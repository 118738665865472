export class Dashboard {
    id: string;
    dashboardId: string;
    name: string;
    shared: boolean
    sharedTo: string;
    what: string;
    why: string;
    lastModifiedDateTime: string;
    likedByLoggedInUser: boolean;
    noOfLikes: number;
    bookmarkedByLoggedInUser: boolean;
    enable: boolean;
    disableCheckBox:boolean;
    removeView:boolean;
    owner: {
        emailAddress: string;
        firstName: string;
        lastName: string;
        userId: string;
    };
    widgetList: WidgetData[];
    layout?: any;
    dashboardConfig?: any;
}
export interface DropdownAccordion {
    personalDashboards: boolean;
    dashboardsSharedByOthers: boolean;
}

export interface DashboardCreationData {
    name: string,
    what: string,
    why: string,
    shared: boolean,
    sharedTo: string,
    widgetList: WidgetData[],
    dashboardLayout?: {}
    id?: string,
    owner?: {
        emailAddress: string;
        firstName: string;
        lastName: string;
        userId: string;
    };
}

export interface WidgetData {
    widgetId: string,
    externalWidget: boolean
}

interface BaseConfig {
    name: string;
    comment?: string;
    dashboardId: string;
    siteRefs: string[];
    excludedCcuRefs: string[];
    excludedFloorRefs: string[];
    excludedZoneRefs: string[];
    excludedEquipRefs: string[];
    excludedDeviceRefs: string[];
    viewBy: string;
    startDate: string;
    endDate: string;
    dateRange: string;
    configId?: string;
}

export interface DashboardConfig extends BaseConfig {
    groupBy: string;
}

export interface WidgetConfig extends BaseConfig {
    widgetId: string;
    scopeSameAsDashboard: boolean;
    groupBySameAsDashboard: boolean;
    groupBy: string;
    dateRangeSameAsDashboard: boolean;
}

export interface DashboardLayout {
    dashboardId: string;
    layout: any;
}