import * as Blockly from 'blockly';

export class BlocklyValidators {
  static addValidators() {
    try {
      Blockly.Extensions.register(
        "unit_selection_validation",
        function (this: Blockly.Block) {
          this.setOnChange((event: Blockly.Events.Abstract) => {
            if (this.isInFlyout) {
              return;
            }
            if (
              event.type === Blockly.Events.CHANGE &&
              (event as Blockly.Events.BlockCreate).blockId === this.id
            ) {
              if (this.getFieldValue('FROM_UNIT') !== this.getFieldValue('TO_UNIT')) {
                this.setWarningText(null);
              } else {
                this.setWarningText('Conversion units cannot be same');
              }
            }

          });
        }
      );

    }
    catch (e) {
      console.log("Error in adding code generators", e);
    }
  }
}