import { Component, OnInit } from '@angular/core';
import { Chart } from '../../shared/models/chart';
import { ObjectUtil } from '../../shared/utils/object-util';
import { CommonService } from '../../shared/_services/common.service';
import { WidgetService } from '../../shared/_services/widget.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '../../shared/_services/alert.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { DashboardService } from '../../shared/_services/dashboard.service';
import { CERTIFICATION_LEVEL, PORTALS } from '../../shared/constants/constants';
import { WidgetLayoutService } from '../../shared/_services/widget-layout.service';
import { Dashboard } from '../../shared/models/dashboard';
import { Subject } from 'rxjs';
import { chartType } from 'src/app/shared/enums/dashboard.enum';

@Component({
  selector: 'app-chart-common-pool',
  templateUrl: './chart-common-pool.component.html',
  styleUrls: ['./chart-common-pool.component.scss']
})
export class ChartCommonPoolComponent implements OnInit {

  charts: any = {
    personalCharts: [],
    chartsSharedByOthers: []
  };
  duplicateCharts: any = {
    personalCharts: [],
    chartsSharedByOthers: []
  };
  dropdownChartsList: any = {
    personalCharts: [],
    chartsSharedByOthers: []
  };
  dropdownAccordion:any = {
    personalCharts: true,
    chartsSharedByOthers: true
  };
  sharedCharts: any = [];
  selectedDashboard : Dashboard;

  selectedSharedChartsList: any = [];
  selectedPersonalChartsList: any = [];
  isLikeUnlikeRefresh: boolean = false;
  tempSharedCharts:any = [];
  hoveredCharts: any;
  subscriptions:any = {};

  siteNameRef: string | undefined;
  enableChartsEdit: boolean = false;
  isLoading: boolean = true;
  tempSelectedChartsList: any;
  tempSelectedSharedChartsList: any;
  chartsList: any;
  selectedChartsList: any;
  searchText: string ='';
  private chartAddedOrRemoved = new Subject<any>();
  chartAddedOrRemoved$ = this.chartAddedOrRemoved.asObservable();
  private chartDeletedSubscription: Subscription;
  constructor(
    private commonservice: CommonService,
    public dialogRef: MatDialog,
    public chartsService: WidgetService,
    public widgetLayoutService: WidgetLayoutService,
    public widgetService: WidgetService,
    public alertService: AlertService,
    public dashBoardService: DashboardService
  ) {
   }

  get isReadOnly(): boolean {
    return (localStorage.getItem('portal') !== PORTALS.INTERNAL) ? this.dashBoardService.certificateLevel == CERTIFICATION_LEVEL.L1 ? true : false : false;
  }

  ngOnInit(): void {
    //Whenever a chart gets deleted update the charts list in common pool.
    this.chartDeletedSubscription = this.dashBoardService.chartDeleted$.subscribe(() => {
      this.setChartsInitialData();
    });
    this.selectedDashboard = this.commonservice.getSelectedDashboard();
    //SETTING SITE ID AND SITE NAME REFERENCE for global charts add
    this.setChartsInitialData();
    this.searchText = '';
    if(this.widgetLayoutService.refreshWidgetListSubject) {
      this.subscriptions['refreshWidgetListSubject'] = this.widgetLayoutService.refreshWidgetListSubject.subscribe((data) => {
        if(data && data['removedChart']) {
          const chart = data.chart;
          let findSelectedIndex = this.selectedDashboard?.widgetList.findIndex(widget => widget.widgetId == chart.widgetId);
          findSelectedIndex != -1 ? this.selectedDashboard?.widgetList.splice(findSelectedIndex, 1) : null;
        }
        this.setChartsDataFormStorage();
      });
    }
  }

  /**
   * Initializes the chart data for the component.
   * 
   * This method sets the initial values for various properties related to charts,
   * such as `siteRef`, `entityRef`, and `siteName`. It also initializes the 
   * `personalCharts` and `chartsSharedByOthers` arrays in the `dropdownChartsList`.
   * Finally, it calls `setChartsDataFormStorage` to load chart data from storage.
   * 
   * @remarks
   * - If `siteId` is provided, it will be used to set `siteRef`.
   * - If `entityRef` is not provided, it will be set to the value of `siteRef`.
   * - If `siteNameRef` is provided, it will be used to set `siteName`.
   * - The `personalCharts` and `chartsSharedByOthers` arrays are reset to empty arrays.
   * 
   * @param {boolean} fromStorage - Indicates whether to load chart data from storage.
   */
  setChartsInitialData() {
    this.dropdownChartsList.personalCharts = [];
    this.dropdownChartsList.chartsSharedByOthers = [];
    this.setChartsDataFormStorage(true);
  }

  /**
   * Sets the charts data from storage and updates the component state accordingly.
   * 
   * @param {boolean} [initialLoad=true] - Indicates whether this is the initial load of the data.
   * 
   * This method performs the following actions:
   * - Sets the `isLoading` flag to `true`.
   * - Retrieves the charts data from storage (mocked data in this case).
   * - Combines the `createdByMeList` and `sharedByOthersList` into a single result array.
   * - If `initialLoad` is `true`, it calls `setInitialLoadData` with the combined result and the individual lists.
   * - If `initialLoad` is `false`, it prepares the dropdown charts list and refreshes the dropdown data.
   * 
   * Note: The actual service call to fetch charts data and related operations are commented out.
   */
  setChartsDataFormStorage(initialLoad = true) {
    this.isLoading = true;
    this.chartsService.getWidgetsList().subscribe({
      next: (chartlistObject: any) => {
        this.isLoading = false;
        let result:Chart[] = [];
        this.chartsService.setChartsList(chartlistObject);
        const createdByMeList: Chart[] = chartlistObject?.createdByMeList || [];
        const sharedByOthersList: Chart[] = chartlistObject?.sharedByOthersList || [];
        if (Array.isArray(createdByMeList) && Array.isArray(sharedByOthersList)) {
          result = [...createdByMeList, ...sharedByOthersList];
        } else {
          result = chartlistObject || [];
        }
        if (initialLoad) {
          this.setInitialLoadData(result, createdByMeList, sharedByOthersList);
        } else {
          const dropdownChartsList: any = {
            personalCharts: [],
            chartsSharedByOthers: []
          };
          // set default values to 'disablecheckbox' and 'removeView' for personalCharts.
          dropdownChartsList.personalCharts = this.setDefaulltValuesToObj(createdByMeList);
          // set default values to 'disablecheckbox' and 'removeView' for chartsSharedByOthers.
          dropdownChartsList.chartsSharedByOthers = this.setDefaulltValuesToObj(sharedByOthersList);

          this.duplicateCharts = ObjectUtil.deepClone(dropdownChartsList);
          this.dropdownChartsList = ObjectUtil.deepClone(dropdownChartsList);
        }
        // this.getSelectedCharts().then(() => {
        // }).catch(err => {
        //   console.error('Error in getSelectedCharts:', err);
        // });
      }, error: (err) => {
        this.alertService.error(err.error.error || 'Something went wrong, please try again');
      }
    });
  }

  /**
   * Sets the initial load data for the charts.
   * 
   * @param tableData - The array of charts to be displayed in the table.
   * @param createdByMeList - The array of charts created by the current user.
   * @param sharedByOthersList - The array of charts shared by other users.
   * 
   * This method initializes the charts based on whether the component is in read-only mode or not.
   * 
   * - If not in read-only mode:
   *   - Sets default values to 'disablecheckbox' and 'removeView' for personal charts and charts shared by others.
   *   - Creates deep clones of the charts for duplicate and dropdown lists.
   * 
   * - If in read-only mode:
   *   - Filters and maps the shared charts to set 'disableCheckBox' and 'removeView' to false.
   *   - Optionally deep clones the shared charts for selected shared and personal charts lists.
   */
  setInitialLoadData(tableData: Chart[], createdByMeList: Chart[], sharedByOthersList: Chart[]) {
    if (tableData.length) {
      this.selectedChartsList = this.selectedDashboard?.widgetList || [];
      if (!this.isReadOnly) {
        // set default values to 'disablecheckbox' and 'removeView' for personalCharts.
        this.charts.personalCharts = this.setDefaulltValuesToObj(createdByMeList);
        // set default values to 'disablecheckbox' and 'removeView' for chartsSharedByOthers.
        this.charts.chartsSharedByOthers = this.setDefaulltValuesToObj(sharedByOthersList);

        //Setting Duplicate array data.
        this.duplicateCharts = ObjectUtil.deepClone(this.charts);
        this.dropdownChartsList = ObjectUtil.deepClone(this.charts);

      } else {

        const sharedCharts = [
          ...createdByMeList.filter((chart:Chart) => chart.shared === true),
          ...sharedByOthersList.filter((chart:Chart) => chart.shared === true)
        ];

        this.sharedCharts = this.setDefaulltValuesToObj(sharedCharts);
        // Below where deep copying the data. so reference of the array will clear.
        this.tempSharedCharts = ObjectUtil.deepClone(this.sharedCharts);
        // this.selectedSharedChartsList = ObjectUtil.deepClone(this.sharedCharts);
        // this.selectedPersonalChartsList = ObjectUtil.deepClone(this.sharedCharts);
      }
    }
  }

  /**
   * Sets default values to each chart object in the provided array.
   * Specifically, it sets 'disableCheckBox' to false and 'removeView' to true.
   *
   * @param chartArray - An array of Chart objects to which default values will be assigned.
   * @returns A new array of Chart objects with default values set.
   */
  setDefaulltValuesToObj(chartArray : Chart[]) {
    // default setting the 'disableCheckBox' and 'removeView' to each charts.
    const selectedChartIds = this.selectedDashboard?.widgetList.map((widget: any) => widget.widgetId) || [];
    return chartArray.map((obj: Chart) => {
      obj['removeView'] = true;
      obj['enable'] = selectedChartIds?.includes(obj.id);
      if (selectedChartIds?.length >= 15) {
        if (!obj['enable']) {
          obj['disableCheckBox'] = true
        } else {
          obj['disableCheckBox'] = false;
        }
      } else {
        obj['disableCheckBox'] = false;
      }
      return obj;
    });
  }
  
  /**
   * Updates the like or bookmark status of a chart in the chart.
   *
   * @param type - The type of action to perform, either 'LIKE' or 'BOOKMARK'.
   * @param chart - The chart object to update.
   *
   * This method toggles the `likedByLoggedInUser` or `bookmarkedByLoggedInUser` 
   * properties of the `chart` object based on the `type` parameter. It also 
   * increments or decrements the `noOfLikes` property if the type is 'LIKE'.
   *
   * A payload object is created to represent the update action, which includes 
   * the component name, action category, and the new value of the toggled property.
   *
   * The actual update service call is commented out but would typically send the 
   * payload to the server to persist the changes.
   */
  updateChartsLikeBookmark(type: string, chart: Chart) {
    if (type == 'LIKE') {
      chart.likedByLoggedInUser = !chart.likedByLoggedInUser;
      if (chart.likedByLoggedInUser) {
        chart.noOfLikes += 1;
      } else {
        chart.noOfLikes -= 1;
      }
    } else {
      chart.bookmarkedByLoggedInUser = !chart.bookmarkedByLoggedInUser;
    }

    let payload = {
      component: "TABLEMAKER_DASHBOARD_WIDGETS",
      category: type,
      value: type == 'LIKE' ? chart.likedByLoggedInUser : chart.bookmarkedByLoggedInUser
    }

    this.chartsService.updateLikeOrBookmark(chart?.id, payload).subscribe({
      next: (res: string) => {
        if (res)
          this.isLikeUnlikeRefresh = true;
        this.setChartsDataFormStorage();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  /**
   * Filters the dropdown list of charts based on the provided search text.
   * 
   * @param searchText - The text to filter the charts by. If the text is empty or null, the filter is cleared.
   * 
   * The method behaves differently based on the `isReadOnly` flag:
   * - If `isReadOnly` is false, it filters `dropdownChartsList.personalCharts` and `dropdownChartsList.chartsSharedByOthers` 
   *   using the `duplicateCharts` list.
   * - If `isReadOnly` is true, it filters the `sharedCharts` list using the `tempSharedCharts` list.
   * 
   * The filtering is case-insensitive and matches charts whose names include the search text.
   */
  searchChartsOnDropDownList(searchText: string) {
    if(!this.isReadOnly){
      if (searchText && searchText?.length) {
        const searchCharts = ObjectUtil.deepClone(this.duplicateCharts);
        this.dropdownChartsList.personalCharts = searchCharts.personalCharts.filter((charts: Chart) => charts.name.toLowerCase().includes(searchText.toLowerCase()));
        this.dropdownChartsList.chartsSharedByOthers = searchCharts.chartsSharedByOthers.filter((charts: Chart) => charts.name.toLowerCase().includes(searchText.toLowerCase()));
      } else {
        this.clearFilterSearch();
      }
    } else {
      if (searchText && searchText?.length) {
        const searchCharts = ObjectUtil.deepClone(this.tempSharedCharts);
        this.sharedCharts = searchCharts.filter((charts: Chart) => charts.name.toLowerCase().includes(searchText.toLowerCase()));
      } else {
        this.clearFilterSearch();
      }
    }
  }

  /**
   * Clears the filter search and resets the dropdown charts list or shared charts
   * based on the read-only status.
   * 
   * If the component is not in read-only mode, it resets the `dropdownChartsList`
   * to a deep clone of `duplicateCharts`. Otherwise, it resets the `sharedCharts`
   * to a deep clone of `tempSharedCharts`.
   */
  clearFilterSearch() {
    if(!this.isReadOnly){
      this.dropdownChartsList = ObjectUtil.deepClone(this.duplicateCharts);
    } else {
      this.sharedCharts = ObjectUtil.deepClone(this.tempSharedCharts);
    }
  }

  /**
   * Handles the selection of a chart and updates the dashboard accordingly.
   * 
   * @param {Chart} chart - The chart object that was selected or deselected.
   * @param {string} type - The type of chart list ('personalCharts' or 'chartsSharedByOthers').
   * 
   * This method performs the following actions:
   * - Adds or removes the chart from the selected dashboard's widget list based on its current state.
   * - Toggles the `enable` property of the chart in the appropriate dropdown list.
   * - Validates the selected charts in both personal and shared charts lists.
   * - Emits an event to notify that a chart has been added or removed from the dashboard.
   */
  onChartSelect(chart: Chart, type: string) {
    let chartAddedorRemoved:string;
    if (!chart.enable) {
      this.selectedDashboard?.widgetList?.push({
        widgetId: chart.id,
        externalWidget: false
      });
      chartAddedorRemoved = 'added';
      // Update the dashboard data upon chart selection to selected dashboard data.
      this.dashBoardService?.dashboardData?.widgetList.push({
        widgetId: chart.id,
        externalWidget: false
      });
    } else {
      if(this.selectedDashboard?.widgetList.length > 0) {
        
        let findSelectedIndex = this.selectedDashboard?.widgetList.findIndex(widget => widget.widgetId == chart.id);
        findSelectedIndex != -1 ? this.selectedDashboard?.widgetList.splice(findSelectedIndex, 1) : null;
      }
      chartAddedorRemoved = 'removed';
      if (this.dashBoardService.dashboardData) {
        this.dashBoardService.dashboardData.widgetList = this.dashBoardService.dashboardData.widgetList.filter(widget => widget.widgetId !== chart.id);
      }
    }
    if(this.isReadOnly){
      this.sharedCharts.forEach((chartObj: Chart) => {
        if (chartObj.id === chart.id) {
          chartObj.enable = !chartObj.enable;
        }
      });
    } else {
      if (type === chartType.PERSONAL_CHARTS) {
        this.dropdownChartsList.personalCharts.forEach((chartObj: Chart) => {
          if (chartObj.id === chart.id) {
            chartObj.enable = !chartObj.enable;
          }
        });
      } else {
        this.dropdownChartsList.chartsSharedByOthers.forEach((chartObj: Chart) => {
          if (chartObj.id === chart.id) {
            chartObj.enable = !chartObj.enable;
          }
        });
      }
    }

    if(this.isReadOnly){
      this.validatedSelectedCharts(this.sharedCharts);
    } else {
      this.validatedSelectedCharts(this.dropdownChartsList.personalCharts);
      this.validatedSelectedCharts(this.dropdownChartsList.chartsSharedByOthers);
    }
    
    // this.widgetService.chartAddedorRemoved.next(this.selectedDashboard);
    this.widgetService.chartAddedorRemoved.next({"chartData":chart,"type":chartAddedorRemoved});
  }

  /**
   * Validates and updates the `disableCheckBox` property of each chart in the provided chart array
   * based on the number of widgets in the selected dashboard.
   *
   * If the selected dashboard has 15 or more widgets, it disables the checkbox for charts
   * where the `enable` property is not true. Otherwise, it ensures that the `disableCheckBox`
   * property is set to false for all charts.
   *
   * @param {Chart[]} chartArray - The array of charts to be validated and updated.
   */
  validatedSelectedCharts(chartArray: Chart[]) {
    if(this.selectedDashboard?.widgetList.length >= 15) {
      chartArray.forEach((chart: Chart) => {
            // Disable checkbox if 'enable' is not true
            if(!chart['enable']) {
              chart['disableCheckBox'] = true;
            } else {
              chart['disableCheckBox'] = false;
            }
          });
    } else {
      chartArray.forEach((chart: Chart) => {
        // Set disableCheckBox to false for all items
        chart['disableCheckBox'] = false;
      });
    }
  }


  /**
   * Handles the hover event on a chart option.
   * 
   * @param chart - The chart that is being hovered over.
   */
  onOptionHover(chart: Chart) {
    this.enableChartsEdit = true;
    this.hoveredCharts = chart;
  }

  /**
   * Event handler for the mouse out event.
   * Disables the chart editing mode when the mouse leaves the chart area.
   */
  onMouseOut() {
    this.enableChartsEdit = false;
  }
  
  /**
   * Formats a given date to a specific string format.
   * The date is first converted to UTC, then to local time, 
   * and finally formatted as 'MMM D, YYYY | HH:mm'.
   *
   * @param date - The date to be formatted.
   * @returns The formatted date string.
   */
  formatDate(date: any) {
    return this.commonservice.descToolTipDateFormat(date);
  }

  /**
   * Toggles the state of the dropdown accordion for a given type.
   *
   * @param type - The type of the dropdown accordion to toggle.
   * @returns A boolean value indicating the success of the operation.
   */
  dropdownListAccordion(type: string) {
    this.dropdownAccordion[type] = !this.dropdownAccordion[type];
  }

  /**
   * Determines if the charts in the given chart are shared by the current user.
   *
   * @param {Chart} chart - The chart containing the charts to check.
   * @returns {boolean} - Returns `true` if the charts are shared by the current user, otherwise `false`.
   */
  isChartsSharedByMe(chart: Chart) {
    return chart?.shared && chart?.owner?.userId === localStorage.getItem('userId') || '';
  }

  /**
   * Formats the given like count using a common service.
   *
   * @param count - The number of likes to format.
   * @returns The formatted like count as a string.
   */
  likeCountFormat(count:number) {
    return this.commonservice.likeCountFormat(count);
  }

  /**
   * Gets the count of selected personal charts.
   * 
   * This getter filters the `personalCharts` from `dropdownChartsList` to include only those
   * charts that are enabled, and returns the count of such charts.
   * 
   * @returns {number} The number of enabled personal charts.
   */
  get selectedPersonalChartscount() {
    const enabledPersonalCharts = this.dropdownChartsList.personalCharts.filter((chart: Chart) => chart.enable === true)
    return enabledPersonalCharts.length;
  }

  /**
   * Gets the count of shared charts that are enabled.
   * 
   * @returns {number} The number of enabled shared charts.
   */
  get selectedSharedChartscount() {
    const enabledSharedCharts = this.dropdownChartsList.chartsSharedByOthers.filter((chart: Chart) => chart.enable === true)
    return enabledSharedCharts.length;
  }

  /**
   * Gets the count of selected shared charts that are enabled.
   * 
   * @returns {number} The number of enabled selected shared charts.
   */

  get selectedSharedChartsDefaultcount() {
    const enabledSharedCharts = this.sharedCharts.filter((chart: Chart) => chart.enable === true)
    return enabledSharedCharts.length;
  }

  createNewChart(isEdit: boolean, widgetData: any) {
    widgetData['name'] = !isEdit ? this.checkForDuplicateName() : widgetData.name;
    this.widgetLayoutService.createOrUpdateCharts(isEdit, this.dashBoardService?.dashboardScopeData?.siteRefs, widgetData)
  }

  checkForDuplicateName() {
    let length = 0;
    Object.keys(this.dropdownChartsList).forEach(_key => {
      if (this.dropdownChartsList[_key]?.length) {
        length += this.dropdownChartsList[_key]?.length;
      }
    });
    return `Charts-${length + 1}`;
  }


  ngOnDestroy(): void {
    if (this.chartDeletedSubscription) {
      //clear the subscribption
      this.chartDeletedSubscription.unsubscribe(); 
    }
  }

}
