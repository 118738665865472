<ng-container>
    <div class="p-l-12 p-r-12 search-charts" (click)="$event.stopPropagation();">
        <mat-form-field appearance="standard" class="charts-popup-input"
            id="charts-form-field">
            <input matInput type="text" [(ngModel)]='searchText' id="chartFilterSearch" #filterSearch class="font-size13" autocomplete="off"
                placeholder="Search for Chart" (focus)=" $event.stopPropagation();" (ngModelChange)="searchChartsOnDropDownList(searchText);" (keydown)="$event.stopPropagation()"
                id="charts-search-input"/>
            <div matSuffix>
                <i class="fas fa-times icon-style p-r-10 ignore cross-icon" (click)="filterSearch.value='';searchText=''; clearFilterSearch(); $event.stopPropagation();"
                    *ngIf="filterSearch.value.length > 0" aria-hidden="true" id="clear-search-icon"></i>
            </div>
        </mat-form-field>
    </div>
    <div (click)="$event.stopPropagation();">
        <ng-container *ngIf="!isReadOnly;else noGrouping" class="charts-Container">
            <div class="list-menu-charts">
                <ng-container [ngTemplateOutlet]="chartDropDown"
                    [ngTemplateOutletContext]="{ list: dropdownChartsList?.personalCharts, name: 'Created by me (' + selectedPersonalChartscount + '/' + (dropdownChartsList?.personalCharts?.length || 0) + ')', type: 'personalCharts' }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="chartDropDown"
                    [ngTemplateOutletContext]="{ list: dropdownChartsList?.chartsSharedByOthers, name: 'Shared by others (' + selectedSharedChartscount + '/' + (dropdownChartsList?.chartsSharedByOthers?.length || 0) + ')', type: 'chartsSharedByOthers' }">
                </ng-container>
            </div>
            <div *ngIf="!isReadOnly" class="button-cta create-btn" id="create-new-chart-btn" (click)="createNewChart(false, {})">CREATE NEW CHART<i class="fa fa-thumb-tack"
                    aria-hidden="true"></i> </div>
        </ng-container>
        <ng-template #noGrouping id="no-grouping-template">
            <div class="list-menu-charts">
                <ng-container [ngTemplateOutlet]="chartDropDown"
                    [ngTemplateOutletContext]="{list:sharedCharts, name: 'Shared by others (' + selectedSharedChartsDefaultcount + '/' + (sharedCharts?.length || 0) + ')',type:'chartsSharedByOthers'}">
                </ng-container>
            </div>
        </ng-template>
    </div>

    <ng-template #chartDropDown let-list="list" let-name="name" let-type="type">
        <app-accordion [blockTitle]="name" [isDropDown]="true" [isOpen]="dropdownAccordion[type]">
            <ng-container [ngTemplateOutlet]="chartsOptionsList"
                [ngTemplateOutletContext]="{list:list,type:type}">
            </ng-container>
        </app-accordion>
    </ng-template>

    <ng-template #chartsOptionsList let-name="name" let-chartlist="list" let-type="type">
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="chartlist?.length">
                <div class="div-bgcolor charts-list" (click)="$event.stopPropagation();"
                    *ngFor="let chart of chartlist; let i=index">
                    <ng-container>
                        <div class="charts-text-height"  (mouseenter)="onOptionHover(chart)" (mouseleave)="onMouseOut()" placement="left" id="chart-container">
                            <div class="charts-name-container">
                                <mat-checkbox class="charts-filter-checkbox" (change)="onChartSelect(chart, type)" [disabled]="chart.disableCheckBox" [checked]="chart.enable" color="primary"
                                
                                    id="{{type}}-chartsCheckbox-{{i}}"></mat-checkbox>
                                <div class="charts-lable-name" matTooltip="" [tippy]="accessInfoToolTip" placement="left" [delay]="[1000, 0]"  [maxWidth]="450" [matTooltipPosition]="'above'"
                                    id="charts-label-name">
                                    {{chart?.name}}
                                </div>
                                <i *ngIf="isChartsSharedByMe(chart)" class="fas fa-share-alt shared-icon"
                                    aria-hidden="true" id="share-icon"></i>
                            </div>

                            <div class="action-container"
                                [ngClass]="type == 'personalCharts' ? 'actionItemsPosition' : ''"
                                id="action-container">
                                <div *ngIf="chart?.noOfLikes"
                                    [ngClass]="isChartsSharedByMe(chart) || type != 'personalCharts' ? 'visibility-show' : 'visibility-hidden'"
                                    class="p-l-2 p-r-5 likes-text" id="likes-text">
                                    <span [matTooltip]="chart?.noOfLikes > 999 ? chart?.noOfLikes + ' Likes': ''"
                                        [matTooltipPosition]="'above'">{{likeCountFormat(chart?.noOfLikes)}}
                                        {{chart?.noOfLikes == 1 ? 'Like': 'Likes'}}</span>
                                </div>
                                <div *ngIf="type != 'personalCharts'"  class="like drop-down-icon" (click)="updateChartsLikeBookmark('LIKE', chart)" id="like-icon">
                                    <img *ngIf="chart.likedByLoggedInUser" class="like-image" src="assets/images/selected-like.svg" alt="" id="liked-image">
                                    <img *ngIf="!chart.likedByLoggedInUser" class="like-image" src="assets/images/default-like.svg" alt="" id="default-like-image">
                                </div>
                                <div class="drop-down-icon p-l-2 bookmark" (click)="updateChartsLikeBookmark('BOOKMARK', chart)" id="bookmar-icon">
                                    <img *ngIf="chart.bookmarkedByLoggedInUser" class="bookmark-image" src="assets/images/bookmarksolid.svg" alt="" id="solid-bookmark-icon">
                                    <img *ngIf="!chart.bookmarkedByLoggedInUser" class="bookmark-image" src="assets/images/bookmarkoutline.svg" alt="" id="regular-bookmark-icon">
                                </div>
                                <div class="drop-down-icon p-l-3 p-r-5 bookmark-icon" (click)="createNewChart(true, chart)"
                                    id="filter-dialog-icon">
                                    <i *ngIf="type === 'personalCharts' && chart.id == hoveredCharts?.id && enableChartsEdit" class="fas fa-pen edit-icon color-primary" id="edit-icon"></i>
                                    <i *ngIf="type != 'personalCharts' && chart.id == hoveredCharts?.id && enableChartsEdit" class="fas fa-eye edit-icon color-primary" id="eye-icon"></i>
                                </div>
                            </div>
                        </div>

                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="!chartlist?.length">
                <div (click)="$event.stopPropagation();" id="no-charts-display" class="no-charts-found p-t-10 p-l-17">
                    No Charts
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoading">   
            <div class="loader-column" *ngFor="let repeat of [0,1]">
                <ngx-skeleton-loader [theme]="{ 'border-radius': '0', 'height': '20px', 'background-color': '#eff0f1'}" count="2"></ngx-skeleton-loader>
            </div>
        </ng-container>
    </ng-template>
</ng-container>

<ng-template #accessInfoToolTip>
    <div class="charts-tooltip tooltip" id="charts-tooltip">
        <div class="tooltip-header entity-tooltip">
            <div class="tool-text">
                <div class="sub-text p-b-15" id="charts-tooltip-why" ><strong>Why: </strong> {{hoveredCharts?.why}}</div>
                <div class="sub-text p-b-15" id="charts-tooltip-what"><strong>What: </strong>{{hoveredCharts?.what}}</div>
                <div class="sub-text">
                    <strong id="charts-tooltip-createdby" >Created By: </strong>{{hoveredCharts?.owner?.firstName}} {{hoveredCharts?.owner?.lastName}}{{hoveredCharts?.owner?.userId === 'external' ? ' (External)' : ''}}
                    <b class="p-l-5" id="charts-tooltip-lastupdated">Last Updated: </b>{{formatDate(hoveredCharts?.lastModifiedDateTime)}}
                </div>
            </div>
        </div>
    </div>    
</ng-template>