export const GRAPH_CONFIGURATION = {
    // zoom config when date is on x-axis
    xDate_ZoomConfig: [
        {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter',
            minValueSpan: 5,
        },
    ],

    // zoom config when date is on y-axis
    yDate_ZoomConfig: [
        {
            type: 'slider',
            yAxisIndex: 0,
            filterMode: 'filter',
            minValueSpan: 5,

        }
    ],

    // X-axis config when date is on x-axis
    xDate_AxisConfig: {
        type: 'time',
        name: '',
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
            fontFamily: 'Lato',
            fontSize: 13,
            lineHeight: 14,
            color: '#999999'
        },
        boundaryGap: false,
        splitNumber: 5,
        axisLabel: {
            formatter: {
                myear: '{yyyy}',
                month: '{MMMM}',
                day: '{MMM} {dd}',
                hour: '{hh}:{mm} {A}',
                minute: '{hh}:{mm} {A}',
            },
        },
        data: [],
        axisLine: {
            lineStyle: {
                color: '#CCCCCC'
            }
        },
        axisTick: {
            show: false,
        },
    },

    // Y-axis config when date is on Y-axis
    YDate_AxisConfig: [{
        type: 'time',
        boundaryGap: false,
        splitNumber: 5,
        name: '',
        nameLocation: 'middle',
        nameGap: 70,
        nameTextStyle: {
            fontFamily: 'Lato',
            fontSize: 13,
            lineHeight: 14,
            color: '#999999'
        },
        data: [],
        axisLabel: {
            formatter: {
                myear: '{yyyy}',
                month: '{MMMM}',
                day: '{MMM} {dd}',
                hour: '{hh}:{mm} {A}',
                minute: '{hh}:{mm} {A}',
            },
        },
        axisLine: {
            lineStyle: {
                color: '#CCCCCC'
            }
        },
        axisTick: {
            show: false,
        },
    }],

    // Tooltip trigger configuration
    toolTipTriggerConfig: {
        trigger: 'axis',
        renderMode: 'html',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 1)',
        borderWidth: 1,
        appendToBody: true,
        // position tooltip dynamically based on height of content
        position: function (point: any, params: any, dom: any, rect: any, size: any) {
            const mouseY = globalMousePosition.y;
            let topPosition = 0;
            // if content height is greater than view height then position tooltip based on mouse position
            if (size.contentSize[1] > size.viewSize[1]) { 
                topPosition = -(mouseY - point[1]);
            }

            let positionObj: any = { top: topPosition };
            if (point[0] < size.viewSize[0] / 2) { 
                positionObj.left = point[0];
            } else {
                positionObj.right = size.viewSize[0] - point[0];
            }
            return positionObj;
        },

        textStyle: {
            color: '#fff',
            fontFamily: 'LATO',
            fontSize: 11
        },
        padding: 10,
        extraCssText: 'box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3); border-radius: 8px;',
    },

    // Legend configuration
    legendConfig: {
        type: 'scroll',
        orient: 'horizontal',
        pageButtonPosition: 'end',
        left: "16px",
        top: "2px",
        icon: 'roundRect',
        animation: true,
        data: []
    },

    // Axis line configuration
    axisLineConfig: {
        lineStyle: {
            color: '#CCCCCC'
        }
    },

    // grid configuration if time is in x-axis
    xAxisgridConfig: {
        left: '2%',
        right: '2%',
        top: '60',
        bottom: '70',
        containLabel: true
    },

    // grid configuration if time is in y-axis
    yAxisgridConfig: {
        left: '4%',
        right: '10%',
        top: '60',
        bottom: '30',
        containLabel: true
    },

    //axis label config, if axis is having values
    axisLabelConfig: {
        formatter: function (value: any) {
            if (value >= 1000 && value < 1e6) {
                const result = value / 1000;
                return result % 1 === 0 ? `${result}k` : `${result.toFixed(1)}k`;
            } else if (value >= 1e6) {
                const result = value / 1e6;
                return result % 1 === 0 ? `${result}M` : `${result.toFixed(1)}M`;
            }
            return value;
        }
    },

    labelNameTextStyle: {
        fontFamily: 'Lato',
        fontSize: 13,
        lineHeight: 14,
        color: '#999999'
    },

    toolTipTriggerConfigForPieChart: {
       
        trigger: 'item',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 1)',

        // position tooltip dynamically based on height of content
        position: function (point:any,params:any, dom:any, rect:any, size:any) {
            const [mouseX, mouseY] = point;
            const { contentSize, viewSize } = size;
        
            let x = mouseX;
            let y = mouseY;
        
            // Adjust x position to prevent overflow
            if (mouseX + contentSize[0] > viewSize[0]) {
              x = mouseX - contentSize[0];
            }
        
            // Adjust y position to prevent overflow
            if (mouseY + contentSize[1] > viewSize[1]) {
              y = mouseY - contentSize[1];
            }
        
            return [x, y];
          },

        textStyle: {
            color: '#fff',
            fontFamily: 'LATO',
            fontSize: 11
        },
        padding: 10,
        extraCssText: 'box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3); border-radius: 8px;',
    },

}


let globalMousePosition = { x: 0, y: 0 };
// get mouse position on mouse move event
document.addEventListener('mousemove', (event) => {
    globalMousePosition.x = event.pageX; // Mouse X relative to the body
    globalMousePosition.y = event.pageY; // Mouse Y relative to the body
  });