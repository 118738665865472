import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of, Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from "./configuration.service";
import { UnitService } from "./units.service";
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CreateDashboardLayoutComponent } from 'src/app/components/create-dashboard-layout/create-dashboard-layout.component';
import { PORTALS, URLS } from "../constants/constants";
import { jwtDecode } from "jwt-decode";
import { DashboardConfig, DashboardCreationData, DashboardLayout, WidgetConfig } from "../models/dashboard";

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  bearer_token: string = localStorage.getItem('bearer_token') || '';
  userId: string;
  tableMakerUrl: string;
  portalParam: string;
  userSitesData: Array<any> = [];
  sitesFloorDataMap: any;
  currentOwnerUserId: string;
  dashboardData: DashboardCreationData;
  tempDashboardData: any;
  tempChartsList: any;
  public dashboardSelectionChange = new Subject<void>();

  // Observable for components to subscribe to
  eventTriggered$ = this.dashboardSelectionChange.asObservable();
  public chartDeletedSource = new Subject<void>();
  chartDeleted$ = this.chartDeletedSource.asObservable();
  dasboardCrudOperation$ = new Subject<string>();
  isSaveasnewOrDuplicate:boolean = false;
  isDashboardEdited:boolean = false;
  _dashboardDate: any;
  _dashboardGroupBy:any;
  _dashboardScopeSiteData: any;
  _dashboardConfig: any;
  _scopeDropdownSitesList: any;
  _crudActionLabel: any;
  certificateLevel: string = '';

  constructor(private http: HttpClient,
      private configService: ConfigurationService,
      private unitService: UnitService,
      public dialogRef: MatDialog
  ) {
    this.tableMakerUrl = this.configService.getConfig('tableMakerUrl');
    this.userId = localStorage.getItem('userId') || '';
  }

  // Method to trigger the event
  triggerDashboardChange() {
    this.dashboardSelectionChange.next();
  }

  createNewDashboard(crudOperation: any, dashboardData: any, isDuplicate: boolean, matDialog?: any) {
    const self = this;

    const dialogRef = self.dialogRef.open(CreateDashboardLayoutComponent, {
      width: '440px',
      maxWidth: '440px',
      height: '350px',
      panelClass: 'create-dashboard-model',
      disableClose: true,
      data: {
        dashboardData: dashboardData,
        crudOperation: crudOperation,
        isDuplicate: isDuplicate
      }
    });
    if (matDialog) {
      matDialog.close();
    }
    dialogRef?.updatePosition({ top: '100px' });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // self.triggerDashboardChange();
      }
    });
  }

  getUserPreferences() {
    const caretakerUrl = this.configService.getConfig('caretakerUrl');
    const userId = localStorage.getItem('userId') || '';
    this.http.get(`${caretakerUrl}/user/${userId}`).subscribe((data: any) => {
      let unitPreferences = {
        temperatureUnit: this.unitService.temperatureUnitListArray[1],
        energyConsumptionUnit: this.unitService.energyConsumptionUnitListArray[0],
        airflowVolumeUnit: this.unitService.airflowVolumeUnitListArray[0],
        airPressureUnit: this.unitService.airPressureUnitListArray[0],
        waterPressureUnit: this.unitService.waterPressureUnitListArray[0],
        waterFlowUnit: this.unitService.waterFlowUnitListArray[0]
      }
      if (data && data['user'] && data['user']['userPreferences']) {
        localStorage.setItem('user_preference', JSON.stringify(data['user']['userPreferences']));
      } else {
        localStorage.setItem('user_preference', JSON.stringify(unitPreferences));
      }
      if (data && data['user'] && data['user']['certificateLevel']) {
        localStorage.setItem('certificateLevel', data['user']?.['certificateLevel']);
        this.certificateLevel = data['user']?.['certificateLevel'];
      }
      if (data && data['user']) {
        const user_data = {
          userId: data.user?.userId,
          emailId: data.user?.emailId,
          firstName: data.user?.personalInfo?.firstName,
          isSupport: data.user?.isSupport
        };
        localStorage.setItem('user_data', JSON.stringify(user_data));
      }
    }, catchError(this.handleError));
  }

  private handleError(error: any, _wrapperParameters:any): Promise<any> {
      console.error('An error occurred', error);
      return of([]).toPromise();
  }

  private handleEditDeleteError(error: any): Promise<any> {
    return Promise.reject(error);
  }

    
  /**************************** API Calls ********************************/

  // API's To Get The Dashboard Data
  getDashboardList() {
    return this.http.get(`${this.tableMakerUrl}dashboard/list-dashboards`);
  }

  // API's For Like And Bookmark
  updateLikeOrBookmark(id : string, request: any) {
    return this.http.post(this.tableMakerUrl + 'userTraction/likeOrBookmark/' + id, request, { responseType: 'text' }).pipe(catchError(this.handleError));
  }

  // API's To Apply The Dashboard To View
  applyDashboardToView(id: string) {
    return this.http.post(this.tableMakerUrl + `dashboard/apply-dashboard?dashboardId=${id}`, { responseType: 'text' }).pipe(catchError(this.handleError));;
  }

  // API's To UnApply The Dashboard To View
  unApplyDashboardToView(id: string) {
    return this.http.delete(this.tableMakerUrl + `dashboard/unapply-dashboard?dashboardId=${id}`, { responseType: 'text' }).pipe(catchError(this.handleError));
  }

  // API's To Get The Applied Dashboard Data
  getAppliedDashboardsList() {
    return this.http.get(`${this.tableMakerUrl}dashboard/get-applied-dashboards`);
  }

  // API's To Get User's Dashboard Data
  getUserDashboardsList() {
    return this.http.get(`${this.tableMakerUrl}dashboard/list-my-dashboards`);
  }

  // API's To Get The Dashboard Usage Data
  getDashboardUsageData(id : string) {
    return this.http.get(`${this.tableMakerUrl}dashboard/usage/${id}`);
  }

  // API's To Delete The Dashboard
  deleteDashboard(id: string) {
    return this.http.delete(`${this.tableMakerUrl}dashboard/delete/${id}`, { responseType: 'text' }).pipe(catchError(this.handleError));;
  }

  getSitesForUser(siteIds?: Array<string>): Observable<any> {
	  this.portalParam = localStorage.getItem('portal') || '';
      return new Observable(observer => {
        const sitesFloorDataMap:any = {};
        let fetchedSiteIds: any[] = [];
  
        if (this.portalParam === PORTALS.FACILISIGHT
          && siteIds && siteIds.length == 1) {
          const siteId = siteIds[0];
  
          this.http.get(`${this.configService.getConfig('caretakerUrl')}/sites/@${siteId}`)
            .pipe(
              catchError(this.handleError),
              map((data: any) => {
                if (data.id) {
                  sitesFloorDataMap[siteId] = {
                    id: siteId,
                    orgId: data.organizationId,
                    name: data.dis,
                    checked: false,
                    tz: data.tz,
                    floors: data.floors || [],
					          value: siteId
                  };
  
                  fetchedSiteIds.push(siteId);
                  this.userSitesData = [{ siteId: `${siteId}`, siteName: data.dis }];
                }
              })
            );
        } else {
            this.getAllSitesAssociatedWithUser().subscribe((result: any) => {
              observer.next(result.data);
              observer.complete();
            })
        }
      });
    }
	
	getAllSitesAssociatedWithUser(){
		return new Observable((observer) => {
		  const orgSitesDataMap: any = {};
		  let fetchedSiteIds = [];
		  const sub_sites = [];
		  const params = this.portalParam === PORTALS.FACILISIGHT ?
			new HttpParams().set('portal', this.portalParam) :
			this.portalParam === PORTALS.INTERNAL ? new HttpParams().set('role', 'supportAccount') : new HttpParams();
	
		  const caretakerCall = this.http.get(
			`${this.configService.getConfig('caretakerUrl')}/${URLS.GET_SITES_USER}/${this.currentOwnerUserId}/sites`, { params: params })
			.pipe(
			  catchError(this.handleError),
			  map((data: any) => {
          return this.handleCaretakerSitesData(data, orgSitesDataMap)
        })
			);
	
		  const payload = `ver:"2.0"\nfilter\n"site"`;
		  const haystackCall = this.http.post(`${this.configService.getConfig('haystackUrl')}v1/read/`, payload)
			.pipe(
			  map((res) => res),
			  catchError(_e => of({ rows: [] })));
	
		  sub_sites.push(caretakerCall);
		  sub_sites.push(haystackCall);
	
		  forkJoin(sub_sites).subscribe((res: any) => {
        res[0].sites.forEach((site: any) => {
          site.siteId = site.siteId.startsWith('@') ? site.siteId.substring(1) : site.siteId;
        });
        const haystackSiteMap: any = {};
        res[1].rows.forEach((site: any) => {
          site.id = this.stripHaystackTypeMapping(site.id.split(' ')[0]);
          let area = site.area;
          if (area) {
          const areaParts = area.split(' ');
          if (areaParts.length > 0) {
            area = areaParts[0].split(':')[1];
          }
          }
    
          haystackSiteMap[site.id] = { tz: site.tz, area: parseInt(area, 10) || 0 };
        });
    
        this.userSitesData = res[0].sites.filter((o: any) => res[1].rows.some((p: any) => o.siteId === p.id));
    
        fetchedSiteIds = this.userSitesData.map(site => site.siteId);
        const data = Object.values(orgSitesDataMap);
        let result =   {data: data, sites: this.userSitesData}
        observer.next(result);
        observer.complete();
		  });
	  });
	}
	
	handleCaretakerSitesData(data: any, orgSitesDataMap: any) {
	  data.sites.forEach((site: any) => {
  
      const siteId = site.siteId.startsWith('@') ? site.siteId.substring(1) : site.siteId;
    
      // Creating two maps - one for org to sites and the other for sites to floors, based on where you need to show it.
      // But will still need to save the original data as well - for more filtering.
      if (!orgSitesDataMap[site.orgId]) {
        orgSitesDataMap[site.orgId] = {
        id: site.orgId,
        name: site.orgName,
        checked: false,
        value: site.orgId,
        sites: []
        };
      }
    
      orgSitesDataMap[site.orgId].sites.push({ id: siteId, name: site.siteName, value: siteId, checked: false });

	  });
	  return data;
	}
  
	stripHaystackTypeMapping(response: any) {
	  return (response) ? JSON.parse(JSON.stringify(response).replace(/"r:|"c:|"n:|"t:|"b:|"m:|"z:|"s:/g, '"')) : '';
	}

	setCurrentOwner(token: any) {
	  const decoded: any = jwtDecode(token);
	  this.currentOwnerUserId = decoded['userData']?.userId || '';
    localStorage.setItem('userId', this.currentOwnerUserId || '');
	}
  
	getCurrentOwner() {
	  return this.currentOwnerUserId;
	}
	
  getChartUsageData(id: string) {
    return this.http.get(`${this.tableMakerUrl}widget/usage/${id}`);
  }

  // API's To Delete the Chart:
  deleteChart(id: string) {
    return this.http.delete(`${this.tableMakerUrl}widget/delete/${id}`, { responseType: 'text' }).pipe(tap(() => this.notifyChartDeleted()),
      catchError(this.handleEditDeleteError));
  }

  //API's to delete external chart:
  deleteExternalChart(id: string) {
    return this.http.delete(`${this.tableMakerUrl}externalWidget/${id}`, { responseType: 'text' }).pipe(catchError(this.handleEditDeleteError));
  }


  // API's To Remove the Chart:
  removeChart(data: any) {
    return this.http.put(`${this.tableMakerUrl}dashboard/removeFromDashboard?dashboardId=${data.dasboardId}&widgetId=${data.widgetId}`, { responseType: 'text' }).pipe(catchError(this.handleEditDeleteError));
  }

  // API's To Get The Chart Data from selected dashboard:
  getSelectedDashboardData(id: string) {
    return this.http.get(`${this.tableMakerUrl}dashboard/${id}`).pipe(catchError(this.handleEditDeleteError));
  }

  /**
   * Retrieves the configuration for widgets in a specific dashboard.
   *
   * @param dashboardId - The ID of the dashboard for which the widget configurations are being requested.
   * @param widgetIdArr - An array containing the IDs of the widgets whose configurations are to be retrieved.
   * @returns An Observable that emits the response from the server containing the widget configurations.
   */
  getWidgetConfigForDashboard(dashboardId: string, widgetIdArr: any) {
    return this.http.post(`${this.tableMakerUrl}widget/getByIds?dashboardId=${dashboardId}`, widgetIdArr).pipe(catchError(this.handleError));
  }

  //This method helps the common pool to have latest chart once chart is deleted:
  notifyChartDeleted(): void {
    this.chartDeletedSource.next();
  }

  set dashboardScopeData(data: any) {
    this._dashboardScopeSiteData = data;
  }

  get dashboardScopeData() {
    return this._dashboardScopeSiteData;
  }

  set dashboardDate(date: any) {
    this._dashboardDate = date;
  }

  get dashboardDate() {
    return this._dashboardDate;
  }

  set dashboardGroupBy(groupBy: any) {
    this._dashboardGroupBy = groupBy;
  }

  get dashboardGroupBy() {
    return this._dashboardGroupBy;
  }

  set scopeDropdownSitesList(scopeDropdownSitesList: any) {
    this._scopeDropdownSitesList = scopeDropdownSitesList;
  }

  get scopeDropdownSitesList() {
    return this._scopeDropdownSitesList;
  }

  set dashboardConfig(config: any) {
    this._dashboardConfig = config;
  }

  get dashboardConfig() {
    return this._dashboardConfig;
  }


  set crudActionLabel(crudActionLabel: any) {
    this._crudActionLabel = crudActionLabel;
  }

  get crudActionLabel() {
    return this._crudActionLabel;
  }


  createDashboard(createDashboardData: DashboardCreationData) {
    return this.http.post(`${this.tableMakerUrl}dashboard/create`, createDashboardData);
  }

  saveDashboardConfiguration(configData: DashboardConfig) {
    return this.http.post(`${this.tableMakerUrl}dashboardconfig/save`, configData);
  }

  saveWidgetConfiguration(configData: WidgetConfig) {
    return this.http.post(`${this.tableMakerUrl}widgetconfig/save`, configData);
  }

  saveBulkWidgetConfiguration(configData: WidgetConfig[], dashboardId: string) {
    return this.http.post(`${this.tableMakerUrl}widgetconfig/saveAll/${dashboardId}`, configData);
  }

  //Copy chart to selected dashboards:
  copyWidgetToDashboard(data: any) {
    return this.http.put(`${this.tableMakerUrl}widget/copy-widget/${data?.widgetId}?sourceDashboardId=${data?.sourceDashBoardId}&targetDashboardId=${data?.targetDashBoardId}`,{ responseType: 'text' }).pipe(catchError(this.handleEditDeleteError));
  }

  //Move chart to selected dashboards:
  moveWidgetToDashboard(data: any) {
    return this.http.put(`${this.tableMakerUrl}widget/move-widget/${data?.widgetId}?sourceDashboardId=${data?.sourceDashBoardId}&targetDashboardId=${data?.targetDashBoardId}`,{ responseType: 'text' }).pipe(catchError(this.handleEditDeleteError));
  }

  // API's to get the site hierarchy
  getScopeHierarchy(siteIds: string[]) {
    const params = siteIds.map(id => `siteIds=${id}`).join('&');
    return this.http.get(`${this.tableMakerUrl}site-hierarchy/site-tree?${params}`).pipe(
      catchError(this.handleEditDeleteError)
    );
  }

  updateDashboardLayout(dashboardLayout: DashboardLayout) {
    return this.http.post(`${this.tableMakerUrl}dashboard/layout-update`, dashboardLayout);
  }

  //API to update the dashboard list order
  updateDashboardListOrder(payload: any) {
    return this.http.put(`${this.tableMakerUrl}dashboard/reorder`, payload);
  }

  /**
   * Reorders the dashboard list based on the provided array.
   * The function creates a payload object where each key is the dashboard id and the value is the new index.
   * If the payload is not empty, it calls `updateDashboardListOrder` with the payload.
   */
  reorderDashboardList(dasboardListArr: any) {
    let payload: any = {}
    dasboardListArr.forEach((dashboard: any, index: number) => {
      payload[dashboard.id] = index;
    });
    if (Object.keys(payload).length === 0) return;
    this.updateDashboardListOrder(payload).subscribe({
      next: (res: any) => {
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }
}