<ng-container *ngIf="widgetObj.isEmbedded">
    <iframe class="chart-iframe" [src]="getSanitizedUrl(widgetObj.widgetUrl)" frameborder="0"></iframe>
</ng-container>
<ng-container *ngIf="!widgetObj.isEmbedded">
    <div class="loader-img" *ngIf="!isLoaded">
        <img alt="" src="./assets/images/loader.gif">
    </div>

    <ng-container *ngIf="showWarningMessage">
        <div class="no-data-container"><span>{{warningMessage}}</span></div>
    </ng-container>

    <div class="chart-container" *ngIf="isLoaded && transformedChartData?.length">
        <div class="chart" *ngFor="let chartOption of transformedChartData">

            <div class="sitename-text" *ngIf="widgetObj?.chartConfig?.viewType == 'SEPARATE' && chartOption?.siteName"> {{chartOption?.siteName}}</div>

            <ng-container *ngIf="chartOption.series.length" [ngSwitch]="widgetObj?.chartConfig?.chartType">
                <ng-container *ngSwitchCase="'LINE'">
                    <app-linechart [chartOptions]="chartOption" [chartAxisPositions]="chartAxisPositions"></app-linechart>
                </ng-container>

                <ng-container *ngSwitchCase="'DOTTED_LINE'">
                    <app-linechart [chartOptions]="chartOption" [chartAxisPositions]="chartAxisPositions"></app-linechart>
                </ng-container>

                <ng-container *ngSwitchCase="'AREA'">
                    <app-areachart [chartOptions]="chartOption" [chartAxisPositions]="chartAxisPositions"></app-areachart>
                </ng-container>

                <ng-container *ngSwitchCase="'BAR'">
                    <app-barchart [chartOptions]="chartOption" [chartAxisPositions]="chartAxisPositions"></app-barchart>
                </ng-container>

                <ng-container *ngSwitchCase="'PIE'">
                    <app-piechart [chartOptions]="chartOption"></app-piechart>
                </ng-container>

                <ng-container *ngSwitchCase="'DONUT'">
                    <app-piechart [chartOptions]="chartOption"></app-piechart>
                </ng-container>
                
                <ng-container *ngSwitchCase="'BOX_PLOT'">
                    <app-boxchart [chartOptions]="chartOption" [chartAxisPositions]="chartAxisPositions"></app-boxchart>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>