export enum DateRangeLabel {
  Today = 'Today',
  Last3Days = 'Last 3 Days',
  LastWeek = 'Last Week',
  LastMonth = 'Last Month',
  Last6Months = 'Last 6 Months',
  LastYear = 'Last Year'
}

export enum timeIntervalsEnum {
  '1 min' = 'ONE_MIN',
  '5 mins' = 'FIVE_MINS',
  '15 mins' = 'FIFTEEN_MINS',
  'Hourly' = 'HOURLY',
  'Daily' = 'DAILY',
  'Weekly' = 'WEEKLY',
  'Monthly' = 'MONTHLY'
}
export enum timeIntervalsEnumLabel {
  'ONE_MIN' = '1 min' ,
  'FIVE_MINS' = '5 mins',
  'FIFTEEN_MINS' = '15 mins',
  'HOURLY' = 'Hourly',
  'DAILY' = 'Daily',
  'WEEKLY' = 'Weekly',
  'MONTHLY' = 'Monthly'
}

export enum DateRangeEnum {
  'Today' = 'TODAY',
  'Last 3 Days' = 'LAST_THREE_DAYS',
  'Last Week' = 'LAST_WEEK',
  'Last Month' = 'LAST_MONTH',
  'Last 6 Months' = 'LAST_SIX_MONTHS',
  'Last Year' = 'LAST_YEAR',
  'CUSTOM' = 'CUSTOM'
}

export enum DateRangeEnumLabel {
  'TODAY' = 'Today' ,
  'LAST_THREE_DAYS' = 'Last 3 Days',
  'LAST_WEEK' = 'Last Week',
  'LAST_MONTH' = 'Last Month',
  'LAST_SIX_MONTHS' = 'Last 6 Months',
  'LAST_YEAR' = 'Last Year',
  'CUSTOM' = 'CUSTOM'
}

