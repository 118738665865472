<div mat-dialog-content id="matDialogContent">
    <div class="header-container" id="headerContainer">
        <div class="warn-position">
            <i class="fa fa-exclamation-triangle color-primary fa-lg" aria-hidden="true" id="exclamationIcon"></i>
        </div>

        <!-- Header Message -->
        <div class="p-l-0 pre-wrap">
        <div class="scrollable-content" id="scrollableContent">
              <div class="m-l-10 edit-text">{{data.headerText}}</div>
         <!-- Sub Header Message Displayed if required -->
              <div class="m-l-10 action-msg sub-message" id="sub-message" *ngIf="data.subMessageRequired">this action cannot be undone</div>
        </div>
    </div>
    </div>
     <!-- Action Message -->
    <div class="row align-items-center">
        <div  [ngClass]="'col-12'" id="indicateMessage" class="p-l-0 pre-wrap">

            <!--Message Template for Edit Or Delete of Dashboard or Charts-->
            <div class="action-msg warn-message"  *ngIf="data?.editOrDelete">{{data?.actionMsg}} <span class="countStyle">{{data?.users}}</span>, including those who have liked it <span class="countStyle">{{data?.likes}}</span> & bookmarked it <span class="countStyle">{{data?.bookmarks}}</span>.</div>

             <!--Message Template for Move or Copy the Charts or Dashboards-->
            <div class="action-msg warn-message"  *ngIf="data?.errorMessage">{{data?.actionMsg}} The <span class="countStyle">{{data?.dashBoardName}}</span>{{data?.limitMessage}}</div>

            <!--Message Template for Removing Chart or Dashboard-->
            <div class="action-msg warn-message"  *ngIf="data?.removeDasboard">{{data?.actionMsg}} <span class="countStyle">{{data?.users}}</span></div>

             <!--Message Template for Unsaved Dashboard or any error message which doesn't require additional styling-->
            <div class="action-msg warn-message"  *ngIf="data?.discardMessage">{{data?.actionMsg}}</div>

            <!--Message Template for Copy or Move where dashboard has exceeded widgets length-->
            <div class="action-msg warn-message"  *ngIf="data?.copyMoveDashboardWidgetExceeded">{{data?.actionMsg}}. The <span class="countStyle">{{data?.dashboardName}}</span> has reached maximum limit of widgets.</div>

            <!--Message Template for Copy or Move where the dashboard already has the widget-->
            <div class="action-msg warn-message"  *ngIf="data?.copyMoveDashboardWidgetExists">{{data?.actionMsg}}. The <span class="countStyle">{{data?.dashboardName}}</span> already contains this chart.</div>
        </div>
    </div>
  </div>

<div mat-dialog-actions [ngClass]="data.isCancelBtnRequired ? 'pull-right' : 'error-btn' " id="matDialogActions">
    <ng-container id="ngContainer">
        <button *ngIf= "data?.isCancelBtnRequired" class="text-uppercase btn color-primary btn-style m-t-3" [mat-dialog-close]="'cancel'"
            id="cancelButton">{{data?.cancelBtnText}}</button>
        <span *ngIf="data?.isCancelBtnRequired" class="seperator">|</span>
        <button class="text-uppercase btn btn-text color-primary btn-style m-t-3" [mat-dialog-close]="'confirm'"
            cdkFocusInitial id="confirmButton">{{data?.confirmBtnText}}</button>
    </ng-container>
</div>