import { Component, EventEmitter, OnInit, Output} from '@angular/core';
import { ObjectUtil } from '../../shared/utils/object-util';
import { Dashboard } from 'src/app/shared/models/dashboard';
import { DashboardService } from 'src/app/shared/_services/dashboard.service';
import { CommonService } from 'src/app/shared/_services/common.service';
import { AlertService } from 'src/app/shared/_services/alert.service';
import { switchMap, map } from 'rxjs/operators';
import { CRUDOperationList, PORTALS } from 'src/app/shared/constants/constants';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-common-pool',
  templateUrl: './common-pool.component.html',
  styleUrls: ['./common-pool.component.scss']
})
export class CommonPoolComponent implements OnInit {

  dashboards: any = {
    personalDashboards: [],
    dashboardsSharedByOthers: []
  };
  duplicateDashboards: any = {
    personalDashboards: [],
    dashboardsSharedByOthers: []
  };
  dropdownDashboardsList: any = {
    personalDashboards: [],
    dashboardsSharedByOthers: []
  };
  dropdownAccordion: any = {
    personalDashboards: true,
    dashboardsSharedByOthers: true,
  };

  sharedDashboards: any = [];
  selectedDashboardData: any = [];
  selectedDashboardCount: number = 0;
  isReadOnly: boolean = false;
  crudOperationList = CRUDOperationList;

  @Output() dashoardChangeEvent = new EventEmitter<void>();

  tempSharedDashboards = [];
  hoveredDashboards: any;
  enableDashboardsEdit: boolean = false;
  isLoading: boolean = true;
  tempSelectedDashboardsList: any;
  tempSelectedSharedDashboardsList: any;
  dashboardsList: any;
  selectedDashboardsList: any;
  searchText: string = '';
  constructor(
    public dashboardService: DashboardService,
    private commonService: CommonService,
    public alertService: AlertService,
    private matDialogRef: MatDialogRef<CommonPoolComponent>
  ) { }

  ngOnInit(): void {

    // Set Initial Data
    this.setDashboardsInitialData();

    // Set Search Text
    this.searchText = '';

    // Check if the portal is internal
    this.isReadOnly = localStorage.getItem('portal') == PORTALS.INTERNAL ? false : true;
  }

  // Method to handle default setting of data
  setDashboardsInitialData() {
    this.dropdownDashboardsList.personalDashboards = [];
    this.dropdownDashboardsList.dashboardsSharedByOthers = [];
    this.setDashboardsDataFormStorage(true);
  }
  
  // Setting default common data pool
  setDashboardsDataFormStorage(initialLoad = true) {
    this.isLoading = true;
    // Get the list of applied dashboards api
    this.dashboardService.getAppliedDashboardsList().pipe(
      map((applieddashboardList: any) => {
        this.selectedDashboardData = applieddashboardList;
        this.selectedDashboardCount = this.selectedDashboardData.length;
        return applieddashboardList;
      }),
      switchMap(() =>

        // Get the list of dashboards api
        this.dashboardService.getDashboardList()

      )).subscribe({
        next: (responseDashboardList: any) => {
          this.isLoading = false;
          let dashboardDataList: Dashboard[];
          const createdByMeList: Dashboard[] = responseDashboardList.createdByMeList;
          const sharedByOthersList: Dashboard[] = responseDashboardList.sharedByOthersList;

          if (Array.isArray(createdByMeList) && Array.isArray(sharedByOthersList)) {
            dashboardDataList = [...createdByMeList, ...sharedByOthersList];
          } else {
            dashboardDataList = responseDashboardList;
          }

          if (initialLoad) {
            this.setInitialLoadData(dashboardDataList, createdByMeList, sharedByOthersList);
          } else {
            const dropdownDashboardsList : any = {
              personalDashboards: [],
              dashboardsSharedByOthers: []
            };

            // set default values to 'disablecheckbox' and 'removeView' for personalDashboards.
            dropdownDashboardsList.personalDashboards = this.setDefaulltValuesToObj(createdByMeList);

            // set default values to 'disablecheckbox' and 'removeView' for dashboardsSharedByOthers.
            dropdownDashboardsList.dashboardsSharedByOthers = this.setDefaulltValuesToObj(sharedByOthersList);

            this.duplicateDashboards = ObjectUtil.deepClone(dropdownDashboardsList);
            this.dropdownDashboardsList = ObjectUtil.deepClone(dropdownDashboardsList);
          }
        },
        error: (error: any) => {
          this.alertService.error(error.error.error || 'Something went wrong, please try again');
        },
      })
  }
  
  // Method Initial Load SetUp
  setInitialLoadData(tableData: Dashboard[], createdByMeList: Dashboard[], sharedByOthersList: Dashboard[]) {
    if (tableData.length) {
      // set default values to 'disablecheckbox' and 'removeView' for personalDashboards.
      this.dashboards.personalDashboards = this.setDefaulltValuesToObj(createdByMeList);

      // set default values to 'disablecheckbox' and 'removeView' for dashboardsSharedByOthers.
      this.dashboards.dashboardsSharedByOthers = this.setDefaulltValuesToObj(sharedByOthersList);

      //Setting Duplicate array data.
      this.duplicateDashboards = ObjectUtil.deepClone(this.dashboards);
      this.dropdownDashboardsList = ObjectUtil.deepClone(this.dashboards);

    }
  }

  // Method to set checkbox options
  setDefaulltValuesToObj(dashboardArray: Dashboard[]) {
    const selectedDashboardIds = this.selectedDashboardData.map((selectedDashboards : any) => selectedDashboards.dashboardId);
    return dashboardArray.map((obj: Dashboard) => {
      
      obj['removeView'] = true;
      obj['enable'] = selectedDashboardIds.includes(obj.id); // check if the dashboard is applied to view and set it true
      if (selectedDashboardIds.length >= 15) {
        if (!obj['enable']) {
          obj['disableCheckBox'] = true
        } else {
          obj['disableCheckBox'] = false;
        }
      } else {
        obj['disableCheckBox'] = false;
      }
      return obj;
    }); 
  }

  // Method to handle the search functionality
  searchDasboardsOnDropDownList(searchText: string) {
      if (searchText && searchText?.length) {
        const searchDashboards = ObjectUtil.deepClone(this.duplicateDashboards);
        this.dropdownDashboardsList.personalDashboards = searchDashboards.personalDashboards.filter((dashboards: Dashboard) => dashboards.name.toLowerCase().includes(searchText.toLowerCase()));
        this.dropdownDashboardsList.dashboardsSharedByOthers = searchDashboards.dashboardsSharedByOthers.filter((dashboards: Dashboard) => dashboards.name.toLowerCase().includes(searchText.toLowerCase()));
      } else {
        this.clearFilterSearch();
      }
  }

  // Method to clear the search filter
  clearFilterSearch() {
      this.dropdownDashboardsList = ObjectUtil.deepClone(this.duplicateDashboards);
  }

  // Method to handle the dashboard selection to view
  onDashboardSelection(dashboard: Dashboard, type: string) {

    // Applying / Unapplying dashboard into view 
    if (dashboard.enable) {
      this.selectedDashboardCount--;
      // Unapply the dashboard API
      this.dashboardService.unApplyDashboardToView(dashboard?.id).subscribe({
        next: (response: string) => {
          this.alertService.success('Dashboard unapplied successfully');
          this.updateValidateDashboardSelection();
         },
        error: (error: any) => {
          this.alertService.error(error.error.error || 'Something went wrong, please try again');
          this.selectedDashboardCount++;
        },
      });
    } else {
      this.selectedDashboardCount++;
      // Apply the dashboard API
      this.dashboardService.applyDashboardToView(dashboard?.id).subscribe({
        next: (response: string) => { 
          this.alertService.success('Dashboard added successfully');
          this.updateValidateDashboardSelection();
         },
        error: (error: any) => {
          this.alertService.error(error.error.error || 'Something went wrong, please try again');
          this.selectedDashboardCount--;
        },
      });
    }

    if (type === 'personalDashboards') {
      this.dropdownDashboardsList.personalDashboards.map((dashboardItem: Dashboard) => {
        if (dashboardItem.id === dashboard.id) {
          dashboardItem.enable = !dashboardItem.enable;
        }
      });
    } else {
      this.dropdownDashboardsList.dashboardsSharedByOthers.map((dashboardItem: Dashboard) => {
        if (dashboardItem.id === dashboard.id) {
          dashboardItem.enable = !dashboardItem.enable;
        }
      });
    }
  
  }

  // Method to handle the dashboard selection to view
  updateValidateDashboardSelection() {
    this.dashboardService.getAppliedDashboardsList().subscribe({
      next: (response: any) => {
        if(response) {
          let selectedDashboardData = response;
          let selectedDashboardIds = selectedDashboardData.map((selectedDashboards : any) => selectedDashboards.dashboardId);
          
          // Add a delay before calling updateDisableCheckbox
          setTimeout(() => {
            this.updateDisableCheckbox(this.dropdownDashboardsList.personalDashboards, selectedDashboardIds);
            this.updateDisableCheckbox(this.dropdownDashboardsList.dashboardsSharedByOthers, selectedDashboardIds);
          }, 500);
        }
      
      }
    });

    this.dashboardService.triggerDashboardChange();
  }

  updateDisableCheckbox(dashboardArray: Dashboard[], selectedDashboardIds: any) {
        if (selectedDashboardIds.length >= 15) {
          dashboardArray.forEach((obj: Dashboard) => {
            // Disable checkbox if 'enable' is not true
            if(!obj['enable']) {
              obj['disableCheckBox'] = true;
            } else {
              obj['disableCheckBox'] = false;
            }
          });
        } else {
          dashboardArray.forEach((obj: Dashboard) => {
            // Set disableCheckBox to false for all items
            obj['disableCheckBox'] = false;
          });
        }
  }
  

  // Method to handle the dashboard selection to view
  onOptionHover(dashboard: Dashboard) {
    this.enableDashboardsEdit = true;
    this.hoveredDashboards = dashboard;
  }

  onMouseOut() {
    this.enableDashboardsEdit = false;
  }

  dropdownListAccordion(type: string) {
    return true;
    // this.dropdownAccordion[type] = !this.dropdownAccordion[type];
  }

  // Method to create new dashboard or edit existing dashboard
  createNewDashboard() {
    this.dashboardService.createNewDashboard(this.crudOperationList.new, {} , false, this.matDialogRef);
    this.dashboardService.isSaveasnewOrDuplicate = true;
  }



  // ******************************** Common Framework Methods ******************************** //

  // Please add the below methods in the common service and use it in the component for common framework methods


  get selectedPersonalDashboardscount() {
    const enabledPersonalDashboards = this.dropdownDashboardsList.personalDashboards.filter((dashboard: Dashboard) => dashboard.enable === true)
    return enabledPersonalDashboards.length;
  }

  get selectedSharedDashboardscount() {
    const enabledSharedDashboards = this.dropdownDashboardsList.dashboardsSharedByOthers.filter((dashboard: Dashboard) => dashboard.enable === true)
    return enabledSharedDashboards.length;
  }

  get selectedSharedTableDefaultcount() {
    const enabledSharedDashboards = this.sharedDashboards.filter((dashboard: Dashboard) => dashboard.enable === true)
    return enabledSharedDashboards.length;
  }

  /**
   * Determines if the given dashboard is personal.
   *
   * @param dashboard - The dashboard object to check.
   * @returns A boolean indicating whether the dashboard is personal.
   */
  isPersonal(dashboard: Dashboard | any) {
    return this.commonService.isEntityPersonal(dashboard);
  }

  /**
   * Checks if the given dashboard is shared by others.
   *
   * @param dashboard - The dashboard object to check.
   * @returns A boolean indicating whether the dashboard is shared by others.
   */
  isSharedByOthers(dashboard: Dashboard | any) {
    return this.commonService.isEntitySharedByOthers(dashboard);
  }

  /**
   * Checks if the given entity is shared by me.
   *
   * @param dashboard - The dashboard object to check.
   * or
   * @param chart - The chart object to check. 
   * @returns A boolean indicating whether the entity is shared by me.
   */
  isSharedByMe(entity: Dashboard | any) {
    return this.commonService.isEntitySharedByMe(entity);
  }

  /**
   * Checks if the given dashboard is user's dashboard.
   *
   * @param dashboard - The dashboard object to check.
   * @returns A boolean indicating whether the dashboard is owned.
   */
  isUsersDashboard(dashboard: Dashboard | any) {
    return this.commonService.isUsersEntity(dashboard);
  }

  /**
   * Formats the given date using a specific tooltip date format.
   *
   * @param date - The date to be formatted.
   * @returns The formatted date string.
   */
  formatDescDate(date: any) {
    return this.commonService.descToolTipDateFormat(date);
  }

  // Method to handle the like count format
  formatLikes(count: number) {
    return this.commonService.likeCountFormat(count);
  }

  //Method to handle the like
  updateDashboardsLikeBookmark(type: string, dashboard: Dashboard) {
    if (type == 'LIKE') {
      dashboard.likedByLoggedInUser = !dashboard.likedByLoggedInUser;
      if (dashboard.likedByLoggedInUser) {
        dashboard.noOfLikes += 1;
      } else {
        dashboard.noOfLikes -= 1;
      }
    } else {
      dashboard.bookmarkedByLoggedInUser = !dashboard.bookmarkedByLoggedInUser;
    }

    let payload = {
      component: "TABLEMAKER_DASHBOARDS",
      category: type,
      value: type == 'LIKE' ? dashboard.likedByLoggedInUser : dashboard.bookmarkedByLoggedInUser
    }

    this.dashboardService.updateLikeOrBookmark(dashboard?.id, payload).subscribe({
      next: (response: string) => {
          this.setDashboardsDataFormStorage(true);
          this.dashboardService.triggerDashboardChange();
      },
      error: (error: any) => {
        this.alertService.error(error.error.error || 'Something went wrong, please try again');
      }
    });
  }

  editAndPreviewDashboard(dashboard: Dashboard, type: string) {
    this.dashboardService.tempDashboardData = this.dashboardService.dashboardData;
    this.dashboardService.dashboardData = dashboard;
    this.dashboardService.isSaveasnewOrDuplicate = false;
    if (type == this.crudOperationList.edit) {
      this.dashboardService.isDashboardEdited = false;
    }
    this.dashboardService.dasboardCrudOperation$.next(type);
    this.matDialogRef.close();
  }
}

// ****************** Common methods for common framework ends here ****************************//
