<div class="d-flex flex-column" id="external-modal">
    <div class="flex-head">
        <div class="d-flex row justify-content-between align-self-center">
            <div class="col-3 d-flex align-items-center">
                <div class="display-chart-name limit-chart-name" matTooltip="{{externalChartName}}" matTooltipPosition="above"
                    [matTooltipClass]="'chart-name-tooltip'" *ngIf="!editExternalName">{{externalChartName}}</div>
                <mat-form-field *ngIf="editExternalName" class="no-input">
                    <mat-label></mat-label>
                    <input required maxlength="35" matInput type="text" trim="blur" id="widgetNameInputField"
                        class="chart-name" autocomplete="off"
                        [(ngModel)]="externalChartName" placeholder="Enter Widget name" (focusout)="editExternalName = false"/>
                </mat-form-field>
                <i class="fas fa-pen btn-icon color-primary" matSuffix aria-hidden="true" (click)="editExternalName =!editExternalName"></i>
            </div>
        </div>
    </div>

    <div class="h-100" id="external-container">
        <div class="row" id="sub-external-container">
            <!--Right panel-->
            <div class="col-3 p-l-0 p-r-0 custom-col-left">
                <div class="col-12 chart-side-bar-border p-l-24 p-r-0">
                    <div class="chart-side-bar-configuration">
                        <label class="lbl">Embed External Link</label>
                    </div>
                    <div class="description-area">
                    <textarea class="iframe-link-contnr" required spellcheck="false" id="embedInput" placeholder="Paste your link here..." [(ngModel)]="iframeLink"> </textarea>
                    </div>

                    <div class="create-btn-container">
                    <div  class="button-cta create-btn" id="create-new-chart-btn"
                    (click)="generatePreview()" [class.disabled]="!iframeLink?.length" 
                    [attr.disabled]="!iframeLink?.length ? true : null">Preview<i class="fa fa-thumb-tack" aria-hidden="true"></i>
                </div>
                </div>
                </div>
            </div>


            <!--Left panel-->
            <div class="col-9 p-l-0 p-r-0 custom-col-right right-panel-main">
                <div class="right-panel-content" *ngIf="displayWidget && !showLoader">
                    <iframe  id="chart-iframe" [src]="formattedIframeLink" frameborder="0"></iframe>
                </div>

                <div class="h-100 error-content d-flex justify-content-center" *ngIf="warningMsg.length && !showLoader">       
                    <div class="header-container">
                        <div class="warn-position">
                            <i class="fa fa-exclamation-triangle color-primary fa-lg" aria-hidden="true" id="exclamationIcon"></i>
                        </div>
                
                        <div class="p-l-0 pre-wrap">
                            <div class="scrollable-content" id="scrollableContent">
                                <div class="edit-text">{{warningMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf=" showLoader">
                 <div class="error-content d-flex justify-content-center">
                    <img alt="" src="./assets/images/loader.gif" class="loader-class">
                 </div>

                 <div class="loader-text d-flex justify-content-center">Loading preview, please wait...</div>
                </div>

                <div id="action-button-container" class="action-buttons">
                    <button id="widget-cancel-embedChart-cta"
                        class=" btn btn-outline-primary color-primary text-uppercase action-btn cancel-btn" [mat-dialog-close]="'cancel'">CANCEL
                    </button>
                    <button class="btn btn-outline-primary color-primary text-uppercase action-btn save-button"
                      id="widget-save-embedChart-cta" (click)="onSaveEmbedLink()" [disabled]="disableSave">SAVE
                    </button>
                </div>
            </div>
    </div>

    </div>