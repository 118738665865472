import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class UnitService {

    unitListArray = ['\xB0C', '\xB0F', 'kWh', 'kBTU', 'tonrefh', 'GJ', 'cfm', 'm\u00B3/h', 'L/min', 'L/s', 'inH\u2082O', 'mmH\u2082O', 'cmH\u2082O', 'Pa', 'bar', 'psi', 'kPa', 'gal/min', 'm\u00B3/h', 'L/min', 'L/s'];
    tunerUnitList = ['\xB0C', '\xB0F', 'kWh', 'kBTU', 'tonrefh', 'GJ', 'cfm', 'm\u00B3/h', 'L/min', 'L/s', 'inH\u2082O', 'mmH\u2082O', 'cmH\u2082O', 'Pa', 'bar', 'psi', 'kPa', 'gal/min', 'm\u00B3/h', 'L/min', 'L/s']
    temperatureUnitListArray = ['\xB0C', '\xB0F'];
    energyConsumptionUnitListArray = ['kWh', 'kBTU', 'tonrefh', 'GJ'];
    airflowVolumeUnitListArray = ['cfm', 'm\u00B3/h', 'L/min', 'L/s'];
    airPressureUnitListArray = ['inH\u2082O', 'mmH\u2082O', 'cmH\u2082O', 'Pa'];
    waterPressureUnitListArray = ['bar', 'psi', 'kPa'];
    waterFlowUnitListArray = ['gal/min', 'm\u00B3/h', 'L/min', 'L/s'];
    
    getUserPrefernceUnits() {
        const data = JSON.parse(localStorage.getItem('user_preference') || '{}');
        let unit = '';
        Object.keys(data)?.forEach((_key, i) => {
            if (i == 0) {
                unit = data[_key];
            } else {
                unit += ',' + data[_key];
            }
        });
        return unit;
    }

}