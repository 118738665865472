import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {

    @Input() ignore: Array<string>;
    @Output() outside: EventEmitter<boolean>;

    constructor(private element: ElementRef) {
        this.outside = new EventEmitter<boolean>();
    }

    @HostListener('document:click', ['$event'])
    onClick(event:any) {
        if (this.ignore && Array.isArray(this.ignore) && this.ignore?.length > 0) {
            if (this.ignore?.indexOf('#' + event.target.id) > -1) {
                return;
            }
            if (this.ignore?.filter(e => event.target.classList.contains(e.substr(1, e.length))).length > 0) {
                return;
            }
        }
        if (!this.element.nativeElement.contains(event.target)) {
            this.outside.emit(true);
        }
    }

}
