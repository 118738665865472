<ng-container>
    <div class="p-l-12 p-r-12 search-dashboards" (click)="$event.stopPropagation();">
        <mat-form-field appearance="standard" class="dashboards-popup-input"
            id="dashboards-form-field">
            <input matInput type="text" [(ngModel)]='searchText' id="dashboardFilterSearch" #filterSearch class="font-size13" autocomplete="off"
                placeholder="Search for Dashboard" (focus)=" $event.stopPropagation();" (ngModelChange)="searchDasboardsOnDropDownList(searchText);" (keydown)="$event.stopPropagation()"
                id="dashboards-search-input"/>
            <div matSuffix>
                <i class="fas fa-times icon-style p-r-10 ignore cross-icon" (click)="filterSearch.value='';searchText=''; clearFilterSearch(); $event.stopPropagation();"
                    *ngIf="filterSearch.value.length > 0" aria-hidden="true" id="clear-search-icon"></i>
            </div>
        </mat-form-field>
    </div>
    <div (click)="$event.stopPropagation();">
        <ng-container  class="dashboards-Container">
            <div class="list-menu-dashboards">
                <ng-container [ngTemplateOutlet]="dashboardDropDown"
                    [ngTemplateOutletContext]="{ list: dropdownDashboardsList?.personalDashboards, name: 'Created by me (' + selectedPersonalDashboardscount + '/' + (dropdownDashboardsList?.personalDashboards?.length || 0) + ')', type: 'personalDashboards' }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="dashboardDropDown"
                    [ngTemplateOutletContext]="{ list: dropdownDashboardsList?.dashboardsSharedByOthers, name: 'Shared by others (' + selectedSharedDashboardscount + '/' + (dropdownDashboardsList?.dashboardsSharedByOthers?.length || 0) + ')', type: 'dashboardsSharedByOthers' }">
                </ng-container>
            </div>
            <div class="button-cta create-btn" id="create-new-dashboard-btn" (click)="createNewDashboard()">CREATE NEW DASHBOARD<i class="fa fa-thumb-tack"
                    aria-hidden="true"></i> </div>
        </ng-container>
    </div>

    <ng-template #dashboardDropDown let-list="list" let-name="name" let-type="type">
        <app-accordion [blockTitle]="name" [isDropDown]="true" [isOpen]="dropdownAccordion[type]">
            <ng-container [ngTemplateOutlet]="dashboardsOptionsList"
                [ngTemplateOutletContext]="{list:list,type:type}">
            </ng-container>
        </app-accordion>
    </ng-template>

    <ng-template #dashboardsOptionsList let-name="name" let-list="list" let-type="type">
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="list?.length">
                <div class="div-bgcolor dashboards-list" (click)="$event.stopPropagation();"
                    *ngFor="let dashboard of list; let i=index">
                    <ng-container>
                        <div class="dashboards-text-height"  (mouseenter)="onOptionHover(dashboard)" (mouseleave)="onMouseOut()" placement="left" id="dashboards-text-teight">
                            <div class="dashboards-name-container">
                                <mat-checkbox class="dashboards-filter-checkbox" (change)="onDashboardSelection(dashboard, type)" [disabled]="dashboard.disableCheckBox || (!dashboard.enable && this.selectedDashboardCount >= 15)" [checked]="dashboard.enable" color="primary"
                                
                                    id="{{type}}-dashboardsCheckbox-{{i}}"></mat-checkbox>
                                <div class="dashboards-lable-name"  matTooltip="" [tippy]="accessInfoToolTip" placement="left" [delay]="[1000, 0]" [maxWidth]="450" [matTooltipPosition]="'above'"
                                    id="dashboards-label-name">
                                    {{dashboard?.name}}
                                </div>
                                <i *ngIf="isSharedByMe(dashboard)" class="fas fa-share-alt shared-icon"
                                    aria-hidden="true" id="share-icon"></i>
                            </div>

                            <div class="action-container"
                                [ngClass]="type == 'personalDashboards' ? 'actionItemsPosition' : ''"
                                id="action-container">
                                <div *ngIf="dashboard?.noOfLikes"
                                    [ngClass]="isSharedByMe(dashboard) || type != 'personalDashboards' ? 'visibility-show' : 'visibility-hidden'"
                                    class="p-l-2 p-r-5 likes-text" id="likes-text">
                                    <span [matTooltip]="dashboard?.noOfLikes > 999 ? dashboard?.noOfLikes + ' Likes': ''"
                                        [matTooltipPosition]="'above'">{{formatLikes(dashboard?.noOfLikes)}}
                                        {{dashboard?.noOfLikes == 1 ? 'Like': 'Likes'}}</span>
                                </div>
                                <div *ngIf="type != 'personalDashboards'"  class="like drop-down-icon" (click)="updateDashboardsLikeBookmark('LIKE', dashboard)" id="like-icon">
                                    <img *ngIf="dashboard.likedByLoggedInUser" class="like-image" src="assets/images/selected-like.svg" alt="" id="liked-image">
                                    <img *ngIf="!dashboard.likedByLoggedInUser" class="like-image" src="assets/images/default-like.svg" alt="" id="default-like-image">
                                </div>
                                <div class="drop-down-icon p-l-2 bookmark" (click)="updateDashboardsLikeBookmark('BOOKMARK', dashboard)" id="bookmar-icon">
                                    <img *ngIf="dashboard.bookmarkedByLoggedInUser" class="bookmark-image" src="assets/images/bookmarksolid.svg" alt="" id="solid-bookmark-icon">
                                    <img *ngIf="!dashboard.bookmarkedByLoggedInUser" class="bookmark-image" src="assets/images/bookmarkoutline.svg" alt="" id="regular-bookmark-icon">
                                </div>
                                <div class="drop-down-icon p-l-3 p-r-5 bookmark-icon"
                                    id="filter-dialog-icon">
                                    <i (click)="editAndPreviewDashboard(dashboard, crudOperationList.edit);" *ngIf="type === 'personalDashboards' && dashboard.id == hoveredDashboards?.id && enableDashboardsEdit" class="fas fa-pen edit-icon color-primary" id="edit-icon"></i>
                                    <i (click)="editAndPreviewDashboard(dashboard, crudOperationList.preview);" *ngIf="type != 'personalDashboards' && dashboard.id == hoveredDashboards?.id && enableDashboardsEdit" class="fas fa-eye edit-icon color-primary" id="eye-icon"></i>
                                </div>
                            </div>
                        </div>

                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="!list?.length">
                <div (click)="$event.stopPropagation();" id="no-dashboards-display" class="no-dashboards-found p-t-10 p-l-17">
                    No Dashboards
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoading">   
            <div class="loader-column" *ngFor="let repeat of [0,1]">
                <ngx-skeleton-loader [theme]="{ 'border-radius': '0', 'height': '20px', 'background-color': '#eff0f1'}" count="2"></ngx-skeleton-loader>
            </div>
        </ng-container>
    </ng-template>
</ng-container>

<ng-template #accessInfoToolTip>
    <div class="dashboards-tooltip tooltip" id="dashboards-tooltip">
        <div class="tooltip-header entity-tooltip">
            <div class="tool-text">
                <div class="sub-text p-b-15" id="dashboards-tooltip-why" ><strong>Why: </strong> {{hoveredDashboards?.why}}</div>
                <div class="sub-text p-b-15" id="dashboards-tooltip-what"><strong>What: </strong>{{hoveredDashboards?.what}}</div>
                <div class="sub-text">
                    <strong id="dashboards-tooltip-createdby" >Created By: </strong>{{hoveredDashboards?.owner?.firstName}} {{hoveredDashboards?.owner?.lastName}}{{hoveredDashboards?.owner?.userId === 'external' ? ' (External)' : ''}}
                    <b class="p-l-5" id="dashboards-tooltip-lastupdated">Last Updated: </b>{{formatDescDate(hoveredDashboards?.lastModifiedDateTime)}}
                </div>
            </div>
        </div>
    </div>    
</ng-template>