import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { Dashboard } from '../models/dashboard';
import { GradientColorGroup, GradientColors } from '../models/widget.model';
import { Chart } from '../models/chart';
import { ObjectUtil } from '../utils/object-util';
dayjs.extend(utc);
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  // userId: string = localStorage.getItem('userId') || '';
  userId = localStorage.getItem('userId') || '';
  selectedDashboard: Dashboard;
  loggedInUser = JSON.parse(localStorage.getItem('user_data') || '{}');

  constructor() { }

  colorSet = ['#E95E6F', '#EF9453', '#F7C325', '#1AAE9F', '#92CA4B', '#897A5F',
    '#439AEB', '#9635E2', '#C660D7', '#4B5C6B', '#788896', '#BABABA'];

  gradientColors:GradientColors = {
      grp1: { stopColor0: '#0000fc', stopColor100: '#00ff1d' },
      grp2: { stopColor0: '#f7c325', stopColor100: '#9635e2' },
      grp3: { stopColor0: '#e95e6f', stopColor100: '#439aeb' }
  };


  /**
   * Checks if the given entity is shared by the current user.
   *
   * @param dashboard - The dashboard object to check.
   * or
   * @param chart - The chart object to check.
   * @returns `true` if the entity is shared and the current user is the owner, otherwise `false`.
   */
  isEntitySharedByMe(entity: Dashboard | Chart) {
    return entity?.shared && entity?.owner?.userId === this.userId;
  }

  /**
   * Determines if the given entity is personal to the current user.
   *
   * A entity is considered personal if it is not shared and the owner of the entity
   * matches the current authenticated user.
   *
   * @param dashboard - The dashboard object to check.
   * or
   * @param chart - The chart object to check.
   * @returns `true` if the entity is personal to the current user, `false` otherwise.
   */
  isEntityPersonal(entity: Dashboard | Chart) {
    return !entity?.shared && entity?.owner?.userId === this.userId;
  }

  /**
   * Checks if the given chart is personal to the current user.
   * @param {Chart} chart - The chart object to check.
   * @returns {boolean} - Returns true if the chart is owned by the current user, otherwise false.
   */
  isChartPersonal(chart:Chart) {
    return chart?.owner?.userId === this.userId;
  }

  /**
   * Checks if the given entity is shared by others.
   *
   * @param dashboard - The dashboard object to check.
   * or
   * @chart - The chart object to check.
   * @returns A boolean indicating whether the entity is shared by others.
   */
  isEntitySharedByOthers(entity: Dashboard | Chart) {
    return entity?.owner?.userId !== this.userId;
  }

  /**
   * Checks if the given entity is user's dashboard.
   *
   * @param dashboard - The dashboard object to check.
   * or
   * @chart - The chart object to check.
   * @returns A boolean indicating whether the entity is owned.
   */
  isUsersEntity(entity: Dashboard | Chart) {
    return entity?.owner?.userId == this.userId;
  }

  /**
   * Formats a given date into a specific string format for tooltip display.
   * The format used is 'MMM D, YYYY | h:mm A'.
   * 
   * @param date - The date to be formatted. It can be any type that is compatible with dayjs.
   * @returns A string representing the formatted date in local time.
   */
  descToolTipDateFormat(date: any) {
    return dayjs.utc(date).local().format('MMM D, YYYY | h:mm A');
  }

  /**
   * Formats the given number of likes into a more readable string.
   * 
   * - If the count is less than 1000, it returns the count as a string.
   * - If the count is 1000 or more, it returns the count rounded to one decimal place followed by 'k'.
   * 
   * @param count - The number of likes to format.
   * @returns A formatted string representing the number of likes.
   */
  likeCountFormat(count: number): string {
    if (count < 1000) {
      return count.toString();
    } else {
      const roundedNumber: string = (Math.floor(count / 100) / 10).toFixed(1);
      return `${roundedNumber}k`;
    }
  }
  
  // Function to format scope data for dropdown
  formatScopeForDropdown(data: any[], childKey: string) {
    return data.map((org: any) => {
      const { [childKey]: children, ...rest } = org;
      return {
        ...rest,
        'children': children, // Renaming based on the childKey
      };
    }).filter((org: any) => org.children.length > 0);
  }

  /**
   * Finds the name of the gradient color group that matches the given gradient color data.
   *
   * @param GradientColordata - The gradient color data to match against the gradient color groups.
   * @returns The name of the matching gradient color group, or `null` if no match is found.
   */
  findGradientGroupName(GradientColordata: GradientColorGroup): string | null {
    for (const [key, colors] of Object.entries(this.gradientColors)) {
      if (
        colors.stopColor0 === GradientColordata.stopColor0 &&
        colors.stopColor100 === GradientColordata.stopColor100
      ) {
        return key;
      }
    }
    return null;
  }

  /**
   * Formats a given date to a specific string format.
   * 
   * The format used is 'MMM D, YYYY | h:mm A', where:
   * - MMM: Abbreviated month name (e.g., Jan, Feb, etc.)
   * - D: Day of the month
   * - YYYY: Four-digit year
   * - h:mm A: Hour and minute in 12-hour format with AM/PM
   * 
   * @param date - The date to be formatted.
   * @returns The formatted date string.
   */
  formatDate(date:Date) {
    return dayjs.utc(date).local().format('MMM D, YYYY | h:mm A');
  }

  /**
   * Sets the selected dashboard.
   * 
   * @param dashboard - The dashboard to be set as selected.
   */
  setSelectDashboard(dashboard: Dashboard) {
    this.selectedDashboard = ObjectUtil.deepCopy(dashboard);
  }

  /**
   * Retrieves the currently selected dashboard.
   *
   * @returns The selected dashboard.
   */
  getSelectedDashboard() {
    return this.selectedDashboard;
  }


  /**
   * Retrieves the key for the child hierarchy based on the properties of the given entity.
   * 
   * @param entity - The entity object which contains various lists.
   * @returns The key corresponding to the child hierarchy list if found, otherwise an empty string.
   */
  getChildKeyForHierarchy(currentKey: string): string {
    const keyMap: { [key: string]: string } = {
      ccus: 'floors',
      floors: 'rooms',
      rooms: 'equips',
      equips: 'devices',
      devices: ''
    };
    return keyMap[currentKey] || '';
  }

  /**
   *  Method to format the selected scope and display the names of selected scope in the select input field.
   *
   * @param scopeData - The selected scope data.
   */
  formatScopeLabel(scopeData: any) {
    let result = scopeData.length === 1 ? scopeData[0].name : (scopeData.length > 1 ? `${scopeData[0].name} & +${(scopeData.length - 1)}` : '');
    return result;
  }
}