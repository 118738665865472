import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AlertService } from '../../_services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  message: any;

  constructor(private alertService: AlertService) { }

  ngOnInit(): void {
    this.getMessage();
  }

  getMessage() {
    this.subscription = this.alertService.getMessage().subscribe(message => {
      // Prevent a message without a trace from overshadowing an active message that does have a trace
      if (this.message?.traceId && !message?.traceId && message.type === 'success') {
        return;
      }
      this.message = message;
      if (message) {
        setTimeout(() => {
          this.message = false;
        }, 3000);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
