<div class="isLoading" *ngIf="isLoading">
    <img alt="" src="./assets/images/loader.gif">
</div>
<ng-container>
    <div class="workspace-wrapper" [ngClass]="{'all-pointer-events': true}">
        <button #showHideButton class="showHideButton" [ngClass]="{'toolboxActive': showToolBox}" 
        (click)="showHideToolBox(!showToolBox)" mat-fab *ngIf="showToolBoxToggleButton">
        <i class="btn-icon" [ngClass]="showToolBox ? 'fas fa-chevron-left' : 'fas fa-chevron-right'"></i>
        </button>
        <div class="blockly-container" id="blockly-container"></div>
        <div class="codeSnippetContainer" [ngClass]="{'expanded': isCodeBlockVisible,'disableDiv': isLoading}">
            <div class="code-snippet-inner">
                <button #showHideCodeBlock class="showHideCodeBlock" matTooltipClass="codeBlockTooltip"
                    #tooltip="matTooltip" [matTooltip]="codeBlockTooltipText" matTooltipPosition="above"
                    (click)="showHideCodeSnippetBlock()" mat-fab>
                    <i class="btn-icon" [ngClass]="isCodeBlockVisible ? 'fas fa-chevron-down' : 'fas fa-chevron-up'"></i>
                </button>
                <div class="codeSnippetBlock">
                    <div *ngIf="isCodeBlockVisible" class="coding-language-dropdown">
                        <mat-form-field class="blockly-language-selection-dropdown" [floatLabel]="'never'">
                            <mat-select disableOptionCentering panelClass="blockly-language-custom-select-panel"
                                (selectionChange)="onLanguageChange($event)" placeholder="Select a language"
                                id="blockly_language_selection_dropdown">
                                <!-- searchField-->
                                <div class="mat-select-search-inner">
                                    <input matInput class="mat-select-search-input notranslate" [(ngModel)]="searchText"
                                        (input)="searchText" (ngModelChange)="searchInputChanged()" [placeholder]="'Search'" autocomplete="off"
                                        id="blockly-language-search-field" />
                                    <button id="select-search" mat-button *ngIf="searchText" mat-icon-button aria-label="Clear" (click)="clearSearch()"
                                        class="mat-select-search-clear">
                                        <i class="fas fa-times appIcon" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <mat-option *ngFor="let language of filteredLanguages" [value]="language.id">
                                    {{ language.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <ngx-codemirror *ngIf="isCodeBlockVisible" [(ngModel)]="code" [options]="codeOptions"></ngx-codemirror>
                </div>
            </div>
        </div>
    </div>
    <div class="action-buttons-blockly" [ngClass]="{'all-pointer-events': true,'disableDiv': isLoading}">
        <button class="btn btn-outline-primary color-primary text-uppercase back-to-parameters" (click)="backToParameters()">BACK TO PARAMETERS</button>
        <button (click)="saveBlockly();" [disabled]="saveCancelBtnDisabled" *ngIf="!viewOnly" class="btn btn-outline-primary color-primary text-uppercase action-btn"> SAVE </button>
        <button (click)="cancel();"  [disabled]="saveCancelBtnDisabled" *ngIf="!viewOnly" class="btn btn-outline-primary color-primary text-uppercase action-btn"> CANCEL </button>
    </div>
</ng-container>