import { Component, OnInit } from '@angular/core';
import { DashboardService } from './shared/_services/dashboard.service';
import { LocalStorageService } from './shared/_services/local-storage.service';
import { HostRoute } from './shared/enums/host.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'custom-dashboard-ui';

  constructor(public dashboardService:DashboardService,
    private localStorageService: LocalStorageService,
  ) {}
  
  ngOnInit(): void {
    const url = new URL(window.location.href);
    const queryParams = url.searchParams;
    this.handleQueryParams(queryParams);
  }

  async handleQueryParams(queryParams: URLSearchParams) {
    if (queryParams.has('token')) {
      localStorage.setItem('bearer_token', queryParams.get('token') || ''); //Added for tempeorary purpose
    }

    if (queryParams.has('userId')) {
      const userId = queryParams.get('userId') || '';
      localStorage.setItem('userId', userId || '');
      await this.dashboardService.getUserPreferences();
    }

    if (queryParams.has('token') || localStorage?.getItem('bearer_token')) {
      // Save this token in a service, which is used for subsequent calls
      const token = queryParams.get('token') || localStorage?.getItem('bearer_token');
      this.dashboardService.setCurrentOwner(token);
      this.localStorageService.persistToken(token);
    }

    const portal = queryParams.get('portal');
    const isInternalPortal = portal !== HostRoute.FACILISIGHT;
    isInternalPortal ? localStorage.setItem('portal', HostRoute.INTERNAL) : localStorage.setItem('portal', portal);
  }

}
