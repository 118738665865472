import { BlockLogo } from './SequenceKeys.enum';
import { BlockColors } from './blockly-theme';

export const blocks = [
  {
    "type": "haystack_query",
    "message0": "%1 query %2 %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "QUERY"
      },

      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": 270,
    "tooltip": "Query text input",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "haystack_query_with_type",
    "message0": "%1 query %2 %3 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "QUERY_TYPE",
        "options": [
          [
            "point list",
            "list"
          ],
          [
            "point",
            "point"
          ],
          [
            "point with current value",
            "pointValue"
          ]
        ]
      },
      {
        "type": "field_input",
        "name": "QUERY"
      },

      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Query text input",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "haystack_query_with_condition",
    "message0": "%1 query %2 %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "QUERY_TYPE",
        "options": [
          [
            "point list",
            "list"
          ],
          [
            "point",
            "point"
          ],
          [
            "point with current value",
            "pointValue"
          ]
        ]
      },
      {
        "type": "field_input",
        "name": "QUERY"
      },

      {
        "type": "input_dummy"
      },

      {
        "type": "input_value",
        "name": "Query_condition"
      },
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Query text input",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "haystack_query_condition",
    "message0": "%1 %2 %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },

      {
        "type": "input_value",
        "name": "Query_LHS"
      },
      {
        "type": "field_dropdown",
        "name": "OPERATOR",
        "options": [
          [
            "==",
            "=="
          ],
          [
            "!=",
            "!="
          ]
        ]
      },
      {
        "type": "input_value",
        "name": "Query_RHS"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Query conditions to filter",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "haystack_logical_operator",
    "message0": "%1 %2 %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "Query_LHS"
      },
      {
        "type": "field_dropdown",
        "name": "OPERATOR",
        "options": [
          [
            "and",
            "and"
          ],
          [
            "or",
            "or"
          ]
        ]
      },
      {
        "type": "input_value",
        "name": "Query_RHS"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Query conditions to filter",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "haystack_not_operator",
    "message0": "%1 not %2 %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "condition"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Query conditions to filter",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "getWrtiablePointValue",
    "message0": "%1 get current value at %2 for %3 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "level",
        "value": "10",
        "options": [
          [
            "level 1",
            "1"
          ],
          [
            "level 2",
            "2"
          ],
          [
            "level 3",
            "3"
          ], [
            "level 4",
            "4"
          ], [
            "level 5",
            "5"
          ], [
            "level 6",
            "6"
          ], [
            "level 7",
            "7"
          ], [
            "level 8",
            "8"
          ], [
            "level 9",
            "9"
          ],
          [
            "level 10",
            "10"
          ], [
            "level 11",
            "11"
          ], [
            "level 12",
            "12"
          ],
          [
            "level 13",
            "13"
          ], [
            "level 14",
            "14"
          ],
          [
            "level 15",
            "15"
          ], [
            "level 16",
            "16"
          ], [
            "level 17",
            "17"
          ]
        ]
      }, {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id",
    "helpUrl": "Fetch current value for point id"
  },
  {
    "type": "getCurrentHisValue",
    "message0": "%1 get current value for %2 %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id",
    "helpUrl": "Fetch current value for point id"
  },
  {
    "type": "pointwrite",
    "message0": "%1 write value %3 at %2 for %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "level",
        "value": "10",
        "options": [
          [
            "level 1",
            "1"
          ],
          [
            "level 2",
            "2"
          ],
          [
            "level 3",
            "3"
          ], [
            "level 4",
            "4"
          ], [
            "level 5",
            "5"
          ], [
            "level 6",
            "6"
          ], [
            "level 7",
            "7"
          ], [
            "level 8",
            "8"
          ], [
            "level 9",
            "9"
          ],
          [
            "level 10",
            "10"
          ], [
            "level 11",
            "11"
          ], [
            "level 12",
            "12"
          ],
          [
            "level 13",
            "13"
          ], [
            "level 14",
            "14"
          ],
          [
            "level 15",
            "15"
          ], [
            "level 16",
            "16"
          ], [
            "level 17",
            "17"
          ]
        ]
      },
      {
        "type": "input_value",
        "name": "value"
      }, {
        "type": "input_value",
        "name": "id"
      }
    ],
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "inputsInline": true,
    "nextStatement": null,
    "previousStatement": null,
    "tooltip": "Write given value to specific point id",
    "helpUrl": "Documenataion links for haystack tags"
  },
  {
    "type": "hisWrite",
    "message0": "%1 write historized value %2 for %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "value"
      }, {
        "type": "input_value",
        "name": "id"
      }
    ],
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "inputsInline": true,
    "nextStatement": null,
    "previousStatement": null,
    "tooltip": "Histrozied Write given value to specific point id",
    "helpUrl": "Documenataion links for haystack tags"
  },
  {
    "type": "getHistorizedValue",
    "message0": "%1 get historized value from %2 for %3 mins ago %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "input_value",
        "name": "INTERVAL"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id",
    "helpUrl": "Fetch current value for point id"
  },
  {
    "type": "getHistorizedValueFromDateRange",
    "message0": "%1 get historized value from %2 from %3 to %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_input",
        "name": "start_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "end_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "getAggregatedValues",
    "message0": "%1 get historized value from %2 \n groupBy %3 %4 aggregateBy %5 interpolate %6 for last %7 %8 %9",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_number",
        "name": "groupByValue",
        "value": "5"
      },
      {
        "type": "field_dropdown",
        "name": "groupBy",
        "value": "min",
        "options": [
          [
            "minute",
            "min"
          ],
          [
            "hour",
            "hr"
          ],
          [
            "day",
            "day"
          ], [
            "month",
            "mo"
          ], [
            "year",
            "year"
          ]
        ]
      }, {
        "type": "field_dropdown",
        "name": "aggregationFunction",
        "value": "avg",
        "options": [
          [
            "average",
            "avg"
          ],
          [
            "count",
            "count"
          ],
          [
            "min",
            "min"
          ],
          [
            "max",
            "max"
          ],
          [
            "sum",
            "sum"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "interpolationMethod",
        "value": "linear",
        "options": [
          [
            "linear",
            "linear"
          ],
          [
            "cov",
            "cov"
          ],
          [
            "none",
            "none"
          ],
          [
            "null",
            "null"
          ]
        ]
      },
      {
        "type": "field_number",
        "name": "rangeValue",
        "value": "1"
      },
      {
        "type": "field_dropdown",
        "name": "rangeUnit",
        "value": "day",
        "options": [
          [
            "day(s)",
            "day"
          ],
          [
            "hour(s)",
            "hour"
          ],
          [
            "minutes",
            "min"
          ]
        ]
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "getAggregatedValuesFromDateRange",
    "message0": "%1 get historized value from %2 groupBy %3 %4 aggregateBy %5 interpolate %6 from %7 to %8 %9",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_number",
        "name": "groupByValue",
        "value": "5"
      },
      {
        "type": "field_dropdown",
        "name": "groupBy",
        "value": "min",
        "options": [
          [
            "minute",
            "min"
          ],
          [
            "hour",
            "hr"
          ],
          [
            "day",
            "day"
          ], [
            "month",
            "mo"
          ], [
            "year",
            "year"
          ]
        ]
      }, {
        "type": "field_dropdown",
        "name": "aggregationFunction",
        "value": "avg",
        "options": [
          [
            "average",
            "avg"
          ],
          [
            "count",
            "count"
          ],
          [
            "min",
            "min"
          ],
          [
            "max",
            "max"
          ],
          [
            "sum",
            "sum"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "interpolationMethod",
        "value": "linear",
        "options": [
          [
            "linear",
            "linear"
          ],
          [
            "cov",
            "cov"
          ],
          [
            "none",
            "none"
          ],
          [
            "null",
            "null"
          ]
        ]
      },
      {
        "type": "field_input",
        "name": "start_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "end_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "getAggregatedValuesFromDateTimeVariant",
    "message0": "%1 get historized value from %2 groupBy %3 %4 aggregateBy %5 interpolate %6 from %7 %8 to %9 %10 %11",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_number",
        "name": "groupByValue",
        "value": "5"
      },
      {
        "type": "field_dropdown",
        "name": "groupBy",
        "value": "min",
        "options": [
          [
            "minute",
            "min"
          ],
          [
            "hour",
            "hr"
          ],
          [
            "day",
            "day"
          ], [
            "month",
            "mo"
          ], [
            "year",
            "year"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "aggregationFunction",
        "value": "avg",
        "options": [
          [
            "average",
            "avg"
          ],
          [
            "count",
            "count"
          ],
          [
            "min",
            "min"
          ],
          [
            "max",
            "max"
          ],
          [
            "sum",
            "sum"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "interpolationMethod",
        "value": "linear",
        "options": [
          [
            "linear",
            "linear"
          ],
          [
            "cov",
            "cov"
          ],
          [
            "none",
            "none"
          ],
          [
            "null",
            "null"
          ]
        ]
      },
      {
        "type": "field_input",
        "name": "start_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "start_time",
        "text": "00:00"
      },
      {
        "type": "field_input",
        "name": "end_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "end_time",
        "text": "23:59"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "gethistorizedNVariant",
    "message0": "%1 get historized value from %2 for last %3 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_number",
        "name": "rangeValue",
        "value": "1"
      },
      {
        "type": "field_dropdown",
        "name": "rangeUnit",
        "value": "day",
        "options": [
          [
            "day(s)",
            "day"
          ],
          [
            "hour(s)",
            "hour"
          ],
          [
            "minutes",
            "min"
          ]
        ]
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "gethistorizedDateTimeVariant",
    "message0": "%1 get historized value for %2 from %3 %4 to %5 %6",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_input",
        "name": "start_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "start_time",
        "text": "00:00"
      },
      {
        "type": "field_input",
        "name": "end_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "end_time",
        "text": "23:59"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "getKeyValue",
    "message0": "%1 get value for key %3 from %2 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "item"
      },
      {
        "type": "field_input",
        "name": "key",
        "text": "key name"
      }, {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Access the specific property/ key of an object",
    "helpUrl": ""
  },
  {
    "type": "alert",
    "message0": "%1 alert title %2 \n message %3 \n notification %4 \n  default State %5 %6 \n type %7 %8 \n severity %9 %10",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "ALERT_TITLE"
      },
      {
        "type": "field_input",
        "name": "ALERT_MESSAGE"
      },
      {
        "type": "field_input",
        "name": "ALERT_NOTIFICATION_MESSAGE"
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_STATE",
        "options": [
          [
            "enabled",
            "Enabled"
          ],
          [
            "disabled",
            "Disabled"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_TYPE",
        "options": [
          [
            "customer visible",
            "CUSTOMER VISIBLE"
          ],
          [
            "customer invisible",
            "CUSTOMER INVISIBLE"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_SEVERITY",
        "options": [
          [
            "severe",
            "SEVERE"
          ],
          [
            "moderate",
            "MODERATE"
          ],
          [
            "low",
            "LOW"
          ],
          [
            "internal info",
            "INTERNAL_INFO"
          ]
        ]
      },
      {
        "type": "input_dummy"
      }
    ],
    "inputsInline": false,
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Create Custom Alert Details",
    "helpUrl": "Documentation Links for Custom Alerts"
  },
  {
    "type": "alertWithRef",
    "message0": "%1 alert title %2 reference %3 \n message %4 \n notification %5 \n type %6 \n severity %7",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "ALERT_TITLE"
      },
      {
        "type": "input_value",
        "name": "ALERT_REFERENCE"
      },
      {
        "type": "input_value",
        "name": "ALERT_MESSAGE"
      },
      {
        "type": "input_value",
        "name": "ALERT_NOTIFICATION_MESSAGE"
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_TYPE",
        "options": [
          [
            "customer visible",
            "CUSTOMER VISIBLE"
          ],
          [
            "customer invisible",
            "CUSTOMER INVISIBLE"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_SEVERITY",
        "options": [
          [
            "severe",
            "SEVERE"
          ],
          [
            "Moderate",
            "MODERATE"
          ],
          [
            "low",
            "LOW"
          ],
          [
            "internal info",
            "INTERNAL_INFO"
          ]
        ]
      }
    ],
    "inputsInline": true,
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Create Custom Alert Details",
    "helpUrl": "Documentation Links for Custom Alerts"
  },
  {
    "type": "75f_math",
    "message0": "%1 %2 %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "OPERATION_TYPE",
        "options": [
          [
            "customAverage",
            "customAverage"
          ],
          [
            "customMovingaverage",
            "customMovingaverage"
          ],
          [
            "customCount",
            "customCount"
          ],
          [
            "customMin",
            "customMin"
          ],
          [
            "customMax",
            "customMax"
          ],
          [
            "customTopN",
            "customTopN"
          ],
          [
            "customBottomN",
            "customBottomN"
          ],
          [
            "customSum",
            "customSum"
          ]
        ]
      },
      {
        "type": "input_value",
        "name": "ENTITY_ID"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": 270,
    "tooltip": "Custom Influx Timeseries Math Operation on Serverside",
    "helpUrl": "Documenatation URL"
  }, 
  {
    "type": "time",
    "message0": "Wait for %1",
    "args0": [
      {
        "type": "field_number",
        "name": "VALUE",
        "value": 0,
        "min": -100,
        "max": 300
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": 270,
    "tooltip": "time based offsets for execution",
    "helpUrl": "Documentation URLs"
  },
  {
    "type": "logger",
    "message0": "%1 log name %2 value %3 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "log_name"
      },
      {
        "type": "input_value",
        "name": "log_value"
      },
      {
        "type": "input_dummy"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Add a custom comment to your code."
  },
  {
    "type": "comment",
    "message0": "%1 comment %2",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "comment"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Add a custom comment to your code."
  },
  {
    "type": "create_persistent_variable",
    "message0": "%1 persist %2 default %3 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "VARIABLE_NAME"
      },
      {
        "type": "input_value",
        "name": "VARIABLE_VALUE"
      },
      {
        "type": "input_dummy"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Create persistent variable",
  },
  {
    "type": "set_persistent_variable",
    "message0": "%1 persist set %2 %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "VARIABLE_NAME"
      },
      {
        "type": "input_value",
        "name": "VARIABLE_VALUE"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Set persistent variable value",
  },
  {
    "type": "get_persistent_variable",
    "message0": "%1 persist get %2 %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "VARIABLE_NAME"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Set persistent variable value",
  }
];