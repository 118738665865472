import { Component, OnInit, Input } from '@angular/core';
import { WidgetLayoutService } from 'src/app/shared/_services/widget-layout.service';

@Component({
  selector: 'app-areachart',
  templateUrl: './areachart.component.html',
  styleUrls: ['./areachart.component.scss']
})
export class areachartComponent implements OnInit {
  
  @Input() chartOptions: any;
  @Input() chartAxisPositions: any;
  
  constructor(public widgetLayoutService: WidgetLayoutService) { }

  ngOnInit(): void {
  }

  onChartInit(chart: any) {
    this.widgetLayoutService.updateAxisLabelPosition(chart, this.chartOptions, this.chartAxisPositions);
  }
}
