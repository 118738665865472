<div class="accordion">
    <div class="accordion__item">
        <div class="accordion__item__title">
            <ng-container>
                <div class="block-title-container">
                    <!-- Left-aligned Arrow Icon -->
                    <div [ngClass]="{'hide-icon' : (entity == hierarchyEntityTypes.DEVICE || entity == hierarchyEntityTypes.SMDEVICE || !hasChildren)}">
                        <i [ngClass]="(isOpen) ? 'fa fa-angle-down arrow-down' : 'fa fa-angle-right arrow-right'" class="m-t-2 arrow-icon"
                         aria-hidden="true" (click)="activateDropdown()"></i>
                     </div>
            
                    <!-- Mat Checkbox -->
                    <div [ngClass]="entity == 'CCU' ? 'm-l-4' : 'm-l-6'"  class="mat-checkbox-container">
                        <mat-checkbox class="hierarchy-checkbox" [ngClass]="{'hierarchy-checkbox-disabled': isDisabled}" color="primary" [checked]="isEnabled" (change)="onCheckboxChange($event.checked)" [indeterminate]="isIndeterminate" [disabled]="isDisabled"></mat-checkbox>
                    </div>
            
                    <div class="entity-name m-l-10" matTooltip="" showTooltipOnOverflow [matTooltipPosition]="'above'" [matTooltipClass]="'hierarchy-entity-name-tooltip'">{{entityName}}</div>

                    <!-- Right-aligned Text -->
                    <div class="right-aligned-text">{{entity}}</div>
            
                </div>
            </ng-container>
        </div>
        <div *ngIf="isOpen">
            <ng-content></ng-content>
        </div>
    </div>
</div>
