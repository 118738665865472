import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  token: string;

  persistToken(token: any) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }
}
