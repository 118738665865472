import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss']
})
export class piechartComponent implements OnInit {
  @Input() chartOptions: any;
  pieChartRadius:string = '40%';
  chartType:string = 'pie';
  constructor() { }

  ngOnInit(): void {
  }
}
