export enum Frequency {
  DAILY = 'DAILY',
  EVERY_15_MINUTES = 'EVERY_15_MINUTES',
  EVERY_5_MINUTES = 'EVERY_5_MINUTES',
  EVERY_HOUR = 'EVERY_HOUR',
  EVERY_MINUTE = 'EVERY_MINUTE',
  HOURLY = 'HOURLY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum SequenceStatus {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  DISABLE_WITH_LOGS = 'DISABLE_WITH_LOGS',
  ENABLE_WITH_LOGS = 'ENABLE_WITH_LOGS',
  DRY_RUN = 'DRY_RUN'
}

export enum BlockLogo {
  IMAGE = 'assets/images/75f-logo.png',
  WIDTH = 30,
  HEIGHT = 18,
  ALT_TEXT = '75F block'
}

export enum BuilderMode {
  SEQUENCE_BUILDER = 'sequence_builder',
  LIBRARY_BUILDER = 'library_builder'
}

export enum DefaultTabName {
  SITE_SEQUENCES = 'Site Sequences',
  LIBRARY_BUILDER = 'New Sequence',
}

export enum CustomCategory {
  PREDEFINED = 'Custom Primitives',
  FUNCTIONS = 'Functions'
}