<div class="content" id="colorSelectionContent">
  <div class="flexDisplayCentered selectedColorList">
    <div class="selectedColorItem">
      <div class="selected-color-box" (click)="showColorSwatch = !showColorSwatch"
        *ngIf="availableColorSet && availableColorSet[selectedGroup]" id="selectedColorBox">
        <span class="color-box"
          [ngStyle]="{ 'background-image' : linearGradient(availableColorSet[selectedGroup]['stopColor0'], availableColorSet[selectedGroup]['stopColor100']) }">
        </span>
      </div>

      <ng-container *ngIf="isEdit">
        <div class="swatch-container" *ngIf="showColorSwatch" id="colorSwatchContainer">
          <ul class="list-none d-flex row text-center" id="colorList">
            <li class="color-box-list-item" *ngFor="let colorSet of availableColorSet | keyvalue"
              (click)="changeColorSelection(colorSet.key)" id="{{'colorItem_' + colorSet.key}}">
              <i class="fa fa-check color-checked" *ngIf="colorSet.key === selectedGroup" aria-hidden="true"></i>
              <span class="color-box"
                [ngStyle]="{'background-image' : linearGradient(colorSet.value['stopColor0'], colorSet.value['stopColor100']) }">
              </span>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>