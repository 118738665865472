import { Directive, ElementRef, HostListener, Renderer2} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
@Directive({
  selector: '[showTooltipOnOverflow]'
})
export class ShowtooltipDirective {
  constructor(public el: ElementRef<HTMLElement>,
    public tooltip: MatTooltip,
    public renderer: Renderer2) { }
  /**
   * This will be used to show tooltip conditionally
   */
  @HostListener('window:resize')
  onResize() {
    this.checkEllipsis();
  }
  ngAfterViewInit() {
    this.checkEllipsis();
  }
  /**
   * This method will check if the element has overflow width or height
   * If it has then it will set the tooltip message
   */
 
  checkEllipsis() {
    const el = this.findChildNodeWithInnerText(this.el.nativeElement);
    
    if (el.offsetWidth < el.scrollWidth) {
      this.tooltip.message = el.innerText;
    } else if(el.offsetHeight < el.scrollHeight){
      this.tooltip.message = el.innerText;
    } else {
      this.tooltip.message = '';
    }
  }

  /**
   * This method will check if main element with child element is having innertext 
   * if condition doesn't satisfy then return parent node
   */

  findChildNodeWithInnerText(parentNode: any): any {
    const children = parentNode.children;

    for (let i = 0; i < children.length; i++) {
      const childNode = children[i];
      if (childNode.innerText.trim() !== '') {
        return childNode; // Return the child node with inner text
      }
    }

    return parentNode; // return parent node
  }
 
  /**
   * This method will show the tooltip on mouseover
   * and hide the tooltip on mouseout
   */
 
  @HostListener('mouseover', ['$event'])
  showTooltip() {
    this.checkEllipsis();
  }
 
  @HostListener('mouseout', ['$event'])
  hideTooltip() {
    this.tooltip.message = '';
  }
}
