import { Component, Input, OnInit } from '@angular/core';
import { WidgetLayoutService } from 'src/app/shared/_services/widget-layout.service';

@Component({
  selector: 'app-boxchart',
  templateUrl: './boxchart.component.html',
  styleUrls: ['./boxchart.component.scss']
})

export class BoxchartComponent implements OnInit {
  @Input() chartOptions: any;
  @Input() chartAxisPositions: any;
  
  constructor(public widgetLayoutService: WidgetLayoutService) { }


  ngOnInit(): void {
  }

  onChartInit(chart: any) {
    this.widgetLayoutService.updateAxisLabelPosition(chart, this.chartOptions, this.chartAxisPositions);
  }
}
