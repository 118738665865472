import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConnectService {

  widgetData: Map<string, any> = new Map();
  enableExperimentalFeatures = false;

  private widgetDrag$: Subject<string> = new Subject(); // Emits the widget Id that is being dragged, null otherwise
  private widgetResize$: Subject<any> = new Subject(); // Emits the widget Id that is being resized, null otherwise
  private widgetDeleted$: Subject<any> = new Subject(); // Emits the widget Id that is being deleted, null otherwise
  refreshEmbedExternalWebpage$: Subject<any> = new Subject(); // Emits

  constructor() { }

  setWidgetDragged(widgetId: any) {
    this.widgetDrag$.next(widgetId);
  }

  // state is 'start | stop'
  setWidgetResized(data:any) {
    this.widgetResize$.next(data);
  }

  getWidgetDeleted(): Observable<string> {
    return this.widgetDeleted$.asObservable();
  }

  // state is 'start | stop'
  setWidgetDeleted(widgetId: string) {
    this.widgetDeleted$.next(widgetId);
  }

}
