import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionComponent } from './_components/accordion/accordion.component';
import { ConfirmationPopupComponent } from './_components/confirmation-popup/confirmation-popup.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { GradientSwatchComponent } from './_components/gradient-swatch/gradient-swatch.component';
import { ShowtooltipDirective } from './directives/showtooltip.directive';
import { EmbedExternalLayoutComponent } from './_components/embed-external-layout/embed-external-layout.component';
import { ScopeSelectionModalComponent } from './_components/scope-selection-modal/scope-selection-modal.component';
import { MultiSelectionDropdownComponent } from './_components/multi-selection-dropdown/multi-selection-dropdown.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { HierarchyAccordionComponent } from './_components/hierarchy-accordion/hierarchy-accordion.component';

@NgModule({
  declarations: [
    AccordionComponent,
    ConfirmationPopupComponent,
    ClickOutsideDirective,
    ShowtooltipDirective,
    GradientSwatchComponent,
    EmbedExternalLayoutComponent,
    ScopeSelectionModalComponent,
    MultiSelectionDropdownComponent,
    HierarchyAccordionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgSelectModule,
    FormsModule,
    ColorSketchModule,
    NgxSkeletonLoaderModule,
    ColorSketchModule,
    CodemirrorModule
  ],
  exports: [
    AccordionComponent,
    ClickOutsideDirective,
    ShowtooltipDirective,
    ConfirmationPopupComponent,
    EmbedExternalLayoutComponent,
    GradientSwatchComponent
  ]
})
export class SharedModule { }
