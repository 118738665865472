export enum componentType {
    DASHBOARD = 'dashboard',
    CHART = 'chart'
}

export enum chartType {
    PERSONAL_CHARTS = 'personalCharts',
    CHARTS_SHARED_BY_OTHERS = 'chartsSharedByOthers'
}

export enum clickFromValues{
    LEFTPANEL = "left-panel",
    DASHBOARD = "dashboard"
}

export enum userConfirmationState {
    CONFIRM = 'confirm',
    CANCEL = 'cancel',
    NOACTION = 'noaction'
}

export enum dashBoardCtaLabel {
    CREATE_NEW_DASHBOARD = 'CREATE NEW DASHBOARD',
    SAVE_DASHBOARD = 'SAVE DASHBOARD',
    ADD_DASHBOARD = 'ADD DASHBOARD',
}
