import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { HIERARCHY_ENTITY_TYPES } from '../../constants/constants';

@Component({
  selector: 'app-hierarchy-accordion',
  templateUrl: './hierarchy-accordion.component.html',
  styleUrls: ['./hierarchy-accordion.component.scss']
})
export class HierarchyAccordionComponent {

  @Input() entityName: string = '';
  @Input() entity: string = '';
  @Input() isEnabled: boolean = true;
  @Input() isOpen: boolean = false;
  @Input() isIndeterminate: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() hasChildren: boolean = true;

  @Output() selectionChange = new EventEmitter<{ isSelected: boolean }>();
  @Output() accordionChange = new EventEmitter<{ isOpen: boolean, entity:string}>();
  
  hierarchyEntityTypes: any = HIERARCHY_ENTITY_TYPES;

  constructor() { }

  /**
   * Responds to changes in the input properties of the component.
   * @param changes - An object of key-value pairs where the key is the name of the input property and the value is an instance of SimpleChange.
   * This method checks if the 'isOpen' property has changed. If it has, it updates the component's 'isOpen' property with the new value.
   * If the new value is not provided, it defaults to 'false'.
   */
  ngOnChanges(changes: SimpleChanges) {
    const isOpen = changes['isOpen'];
    if (isOpen) {
      this.isOpen = (isOpen.currentValue) ? isOpen.currentValue : false
    }
  }

  /**
   * Toggles the state of the dropdown menu.
   * When called, it switches the `isOpen` property between `true` and `false`.
   */
  activateDropdown() {
    this.isOpen = !this.isOpen;
    this.accordionChange.emit({ isOpen: this.isOpen, entity: this.entity });
  }

  /**
   * Handles the change event of a checkbox.
   * Emits a selectionChange event with the current entity's details and selection status.
   * @param isSelected - A boolean indicating whether the checkbox is selected.
   */
  onCheckboxChange(isSelected: boolean) {
    this.selectionChange.emit({ isSelected });
  }

}
