import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  
  @Input() blockTitle: string = '';
  @Input() isOpen: boolean = false;
  @Input() isDropDown: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  
  ngOnChanges(changes: SimpleChanges) {
    const isOpen = changes['isOpen'];
    if (isOpen) {
      this.isOpen = (isOpen.currentValue) ? isOpen.currentValue : false
    }
  }

  activateDropdown() {
    this.isOpen = !this.isOpen;
  }

}
