<div  class="dashboard-layout-container">

    <!-- Left side Panel -->
    <div *ngIf="showLeftPanel" class="dashboard-left-container" id="dashboard-left-container">

        <!-- Search Container -->
        <div class="dashboard-search-container" id="dashboard-search-container">
            <mat-form-field [style.width.px]=240 (click)="$event.stopPropagation()" class="dashboard-search-input"
                [floatLabel]="'never'" id="searchFormField">
                <input [(ngModel)]='dashboardSearchText'
                    (ngModelChange)="dashboardSearchInputChanged(dashboardSearchText)" autocomplete="off" trim="blur"
                    matInput placeholder="Search for Dashboards" #searchTextField
                    (keydown.Space)="$event.stopPropagation()" (keydown.Enter)="$event.stopPropagation()"
                    id="searchInput" />
                <i class="fas fa-search dashboard-search-icon" matPrefix aria-hidden="true" id="searchIcon"></i>
            </mat-form-field>
        </div>

        <!-- Dashboard List Container -->
        <div class="dashboard-list-container" id="dashboard-list-container">
            <ng-container *ngIf="filteredDashboardList?.length && !dashboardListloading">
                <ul (click)="$event.stopPropagation();" class="dropdown-menu-ul" id="custom-dashboards-list" cdkDropList
                    [cdkDropListAutoScrollDisabled]="true" (cdkDropListDropped)="drop($event)"
                    [cdkDropListAutoScrollStep]="15">

                    <li class="div-bgcolor dashboard-menu-lists"
                        *ngFor="let dashboard of filteredDashboardList; let i = index"
                        [ngClass]="{'dragging': isDraggingDashboard}" cdkDrag cdkDragLockAxis="y"
                        (mouseenter)="onOptionHover(dashboard)" (mouseleave)="onMouseOut()"
                        [cdkDragPreviewClass]="'drag-preview'" (click)="selectDashboardFromNavigation(dashboard)"
                        [ngClass]="{'selected-dashboard': dashboard?.id == selectedDashboard?.dashboardId ||  dashboard?.id == selectedDashboard?.id}">

                        <ng-container>
                            <div class="dashboard-text-height" placement="left" id="dashboard-text-height">
                                <div class="dashboard-name-container">
                                    <img src="assets/images/drag-block.svg" class="drag-dashboard-icon" alt=""
                                        id="drag-dashboard-icon" cdkDragHandle [ngClass]="{'drag-icon-hidden': dashboardSearchText || !(hoveredDashboard && hoveredDashboard.id == dashboard.id)}">
                                    <div class="dashboard-lable-name" id="dashboard-lable-name"
                                        (mouseout)="unhoveredDashboard()" matTooltip="" showTooltipOnOverflow [matTooltipPosition]="'above'" [matTooltipClass]="'dashboard-parameter-name-tooltip'">
                                        {{dashboard?.name}}
                                    </div>
                                    <span class="icon-class-dashboard type-icon-margin" id="dashboard-type-icon"
                                        *ngIf="isSharedByMe(dashboard)">
                                        <i matTooltip="Shared By Me" matTooltipPosition="above"
                                            class="fas fa-share-alt secondary-color dashboard-type-icon"
                                            aria-hidden="true"></i>
                                    </span>
                                    <span class="icon-class-dashboard type-icon-margin" id="dashboard-type-icon"
                                        *ngIf="isPersonal(dashboard)">
                                        <i matTooltip="Personal" matTooltipPosition="above"
                                            class="fas fa-user dashboard-type-icon secondary-color"
                                            aria-hidden="true"></i>
                                    </span>
                                    <span class="icon-class-dashboard shared-by-others-icon" id="dashboard-type-icon"
                                        *ngIf="isSharedByOthers(dashboard)">
                                        <i matTooltip="Shared By Others" matTooltipPosition="above"
                                            class="fas fa-users dashboard-type-icon secondary-color"
                                            aria-hidden="true"></i>
                                    </span>
                                    <span class="icon-class-dashboard" id="dashboard-info-icon"
                                        (mouseover)="onMouseOver(dashboard)" [tippy]="accessInfoToolTip">
                                        <i class="fas fa-info-circle info-panel-icon primary-color"
                                            aria-hidden="true"></i>
                                    </span>
                                </div>

                                <div class="action-container" id="action-container">
                                    <span class="dashboard-likes-count"  [ngClass]="{'hideVisibility': dashboard?.noOfLikes < 1 }"
                                        *ngIf="isSharedByMe(dashboard) || isSharedByOthers(dashboard) && dashboard?.noOfLikes">
                                        <span
                                            [matTooltip]="(dashboard?.noOfLikes ?? 0) > 999 ? (dashboard?.noOfLikes ?? 0) + ' Likes' : ''"
                                            [matTooltipPosition]="'above'">
                                            {{formatLikes(dashboard?.noOfLikes ?? 0)}}
                                            {{dashboard?.noOfLikes == 1 ? 'Like' : 'Likes'}}
                                        </span>
                                    </span>
                                    <span class="dashboard-like-icon" *ngIf="isSharedByOthers(dashboard)">
                                        <img (click)="$event.stopPropagation(); updateEntityLike(dashboard,'dashboard')" *ngIf="dashboard.likedByLoggedInUser" class="like-image"
                                            src="assets/images/selected-like.svg" alt="" id="liked-image">
                                        <img *ngIf="!dashboard.likedByLoggedInUser"
                                            (click)="$event.stopPropagation(); updateEntityLike(dashboard,'dashboard')" class="like-image"
                                            src="assets/images/default-like.svg" alt=""
                                            id="default-like-image">
                                    </span>
                                    <span class="dashboard-settings">
                                        <button class="button-config" [disableRipple]="true" mat-icon-button
                                            [matMenuTriggerFor]="menu" id="config"  #dashBoardSettingsTrigger="matMenuTrigger"
                                            (click)="$event.stopPropagation(); updateSelectedDashboard(dashboard)">
                                            <i class="fa fa-cog settingIcon" aria-hidden="true"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>

                        </ng-container>
                    </li>
                </ul>

            </ng-container>
            <ng-container *ngIf="!filteredDashboardList?.length && !dashboardListloading">
                <div (click)="$event.stopPropagation();" id="no-dashboard-display" class="no-dashboard">
                    There is no dashboard to display.
                </div>
            </ng-container>
            <ng-container *ngIf="dashboardListloading">   
                <div class="loader-column" *ngFor="let repeat of [0,1]">
                    <ngx-skeleton-loader [theme]="{ 'border-radius': '0', 'height': '20px', 'background-color': '#eff0f1'}" count="2"></ngx-skeleton-loader>
                </div>
            </ng-container>
        </div>

        <div id="add-new-dashboard" class="add-dashboard-btn" (click)="onAddNewDashboard()">
            ADD NEW DASHBOARD
        </div>

    </div>

    <!-- Left Panel Open/Close button -->
    <button #showHideButton class="showHide-leftpanel-btn" id="open-close-leftpanel"
        [ngClass]="{'leftPanel-open': showLeftPanel}" (click)="showHideToolBox(!showLeftPanel)" mat-fab
        id="show-tool-box">
        <i class="slider-btn-icon"
            [ngClass]="this.showLeftPanel ? 'fas fa-chevron-left m-r-2' : 'fas fa-chevron-right m-l-2'"></i>
    </button>

    <!-- Right side Panel -->
    <div class="dashboard-right-container" *ngIf="!noDashboardSelected">
        <div [ngClass]="{'rightContent-leftclose': !showLeftPanel}" class="right-panel-content">
            <div [ngClass]="!showLeftPanel ? 'widget-container-leftclose' :'widget-container-layout'" >
            <div class="d-flex header-container">
                <div class="display-inline-flex">
                    <div id="tag-name" *ngIf="getCrudLabel()" class="tag-style">{{getCrudLabel()}}</div>
                    <div id="dashboard-name" class="dashboard-name-text">{{selectedDashboard?.name}}</div>
                    <div id="user-icon" class="dashboard-shared-icon">
                        <i *ngIf="isPersonal(selectedDashboard)" matTooltip="Personal" class="fas fa-user personal-icon"
                            aria-hidden="true"></i>
                        <i *ngIf="isSharedByMe(selectedDashboard)" matTooltip="Shared By Me"
                            class="fas fa-share-alt personal-shared-icon" aria-hidden="true"></i>
                        <i *ngIf="isSharedByOthers(selectedDashboard)" matTooltip="Shared By Others"
                            class="fas fa-users shared-user-icon" aria-hidden="true"></i>
                    </div>
                    <div id="description-tooltip"><i (mouseover)="onMouseOver(selectedDashboard)" [tippy]="accessInfoToolTip" class="fas fa-info-circle info-icon" aria-hidden="true"></i></div>
                </div>

                <!-- Widget content If nowidget is added -->
                <ng-container>
                    <div class="ml-auto display-inline-flex">
                        <div id="add-chart-btn" class="add-chart-btn" *ngIf="!isSharedByOthers(selectedDashboard) && !viewMode"
                            (click)="clickedAddCharts();" [matMenuTriggerFor]="chartsmenu" id="add-charts">
                            ADD WIDGET
                        </div>
                        <mat-menu (click)="$event.stopPropagation();" (close)="onMenuClose()" class="chart-addition-list" #chartsmenu="matMenu" xPosition="before" id="create-chart-menu">
                        <button mat-menu-item class="chart-addition-menu" id= "create-new-chart-btn" (click)="onClickCustomChart()">Custom Charts</button>
                        <button *ngIf="(!isReadOnly) && crudActionLabel != crudOperationList.new" [matTooltipPosition]="'above'" [matTooltip]="chartsList?.length >= 15 ? chartsLimitReachedMsg: ''" mat-menu-item class="chart-addition-menu" id= "create-external-link-btn" (click)="openEmbedExternal(false)" [ngClass]="chartsList?.length >= 15 ? 'disable-external-chart' : ''">Embed External Link</button>
                        </mat-menu>
                        <span *ngIf="(crudActionLabel != crudOperationList.new && crudActionLabel != crudOperationList.duplicate)" class="widget-settings">
                            <button class="widget-button-config" [disableRipple]="true" mat-icon-button
                                id="widget-config" 
                                [matMenuTriggerFor]="dashboardMenuSettings" mat-icon-button
                                #dashboardMenuSettingsTrigger="matMenuTrigger">
                                <i class="fa fa-cog widget-settingIcon edit-dashboard-settings" aria-hidden="true"></i>
                            </button>
                        </span>
                    </div>
                    <mat-menu (click)="$event.stopPropagation();" (close)="onMenuClose()" class="chart-addition-list" #dashboardMenuSettings="matMenu" xPosition="before" id="create-chart-menu">
                        <button mat-menu-item *ngIf="isPersonal(selectedDashboard) || isSharedByMe(selectedDashboard)" class="chart-addition-menu" id= "edit-personal-dashboard" (click)="editDashboard()">Edit Dashboard Details</button>
                        <button mat-menu-item *ngIf="(isPersonal(selectedDashboard) || isSharedByMe(selectedDashboard)) && !isDashboardUpdated" class="chart-addition-menu" id= "duplicate-personal-dashboard" (click)="duplicateDashboard()">Save as New Dashboard</button>
                        <button mat-menu-item *ngIf="isDashboardPresent()" class="chart-addition-menu" id= "remove-dashboard" (click)="removeDashboard('dashaboardLayout')">Remove Dashboard</button>
                        <button mat-menu-item *ngIf="isSharedByOthers(selectedDashboard)" class="chart-addition-menu" id= "duplicate-dashboard" (click)="duplicateDashboard()">Duplicate Dashboard</button>
                        <button mat-menu-item class="chart-addition-menu" id= "export-dashboard" (click)="export('dashboard')" >Export</button>
                        <button mat-menu-item *ngIf="isPersonal(selectedDashboard) || isSharedByMe(selectedDashboard)" class="chart-addition-menu" id= "delete-dashboard" (click)="deleteDashbooard('dashaboardLayout')">Delete</button>
                    </mat-menu>
                </ng-container>

            </div>

            <!-- scope, Date and Interval selection  -->
            <div class="scope-selection-container">
                <div class="scopeSelectionOption">
                    <mat-select class="select-width scope-dropdown" disableOptionCentering id="scope-dropdown"
                        (click)="openScopeSelectionModal('dashboard')" [disabled]="previewMode"
                        [placeholder]="selectedScopeLabel && selectedScopeLabel.length ? selectedScopeLabel : scopedSelectedPlaceholder"
                        [ngClass]="{'dropdown-selected': selectedScopeLabel && selectedScopeLabel.length}">
                    </mat-select>
                </div>

                <!-- Date placeholder  -->
                <div class="m-l-16 calendar-focused-underline" (click)="calendarClicked(true)">
                    <mat-form-field class="calendar-field placeholder-spacing" [ngClass]="{
                        'today-width': selectedRangeLabel === 'Today',
                        'last6Months-width': selectedRangeLabel === 'Last 6 Months',
                        'lastYear-width' : selectedRangeLabel === 'Last Year',
                        'lastWeek-width' : selectedRangeLabel === 'Last Week',
                        'manual-range-width' :selectedRangeLabel === 'CUSTOM' || selectedRangeLabel === 'Last 3 Days',
                        'disable': previewMode
                      }">
                        <input matInput #dateRangePicker ngxDaterangepickerMd
                            [locale]="{ format: 'MMM DD, YYYY', separator: ' to ' }" readonly [ranges]="ranges"
                            [(ngModel)]="dashboardDateSelected" [showRangeLabelOnInput]="false" [alwaysShowCalendars]="true"
                            [linkedCalendars]="true" drops="down" opens="rigth" [autoApply]="true"
                            (rangeClicked)="handleDateRangeChange($event)" [maxDate]=maxDate
                            (datesUpdated)="onDatesUpdated()" />
                        <i matSuffix class="fa fa-solid fa-calendar color-primary m-l-10 calendar-position"
                            (click)="handleOpenDateRangePicker()" [ngClass]="{'disable': previewMode}" aria-hidden="true"></i>
                    </mat-form-field>

                </div>

                <div class="m-l-16 durationOption">
                    <mat-select class="select-width hourly-dropDown" [disabled]="previewMode" disableOptionCentering placeholder="Hourly"
                        [panelClass]="'hourly-dropDown-panel'" (selectionChange)="dashboardGroupByChanged(true)" [(ngModel)]="dashboardGroupBy">
                        <mat-option *ngFor="let option of hourOptions" [value]='option'>
                            {{option}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            </div>

            <div class="add-chart-text" *ngIf="!chartsList?.length && !isDasboardLoading">
                Please add charts.
            </div>

            <div class="isLoading" *ngIf="isDasboardLoading">
               <img class="loading-icon" src="assets/images/loader.gif" alt="loading-icon" id="loading-icon">
            </div>

            <!-- Chart Widgets -->
            <div *ngIf="isGridsterVisible" class="dashboard-layout-body" id="dashboard-layout-body">
                <gridster [options]="gridsterOptions" *ngIf="chartsList?.length && !isDasboardLoading" id="gridsterContainer">
                    <gridster-item class="gridster-container" *ngFor="let chart of chartsList;let i = index" [item]="chart">
                        <ng-container>
                            <div class="chart-widgets">
                                <div class="chart-widgets-header">
                                    <div class="widget-text" placement="left" id="widget-text">
                                        <div class="widget-name-container">
                                            <div class="handle-icon">
                                                <i *ngIf="!previewMode" class="fas fa-grip-vertical color-primary dragHandle widget-drag-handle" aria-hidden="true"></i>
                                            </div>
                                            <div class="widget-label-name" id="widget-label-name" (mouseout)="unhoveredDashboard()"
                                                matTooltip="" showTooltipOnOverflow
                                                [matTooltipClass]="'widget-parameter-name-tooltip'" [matTooltipPosition]="'above'">
                                                {{chart?.name}}
                                            </div>
                                            <span class="icon-class-widget widget-icon-margin" id="widget-type-icon"
                                                *ngIf="!chart.isEmbedded && isSharedByMe(chart)">
                                                <i matTooltip="Shared By Me" matTooltipPosition="above"
                                                    class="fas fa-share-alt secondary-color widget-type-icon"
                                                    aria-hidden="true"></i>
                                            </span>
                                            <span class="icon-class-widget widget-icon-margin" id="widget-type-icon"
                                                *ngIf="chart.isEmbedded || isPersonal(chart)">
                                                <i matTooltip="Personal" matTooltipPosition="above"
                                                    class="fas fa-user widget-type-icon secondary-color" aria-hidden="true"></i>
                                            </span>
                                            <span class="icon-class-widget shared-by-others-icon" id="widget-type-icon"
                                                *ngIf="!chart.isEmbedded && isSharedByOthers(chart)">
                                                <i matTooltip="Shared By Others" matTooltipPosition="above"
                                                    class="fas fa-users widget-type-icon secondary-color" aria-hidden="true"></i>
                                            </span>
                                            <span class="icon-class-widget" *ngIf="!chart.isEmbedded" id="widget-info-icon" (mouseover)="onMouseOver(chart)"
                                                [tippy]="accessInfoToolTip">
                                                <i class="fas fa-info-circle info-widget-icon primary-color" aria-hidden="true"></i>
                                            </span>
                                        </div>
            
                                        <div class="widget-action-container" id="widget-action-container">
                                            <span class="widget-likes-count" *ngIf="!chart.isEmbedded && (isSharedByMe(chart) || isSharedByOthers(chart))">
                                                <span
                                                    [matTooltip]="(chart?.noOfLikes ?? 0) > 999 ? (chart?.noOfLikes ?? 0) + ' Likes' : ''"
                                                    [matTooltipPosition]="'above'">
                                                    {{formatLikes(chart?.noOfLikes ?? 0)}}
                                                    {{chart?.noOfLikes == 1 ? 'Like' : 'Likes'}}
                                                </span>
                                            </span>
                                            <span class="widget-like-icon" [ngClass]="{'disable': previewMode}" (click)="updateEntityLike(chart,'chart')" *ngIf="!chart.isEmbedded && isSharedByOthers(chart)">
                                                <img class="widget-like-image" *ngIf="chart.likedByLoggedInUser && !previewMode"
                                                    src="assets/images/selected-like.svg" alt="" id="widget-liked-image">
                                                <img class="widget-like-image" *ngIf="!chart.likedByLoggedInUser && !previewMode"
                                                     src="assets/images/default-like.svg"
                                                    alt="" id="widget-default-like-image">
                                                <img class="widget-like-image" *ngIf="chart.likedByLoggedInUser && previewMode"
                                                    src="assets/images/selected-disable-like.svg" alt="" id="widget-liked-image">
                                                <img class="widget-like-image" *ngIf="!chart.likedByLoggedInUser && previewMode"
                                                     src="assets/images/default-disable-like.svg"
                                                    alt="" id="widget-default-like-image">
                                            </span>
                                            <span class="widget-settings">
                                                <button class="widget-button-config" [ngClass]="{'disable': previewMode}" [disableRipple]="true" mat-icon-button
                                                    id="widget-config" (click)="openSettingsMenu(chart)"
                                                    [matMenuTriggerFor]="widgetMenuSettings" mat-icon-button
                                                    #widgetMenuSettingsTrigger="matMenuTrigger">
                                                    <i class="fa fa-cog widget-settingIcon" [ngClass]="{'disable': previewMode}" aria-hidden="true"></i>
                                                </button>
                                            </span>
            
                                            <!-- Widget Settings Menu Content  -->
                                            <mat-menu #widgetMenuSettings="matMenu" class="settings-menu-panel" xPosition="before"
                                                id="settingsMenu">
                                                <button *ngIf="!chart.isEmbedded" mat-menu-item class="mat-menu-btn" id="scope-button"
                                                    (click)="openScopeSelectionModal('chart',chart)">
                                                    <span class="settings-option-text">Scope</span> <span
                                                        class="placeHolderSubMenu sub-label" [ngClass]="{'m-l-75': !chart.scopeSameAsDashboard}" matTooltip=""               
                                                        [matTooltipPosition]="'above'"
                                                        showTooltipOnOverflow>{{chart.scopeSameAsDashboard ? 'Same as Dashboard' : chart.scopeLabel}}</span>
                                                    <i class="fa fa-angle-right widget-settings-caret-right" aria-hidden="true"></i>
                                                </button>
            
            
                                                <button *ngIf="!chart.isEmbedded && !hideDataRange" mat-menu-item class="mat-menu-btn" id="date-range-button"
                                                    [matMenuTriggerFor]="dateRangeSubMenu"
                                                    #dateRangeSubMenuTrigger="matMenuTrigger">
                                                    <span class="settings-option-text">Date Range </span> <span
                                                        class="placeHolderSubMenu" matTooltip="" [matTooltipPosition]="'above'"
                                                        showTooltipOnOverflow>{{chart?.dateRangeSameAsDashboard ? 'Same as
                                                        Dashboard' : chart?.chartDateFormatted }}</span>
                                                    <i class="fa fa-angle-right widget-settings-caret-right" aria-hidden="true"></i>
                                                </button>
                                                <mat-menu #dateRangeSubMenu="matMenu" class="settings-sub-menu-panel m-r-5"
                                                    id="subMenuDateRange">
                                                    <button mat-menu-item id="dashBoard" (click)="setDateOnSettings(true,chart)"
                                                        [ngClass]="{'selected-interval': chart?.dateRangeSameAsDashboard == true}">Same
                                                        as Dashboard</button>
                                                    <button mat-menu-item id="customDateRange" [matMenuTriggerFor]="openDateRange"
                                                        (click)="setDateOnSettings(false,chart)"
                                                        #openDateRangeTrigger="matMenuTrigger" class="custom-date-range"
                                                        [ngClass]="{'selected-interval': chart?.dateRangeSameAsDashboard == false}">Custom
                                                        Date Range</button>
                                                </mat-menu>
            
                                                <mat-menu #openDateRange="matMenu" xPosition="before"
                                                    class="custom-date-menu-panel">
                                                    <ngx-daterangepicker-material #customDateRangePicker
                                                        [locale]="{ format: 'MMM DD, YYYY', separator: ' to ' }" readonly
                                                        [ranges]="ranges" [showRangeLabelOnInput]="false"
                                                        [alwaysShowCalendars]="true" [linkedCalendars]="true" drops="down"
                                                        opens="rigth" [autoApply]="true"
                                                        (rangeClicked)="handleDateChangeOnSettings($event,chart); closeMenu()"
                                                        [maxDate]=maxDate
                                                        (datesUpdated)="onDatesUpdatedOnSettings($event,chart); closeMenu()"
                                                        [startDate]="chart?.startDateTime" [endDate]="chart?.endDateTime">
                                                    </ngx-daterangepicker-material>
                                                </mat-menu>
                                                <button mat-menu-item *ngIf="!chart.isEmbedded && !hideGroupBy" class="mat-menu-btn" id="group-by-button"
                                                    [matMenuTriggerFor]="groupBySubMenu">
                                                    <span class="settings-option-text">Group By</span> <span
                                                        class="placeHolderSubMenu sub-label" matTooltip=""
                                                        [matTooltipPosition]="'above'"
                                                        showTooltipOnOverflow>{{chart?.groupBy}}</span>
                                                    <i class="fa fa-angle-right widget-settings-caret-right" aria-hidden="true"></i>
                                                </button>
                                                <mat-menu #groupBySubMenu="matMenu" class="settings-sub-menu-panel m-r-5"
                                                    id="subMenuGroupBy">
                                                    <button mat-menu-item *ngFor="let interval of timeIntervals" [id]="interval"
                                                        (click)="setGroupAndView(interval,true,chart)"
                                                        [ngClass]="{'selected-interval': interval === chart?.groupBy}">{{ interval
                                                        }}</button>
                                                </mat-menu>
            
                                                <button mat-menu-item *ngIf="!chart.isEmbedded && !hideViewBy" class="mat-menu-btn" id="view-by-button"
                                                    [matMenuTriggerFor]="viewBySubMenu">
                                                    <span class="settings-option-text">View By</span> <span
                                                        class="placeHolderSubMenu sub-label" matTooltip=""
                                                        [matTooltipPosition]="'above'"
                                                        showTooltipOnOverflow>{{chart?.viewBy}}</span>
                                                    <i class="fa fa-angle-right widget-settings-caret-right" aria-hidden="true"></i>
                                                </button>
                                                <mat-menu #viewBySubMenu="matMenu"
                                                    class="settings-sub-menu-panel view-by-sub-menu-panel m-r-5" id="subMenuViewBy">
                                                    <button mat-menu-item *ngFor="let option of viewByMenu" [id]="option"
                                                        (click)="setGroupAndView(option,false,chart)"
                                                        [ngClass]="{'selected-interval': option === chart?.viewBy}">{{ option
                                                        }}</button>
                                                </mat-menu>
                                            
                                            
                                                <button mat-menu-item class="mat-menu-btn" id="edit-chart-button"
                                                    (click)="openEditOrViewChart()">
                                                    <span class="settings-option-text">{{isChartPersonal(selectedChart) ? 'Edit Chart Config' : 'View Chart Config'}}</span>
                                                </button>

                                                <button mat-menu-item *ngIf="!chart.isEmbedded" class="mat-menu-btn" id="export-button">
                                                    <span class="settings-option-text" (click)="export('chart', chart);">Export</span>
                                                </button>            
            
                                                <button mat-menu-item class="mat-menu-btn" id="copy-button"
                                                    [matMenuTriggerFor]="copyToSubMenu">
                                                    <span class="settings-option-text">Copy</span>
                                                    <i class="fa fa-angle-right widget-settings-caret-right" aria-hidden="true"></i>
                                                </button>
                                                <mat-menu (closed)="onMoveCopySubMenuClosed()" #copyToSubMenu="matMenu"
                                                    class="move-tosub-menu-panel calendar-focused-underline m-r-5">
                                                    <div class="search-wrapper">
                                                    <mat-form-field class="search-move-copy" (click)="$event.stopPropagation()"
                                                        [floatLabel]="'never'" >
                                                        <input matInput placeholder="Search for Dashboard" [(ngModel)]="searchTextForDashBoard" (ngModelChange)="searchDashboardForMoveCopy(searchTextForDashBoard);">
                                                        <i class="fas fa-search dashboard-search-icon" matPrefix aria-hidden="true"
                                                            id="searchIcon"></i>
                                                    </mat-form-field>
                                                </div>
                                                 
                                                <div class="dashboard-list">
                                                    <ng-container *ngFor="let dashBoardData of dashBoardForMoveCopy || loggedInUserDashboardList">
                                                        <button mat-menu-item  *ngIf="dashBoardData?.name !== 'No Result Found' ">
                                                        <mat-checkbox class="copy-to-checkbox" (click)="$event.stopPropagation()"
                                                            [checked]="dashBoardIsChecked(chart.id, dashBoardData.id)"
                                                            (change)="onDashboardSelectedForCopy($event, chart.id, dashBoardData.id)">
                                                            <span showTooltipOnOverflow matTooltip="" showTooltipOnOverflow [matTooltipPosition]="'above'"
                                                                [matTooltipClass]="'dashboard-parameter-name-tooltip'" class="copy-to-list">{{ dashBoardData?.name }}</span></mat-checkbox>
                                                        </button>

                                                        <button mat-menu-item *ngIf="dashBoardData?.name === 'No Result Found' ">
                                                            <span class="copy-to-list">{{ dashBoardData?.name }}</span>
                                                        </button>
                                                    </ng-container>
                                                </div>
                                                </mat-menu>

                                                <button mat-menu-item class="mat-menu-btn" id="remove-button"
                                                    *ngIf="!chart.isEmbedded && !isSharedByOthers(selectedDashboard) && !isVofmUser" (click)="removeChart(chart)">
                                                    <span class="settings-option-text">Remove</span>
                                                </button>

                                                <button mat-menu-item class="mat-menu-btn" id="delete-button"
                                                    *ngIf="!isSharedByOthers(chart) && !isVofmUser && chart.isEmbedded" (click)="deleteChart(chart)">
                                                    <span class="settings-option-text">Delete</span>
                                                </button>
        
        
                                            </mat-menu>
                                        </div>
                                    </div>
                                </div>
                                <div class="chart-widgets-content">   
                                   <app-widget-layout id="{{chart?.id}}" [widgetObj]="chart"></app-widget-layout>                             
                                </div>
                            </div>
                        </ng-container>
            
                    </gridster-item>
                </gridster>
            
            </div>

            <!-- footer content  -->
            <div [ngClass]="{'rightbottom-leftclose': !showLeftPanel}" class="right-bottom-content" *ngIf="(crudActionLabel == crudOperationList.preview || crudActionLabel == crudOperationList.new || isDashboardUpdated || crudActionLabel == crudOperationList.duplicate) && crudActionLabel">
                <div class="footer-btn-content">
                    <div class="left-side-actions">
                        <div (click)="editNewlyCreatedDashboard()" *ngIf="(crudActionLabel == crudOperationList.new || crudActionLabel == crudOperationList.duplicate) && dashboardService?.isSaveasnewOrDuplicate" id="backToDashboard-btn" class="backto-bashboard-btn ">
                            BACK TO DASHBOARD DETAILS 
                        </div>
                        <div (click)="onAddNewDashboard()" *ngIf="crudActionLabel == crudOperationList.preview || (!isDashboardPresent() && crudActionLabel != crudOperationList.duplicate && crudActionLabel != crudOperationList.new)" id="backToDashboard-btn" class="backto-bashboard-btn ">
                            BACK TO DASHBOARD POOL
                        </div>
                    </div>

                    <div class="right-side-actions" *ngIf="!viewMode" >
                        <div *ngIf="(crudActionLabel != crudOperationList.new && crudActionLabel != crudOperationList.preview && isDashboardUpdated) || crudActionLabel == crudOperationList.duplicate" id="discard-btn" class="discard-btn" [ngClass]="{'disable': !isDashboardUpdated}" (click)="discardChanges()">
                            DISCARD CHANGES 
                        </div>
                        <span *ngIf="isDashboardUpdated && crudActionLabel != crudOperationList.new && crudActionLabel != crudOperationList.preview && !dashboardService?.isSaveasnewOrDuplicate" class="divider"></span>
                        <div *ngIf="!previewMode" id="createNewDashboard-btn" class="createNew-dashboard-btn" [ngClass]="{'disable': disableDashboard()}" (click)="saveDashboard()">
                            {{saveDashboardLabel}}
                        </div>
                        <div [matTooltip]="disablePreviewDashboardBtn() ? previewModeLimitReachedMsg: '' " matTooltipPosition="above" *ngIf="previewMode && !isDashboardPresent()" id="createNewDashboard-btn" class="createNew-dashboard-btn" [ngClass]="{'disable-preview-btn': disablePreviewDashboardBtn()}" (click)="applyDashboardToView()">
                            ADD DASHBOARD
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- No Dashboard Selected Panel -->
    <div *ngIf="noDashboardSelected" class="nodashboard-layout">
        <div id="noDashboard-content" class="nodashboard-container">
            <img class="nodashboard-icon" alt="no-dashboard-icon" id="no-dashboard-icon"
                src="assets/images/nodashboardIcon.svg">
            <div class="nodashboard-text">Please select a dashboard to proceed.</div>
        </div>
    </div>
</div>



<!-- Tooltip Content  -->
<ng-template #accessInfoToolTip>
    <div class="tooltip" id="dashboardTooltip">
        <div class="tooltip-header entity-tooltip">
            <div class="tool-text">
                <div class="sub-text p-b-15" [id]="hoveredDashboard?.why"><strong>Why: </strong>
                    {{hoveredDashboard?.why}}</div>
                <div class="sub-text p-b-15" [id]="hoveredDashboard?.what"><strong>What:
                    </strong>{{hoveredDashboard?.what}}</div>
                <div class="sub-text">
                    <strong [id]="hoveredDashboard?.owner?.firstName">Created By:
                    </strong>{{hoveredDashboard?.owner?.firstName}}
                    {{hoveredDashboard?.owner?.lastName}}{{hoveredDashboard?.owner?.userId === 'external'
                    ? '(External)' : ''}}
                    <b class="p-l-5" id="lastUpdated">Last Updated:
                    </b>{{formatDescDate(hoveredDashboard?.lastModifiedDateTime)}}
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Dashboard Settings Menu Content  -->
<mat-menu #menu="matMenu" class="dashboard-settings-panel" xPosition="before" id="mainMenu">
    <button mat-menu-item class="mat-menu-btn" id="remove-dashboard-button" (click)="removeDashboard('left-panel')">
        <span>Remove Dashboard</span>
    </button>
    <button mat-menu-item class="mat-menu-btn" id="delete-dashboard-button" (click)="deleteDashbooard('left-panel')"
        *ngIf="isUsersDashboard(selectedDashboardOption)">
        <span>Delete</span>
    </button>
</mat-menu>

    