<div *ngIf="message" class="alert-container"
    [ngClass]="{ 'alert': message, 'alert--success': (message?.type) === 'success', 'alert--danger': (message?.type) === 'error' }">
    <ng-container>
        <ng-container *ngIf="message.type == 'success'">
            <i class="fas fa-check alert__green" aria-hidden="true"> </i>
            <span class="message__title alert__green">Success</span>
            <i class="fa fa-exclamation alert__green__exc alert__green" aria-hidden="true"></i>
        </ng-container>
        <ng-container *ngIf="message.type == 'warning'">
            <i class="fas fa-exclamation alert__red" aria-hidden="true"> </i>
            <span class="message__title alert__red">Warning</span>
        </ng-container>
        <ng-container *ngIf="message.type == 'error'">
            <i class="fas fa-exclamation-triangle alert__red" aria-hidden="true"></i>
            <span class="message__title alert__red">Error</span>
            <i class="fa fa-exclamation alert__red__exc alert__red" aria-hidden="true"></i>
        </ng-container>
        <span class="message__text" [innerHTML]="message.text"></span>
    </ng-container>
</div>