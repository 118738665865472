import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '../../_services/common.service';
import { GradientColors } from '../../models/widget.model';

@Component({
  selector: 'app-gradient-swatch',
  templateUrl: './gradient-swatch.component.html',
  styleUrls: ['./gradient-swatch.component.scss']
})
export class GradientSwatchComponent implements OnInit {

  constructor(public commonService:CommonService) { }

  @Input() selectedGroup: keyof GradientColors;
  availableColorSet:GradientColors;
  isEdit:boolean = true;
  showColorSwatch:boolean = false;
  
  @Output() colorSelectionChange: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {
    this.availableColorSet = this.commonService.gradientColors;
  }

  changeColorSelection(key:keyof GradientColors) {
    this.selectedGroup = key;
    this.showColorSwatch = false;
    const selectedColorset = (this.availableColorSet && (key in this.availableColorSet)) ? this.availableColorSet[key as keyof GradientColors] : '';
    this.colorSelectionChange.emit(selectedColorset);
  }


  linearGradient(stopColor0:string, stopColor100:string) {
    return `linear-gradient(to right, ${stopColor0} , ${stopColor100})`;
  }

  @HostListener('click', ['$event'])
  hostClicked(event: Event) {
    const target = event.target as HTMLElement;
    if (!(target.classList.contains('selected-color-box')
      || target.classList.contains('color-box')
      || target.classList.contains('color-box-list-item'))) {
      this.showColorSwatch = false;
    }
  }

}
