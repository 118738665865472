import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WidgetService } from '../../_services/widget.service';
import { AlertService } from '../../_services/alert.service';

@Component({
    selector: 'app-embed-external-layout',
    templateUrl: './embed-external-layout.component.html',
    styleUrls: ['./embed-external-layout.component.scss']
  })

  export class EmbedExternalLayoutComponent implements OnInit {
    editExternalName: boolean;
    externalChartName: string = 'External Chart';
    iframeLink: string = '';
    warningMsg: string = '';
    formattedIframeLink: SafeResourceUrl;
    displayWidget: boolean;
    disableSave: boolean = true;
    showLoader: boolean = false;
    constructor(
      private alertService: AlertService,
      private sanitizer: DomSanitizer,
      public widgetService: WidgetService,
      public dialogRef: MatDialogRef<EmbedExternalLayoutComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,) { }
  
    ngOnInit() {
      if(this.data.isEdit) {
        this.externalChartName = this.data.widgetData.name;
        this.iframeLink = '<iframe src="'+this.data.widgetData.widgetUrl+'"></iframe>';
      }
    }  

    //check for iframe link and generate preview if link is valid:
    generatePreview () {
      this.disableSave = true;
      this.formattedIframeLink = '';
      this.displayWidget = false;
      this.showLoader = true;
      setTimeout(() => {
      if (this.iframeLink.startsWith('<iframe') && this.iframeLink.includes("src=")) {
        let iframeSrc = this.iframeLink?.split('src=')[1].split(/[ >]/)[0];
        if (this.checkIfUrlIsValid(iframeSrc?.replace(/("|')/g, ""))) {
          this.warningMsg = '';
          this.displayWidget = true;
          this.disableSave = false;
          iframeSrc = this.replaceWidthHeight(iframeSrc?.replace(/("|')/g, ""));
          this.formattedIframeLink = this.sanitizer.bypassSecurityTrustResourceUrl(iframeSrc);
        } else {
          this.warningMsg = 'The link appears to be invalid.';
        }
      } else {
        this.warningMsg = 'The link appears to be invalid.';
      }
      this.showLoader = false;
    }, 5000);
    }

    checkIfUrlIsValid(str: string) {
      var pattern = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w.-]*/?');
      return !!pattern.test(str);
    }

    //Temporary method to set height and width of iframe. This needs to be remodified once d=widgets are added in dashboard.
    replaceWidthHeight(url: string) {
      const urlObj = new URL(url);
      
      urlObj.searchParams.set('width', 'auto');
      urlObj.searchParams.set('height', 'auto');
      
      return urlObj.toString();
    }

    onSaveEmbedLink() {
      let iframeSrc = this.replaceWidthHeight(this.iframeLink?.split('src=')[1].split(/[ >]/)[0]?.replace(/("|')/g, ""));
      let externalWidgetData = {
        "id": this.data.isEdit ? this.data.widgetData.widgetId : "",
        "name": this.externalChartName,
        "dashboardId": this.data.selectedDashboard.dashboardId,
        "externalWidgetUrl": iframeSrc?.replace(/("|')/g, ""),
      }
      if (this.data.selectedDashboard.dashboardId) {
        this.widgetService.addExternalWidget(externalWidgetData).subscribe((response: any) => {
          this.dialogRef.close({"confirm": true, "externalWidgetData": externalWidgetData, "widgetData": response});
          this.alertService.success('External link has been created successfully');
        }, (error) => {
          this.alertService.error(error?.error?.error || 'Something went wrong, please try again');
        });
      }
    }

  }