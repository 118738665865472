<div class="scope-selection-modal">
    <div class="row scope-selection-modal-inner-grid">
        <div class="scope-selection-sidebar">
            <ng-container *ngIf="isLoading">
                <div class="loader-column" *ngFor="let repeat of [0]">
                    <ngx-skeleton-loader [theme]="{ 'border-radius': '0', 'height': '200px', 'background-color': '#eff0f1'}" count="1"></ngx-skeleton-loader>
                </div>
            </ng-container>

            <div class="scope-side-bar-configuration" *ngIf="!isLoading">
                <div class="title-container">
                    <p class="lbl">
                        <span class="label">Select Scope: </span> 
                        {{data.componentName}}
                    </p>
                </div>

                <div class="scope-toggle-container d-flex align-items-center" id="display-toggle-time" *ngIf="componentType === 'chart'">
                    <mat-slide-toggle class="display-toggle" name="dashboardScope" [(ngModel)]="scopeSameAsDashboard"
                        (change)="toggleDashboardScope($event)" (click)="$event.stopPropagation()"
                        id="dashboard-scope-toggle"></mat-slide-toggle>
                    <div class="label" id="flatten-hierarchy-text">Same as Dashboard</div>
                </div>

                <div class="scope-toggle-message" *ngIf="componentType === 'chart' && scopeSameAsDashboard">
                    This chart is currently using the scope selected on the Dashboard. To customize the scope, turn off the toggle
                </div>
                
                <div class="scope-select-container" id="scope-select-section" *ngIf="!scopeSameAsDashboard">
                    <label class="label">Select Scope</label>
                    <multi-selection-dropdown [nodes]="scopeDropdownList" [inputPlaceholder]="'Search for Site'" 
                        [selectPanelPlaceholder]= "'Select Scope'" [preselectedSiteIds]="preselectedSiteIds"
                        (selectedchild)="handleSelectedScope($event)">
                    </multi-selection-dropdown>
                </div>

                <div class="scope-action-button-container">
                    <div class="inner-grid">
                        <button id="cancelBtnScope" class="text-uppercase btn btn-text color-primary" (click)="closeModal()">Cancel</button>
                            <span class="seperator">|</span>
                        <button id="saveBtnScope" class="text-uppercase btn btn-text color-primary" [ngClass]="{'save-disable': isHierarchyLoading}" (click)="componentType == 'chart' ? saveWidgetConfig() : saveDashboardConfig()" [disabled]="isHierarchyLoading">Save</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="scope-selection-content">
            <div class="scoll-container">
                <div class="scope-selection-header">
                    <div class="buttons-container">
                        <span id="scope-expand" [matTooltip]="expandCollapeClickCounter >= 4 ? '' : 'Expand'" matTooltipPosition="below" class="scope-list-action-button" (click)="expandScopeList()" [ngClass]="{'disabled-expand-collapse-button': expandCollapeClickCounter >= 4 && getOpenedAccordionLength() == 4  }">
                            <i class="fa fa-plus primary-color" aria-hidden="true"></i></span>
                        <span id="scope-collapse" [matTooltip]="expandCollapeClickCounter == 0 ? '' : 'Collapse'" matTooltipPosition="below" class="scope-list-action-button m-l-5" (click)="collapseScopeList()" [ngClass]="{'disabled-expand-collapse-button': expandCollapeClickCounter == 0}">
                            <i class="fa fa-minus primary-color" aria-hidden="true"></i></span>
                    </div>
                    <div class="scope-search-container">
                        <mat-form-field (click)="$event.stopPropagation()" class="search-input" [floatLabel]="'never'">
                            <div class="search-icon-container" matPrefix>
                                <i class="fas fa-search searchIcon"  aria-hidden="true" id="search-icon"></i>
                            </div>
                            <input matInput id="scope-search" [(ngModel)]='searchText' 
                                autocomplete="off" trim="blur" matInput placeholder="Search for CCU, Floor, Zone, Zone Equip and Device" #searchTextFieldForPreDef
                                (keydown.Space)="$event.stopPropagation()" (keydown.Enter)="$event.stopPropagation()" class="scope-search-input"/>
                            <button mat-button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" class="close-button" (click)="clearSearch()">
                                <i class="fa fa-times closeIcon" aria-hidden="true"></i>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="scope-list-container">
                    <ng-container *ngIf="filteredHierarchyList && !isHierarchyLoading">
                        <ng-container *ngFor="let site of filteredHierarchyList.sites">
                            <div class="building-container">
                                <div class="building-label-container">
                                    <label class="lbl">{{site.name}}</label>
                                </div>
                                <div class="building-heirarchy-wrapper">
                                    <!-- ccu list -->
                                    <div>
                                        <ng-container *ngFor="let ccu of site.ccus;let i = index">
                                            <div *ngIf="!ccu.siteManager" [ngClass]="{'ccu-bottom-section': site.ccus.length-2 == i && site.ccus[i+1]?.siteManager}">
                                                <app-hierarchy-accordion [entityName]="ccu.name" [entity]="hierarchyEntityTypes.CCU" [isEnabled]="ccu.enabled"
                                                    [isIndeterminate]="ccu.indeterminate" [isOpen]="accordionStates['ccu']" [isDisabled]="scopeSameAsDashboard" [hasChildren]="ccu.floors && ccu.floors.length >0"
                                                    (selectionChange)="onHierarchySelectionChange($event, ccu, 'floors')" (accordionChange)="onAccordionChange($event)">
                                                    <!-- floor list -->
                                                    <div *ngFor="let floor of ccu.floors" class="m-l-22">
                                                        <app-hierarchy-accordion [entityName]="floor.name" [entity]="hierarchyEntityTypes.FLOOR"
                                                            [isEnabled]="floor.enabled" [isIndeterminate]="floor.indeterminate"
                                                            [isDisabled]="scopeSameAsDashboard" [isOpen]="accordionStates['floor']" [hasChildren]="floor.rooms && floor.rooms.length >0"
                                                            (selectionChange)="onHierarchySelectionChange($event, floor, 'rooms')"
                                                            (accordionChange)="onAccordionChange($event)">
                                                            <!-- Room list -->
                                                            <div *ngFor="let room of floor.rooms" class="m-l-24">
                                                                <app-hierarchy-accordion [entityName]="room.name" [entity]="hierarchyEntityTypes.ZONE"
                                                                    [isEnabled]="room.enabled" [isIndeterminate]="room.indeterminate"
                                                                    [isDisabled]="scopeSameAsDashboard" [hasChildren]="room.equips && room.equips.length >0"
                                                                    [isOpen]="accordionStates['room']" (selectionChange)="onHierarchySelectionChange($event, room, 'equips')"
                                                                    (accordionChange)="onAccordionChange($event)">
                                                                    <!-- Room Equip list -->
                                                                    <div *ngFor="let equip of room.equips" class="m-l-24">
                                                                        <app-hierarchy-accordion [entityName]="equip.name"
                                                                            [isDisabled]="scopeSameAsDashboard" [isEnabled]="equip.enabled"
                                                                            [isIndeterminate]="equip.indeterminate"
                                                                            [entity]="hierarchyEntityTypes.EQUIP" [isOpen]="accordionStates['equip']" [hasChildren]="equip.devices && equip.devices.length >0"
                                                                            (selectionChange)="onHierarchySelectionChange($event, equip, 'devices')"
                                                                            (accordionChange)="onAccordionChange($event)">
                                                                            <!-- Device list -->
                                                                            <div *ngFor="let device of equip.devices" class="m-l-36">
                                                                                <app-hierarchy-accordion [entityName]="device.name" [isEnabled]="device.enabled"
                                                                                    [isIndeterminate]="device.indeterminate" [isDisabled]="scopeSameAsDashboard" [entity]="hierarchyEntityTypes.DEVICE"
                                                                                    (selectionChange)="onHierarchySelectionChange($event, device, '')">
                                                                                </app-hierarchy-accordion>
                                                                            </div>
                                                                        </app-hierarchy-accordion>
                                                                    </div>
                                                                </app-hierarchy-accordion>
                                                            </div>
                                                        </app-hierarchy-accordion>
                                                    </div>
                                                </app-hierarchy-accordion>
                                            </div>

                                            <!-- SM Equips-->
                                            <ng-container *ngIf="ccu.siteManager">
                                                <div class="sm-equips-section">
                                                    <div class="sm-label-container">
                                                        <label class="sm-lbl">SM Floors</label>
                                                    </div>
                                                    <!-- floor list -->
                                                    <div *ngFor="let floor of ccu.floors">
                                                        <app-hierarchy-accordion [entityName]="floor.name" [entity]="hierarchyEntityTypes.FLOOR"
                                                            [isDisabled]="scopeSameAsDashboard" [isEnabled]="floor.enabled" [isIndeterminate]="floor.indeterminate"
                                                            [isOpen]="accordionStates['floor']" [hasChildren]="floor.rooms && floor.rooms.length >0"
                                                            (selectionChange)="onHierarchySelectionChange($event, floor, 'rooms')" (accordionChange)="onAccordionChange($event)">
                                                            <!-- Room list -->
                                                            <div *ngFor="let room of floor.rooms" class="m-l-24">
                                                                <app-hierarchy-accordion [entityName]="room.name" [entity]="hierarchyEntityTypes.SMZONE"
                                                                    [isDisabled]="scopeSameAsDashboard" [isEnabled]="room.enabled"
                                                                    [isIndeterminate]="room.indeterminate" [hasChildren]="room.equips && room.equips.length >0"
                                                                    [isOpen]="accordionStates['room']" (selectionChange)="onHierarchySelectionChange($event, room, 'equips')"
                                                                    (accordionChange)="onAccordionChange($event)">
                                                                    <!-- Room Equip list -->
                                                                    <div *ngFor="let equip of room.equips" class="m-l-24">
                                                                        <app-hierarchy-accordion [entityName]="equip.name"
                                                                            [isDisabled]="scopeSameAsDashboard" [isEnabled]="equip.enabled"
                                                                            [isIndeterminate]="equip.indeterminate" [hasChildren]="equip.devices && equip.devices.length >0"
                                                                            [entity]="hierarchyEntityTypes.SMEQUIP" [isOpen]="accordionStates['equip']"
                                                                            (selectionChange)="onHierarchySelectionChange($event, equip, 'devices')"
                                                                            (accordionChange)="onAccordionChange($event)">
                                                                            <!-- Device list -->
                                                                            <div *ngFor="let device of equip.devices" class="m-l-36">
                                                                                <app-hierarchy-accordion [entityName]="device.name" [isEnabled]="device.enabled"
                                                                                    [isIndeterminate]="device.indeterminate"
                                                                                    [isDisabled]="scopeSameAsDashboard"
                                                                                    [entity]="hierarchyEntityTypes.SMDEVICE"
                                                                                    (selectionChange)="onHierarchySelectionChange($event, device, '')">
                                                                                </app-hierarchy-accordion>
                                                                            </div>
                                                                        </app-hierarchy-accordion>
                                                                    </div>
                                                                </app-hierarchy-accordion>
                                                            </div>
                                                        </app-hierarchy-accordion>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
 
                                    <ng-container *ngIf="checkIfHierarchyIsEmpty(site) && searchText && !isHierarchyLoading">
                                        <div class="no-result-msg">No Result Found</div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="isHierarchyLoading">
                        <div class="hierarchy-loader-container">
                          <ngx-skeleton-loader 
                            *ngFor="let loader of [1, 2, 3]"
                            [theme]="{ 'border-radius': '0', 'height': '100%', 'width':'100%', 'background-color': '#eff0f1' }">
                          </ngx-skeleton-loader>
                        </div>
                      </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>