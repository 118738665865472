/**
 * Portal Selector
 */
export const PORTALS = {
  INTERNAL: 'internal',
  FACILISIGHT: 'facilisight'
};

/**
 * The maximum number of sites that can be selected by use in scope selection.
 */
export const siteSelectionConfig = {
  isRestrictionEnabled: true,
  maxSelectableSites: 30
};


/**
* API static Urls part. Gets appended to the URL obtained from Config service, and further path params would be added to these.
*/
export const URLS = {
GET_SITES_USER: 'user'
}

/**
* Certification levels
*/
export const CERTIFICATION_LEVEL = {
L0: 'L0',
L1: 'L1',
L2: 'L2',
L3: 'L3',
}

export const restrictedCertificationLevels: string[] = ['L0', 'L1'];
export const CRUDOperationList = {
  new: 'NEW',
  duplicate: 'DUPLICATE',
  preview: 'PREVIEW',
  edit: 'EDIT',  
};

export const LimitReached = "You've reached the limit of 15 dashboards. Remove a dashboard to add a new one."
export const chartLimitReached = "You've reached the limit of 15 charts. Remove a chart to add a new one."

export const HIERARCHY_ENTITY_TYPES = {
  CCU: 'CCU',
  FLOOR: 'Floor',
  ZONE: 'Zone',
  EQUIP: 'Zone Equip',
  DEVICE: 'Device',
  SMZONE: 'SM Zone',
  SMEQUIP: 'SM Zone Equip',
  SMDEVICE: 'SM Device'
}

export const SCOPE_SELECTION = {
  allSites: 'allSites',
  selectedSites: 'selectedSites'
}