export enum chartType {
  LINE = 'LINE',
  DOTTED = 'DOTTED_LINE',
  AREA = 'AREA',
  BAR = 'BAR',
  PIE = 'PIE',
  DONUT = 'DONUT',
  SUNBURST = 'SUNBURST',
  BOX_PLOT = 'BOX_PLOT'
}

export enum chartname {
  LINE = 'line',
  DOTTED_LINE = 'line',
  AREA = 'line',
  BAR = 'bar',
  PIE = 'pie',
  DONUT = 'donut',
  SUNBURST = 'sunburst',
  BOX_PLOT = 'boxplot'
}

export enum viewByEnum {
  'Site'= 'SITE',
  'System'= 'CCU', 
  'Floor'= 'FLOOR', 
  'Zone'= 'ZONE',
  'Zone Equip'= 'EQUIP',
  'ZONE EQUIP'= 'EQUIP',
  'Device'= 'DEVICE'
}

export enum viewByEnumLabel {
  'SITE' = 'Site',
  'CCU' = 'System', 
  'FLOOR' = 'Floor', 
  'ZONE' = 'Zone',
  'EQUIP' = 'Zone Equip',
  'DEVICE' = 'Device'
}


export enum viewByEnumConvert {
  'SITE' = 'SITE',
  'SYSTEM' = 'CCU', 
  'FLOOR' = 'FLOOR', 
  'ZONE' = 'ZONE',
  'ZONE EQUIP' = 'EQUIP',
  'DEVICE' = 'DEVICE'
}

