import { AxisType, TooltipFormat } from "../models/widget.model";
import { ChartType, Option, } from "../models/widget.model";

export enum axisOrientation {
    left = 'LEFT',
    right = 'RIGHT',
    top = 'TOP',
    bottom = 'BOTTOM'
};

export enum chartType {
    LINE = 'Line Chart',
    DOTTED_LINE = 'Dotted Line Chart',
    AREA = 'Area Chart',
    BAR = 'Bar Chart',
    PIE = 'Pie Chart',
    DONUT ='Donut Chart',
    SUNBURST = 'Sunburst Chart',
    TERRAIN = 'Terrain Chart',
    BOX_PLOT = 'Box Plot'
}

export enum chartEnum {
    'Line Chart' = 'LINE',
    'Dotted Line Chart' = 'DOTTED_LINE',
    'Area Chart' = 'AREA',
    'Bar Chart' = 'BAR',
    'Pie Chart' = 'PIE',
    'Donut Chart' = 'DONUT',
    'Sunburst Chart' = 'SUNBURST',
    'Terrain Chart' = 'TERRAIN',
    'Box Plot' = 'BOX_PLOT'
}

export const TimeSeriesChartTypes = ['LINE', 'DOTTED_LINE', 'AREA', 'BAR', 'TERRAIN', 'BOX_PLOT'];

export const fixedFormatValue:TooltipFormat = { 
    name: '$parameter',
    value: '$parameter', 
    disabled: true 
};

export const axisValues: AxisType [] = [
    { name: 'Date & Time', value: 'time' },
    { name: 'Value', value: 'value' }
];

export const sortingValues:AxisType[] =  [
    { name: 'Sort by View', value: 'sortview' },
    { name: 'Sort by Parameter', value: 'sortparameter' }
];

export const formatValues:TooltipFormat[] =  [
    { name: '$site', value: '$site', disabled: false },
    { name: '$ccu', value: '$ccu', disabled: false },
    { name: '$floor', value: '$floor', disabled: false },
    { name: '$zone', value: '$zone', disabled: false },
    { name: '$zoneEquip', value: '$zoneEquip', disabled: false },
    { name: '$device', value: '$device', disabled: false },
    { name: '$parameter', value: '$parameter', disabled: true }
];

export const chartTypes: ChartType[] = [
    { name: 'Line Chart', image: 'line-chart.svg', value: 'LINE' },
    { name: 'Dotted Line Chart', image: 'dotted-line-chart.svg', value: 'DOTTED_LINE' },
    { name: 'Area Chart', image: 'area-chart.svg', value: 'AREA' },
    { name: 'Bar Chart', image: 'bar-chart.svg', value: 'BAR' },
    { name: 'Pie Chart', image: 'pie-chart.svg', value: 'PIE' },
    { name: 'Donut Chart', image: 'donut-chart.svg', value: 'DONUT' },
    // { name: 'Sunburst Chart', image: 'sunburst-chart.svg', value: 'SUNBURST' },
    // { name: 'Terrain Chart', image: 'terrain-chart.svg', value: 'TERRAIN' },
    // { name: 'Box Plot', image: 'box-plot.svg', value: 'BOX_PLOT' }
];

export const chartViewTypes: Option[] = [
    { name: 'Site', value: 'SITE' },
    { name: 'System', value: 'SYSTEM' },
    { name: 'Floor', value: 'FLOOR' },
    { name: 'Zone', value: 'ZONE' },
    { name: 'Zone Equip', value: 'ZONE EQUIP' },
    { name: 'Device', value: 'DEVICE' }
];

export const chartAggregationTypes: Option[] = [
    { name: 'Avg', value: 'AVG' },
    { name: 'Sum', value: 'SUM' },
    { name: 'Min', value: 'MIN' },
    { name: 'Max', value: 'MAX' },
    { name: 'Count', value: 'COUNT' }
];

export const chartYAxisTypes: Option[] = [
    { name: 'Left Y Axis', value: 'LEFT' },
    { name: 'Right Y Axis', value: 'RIGHT' }
];

export const chartXAxisTypes: Option[] = [
    { name: 'Top X Axis', value: 'TOP' },
    { name: 'Bottom X Axis', value: 'BOTTOM' }
];

export const pieDonutSunBurstCharts = ['PIE', 'DONUT', 'SUNBURST'];


export const chartBuilderChartConfig = {
    'LINE': ['showAxisPosition', 'showAxisScale', 'showAxisLabel', 'showToolTipConfig', 'showToolTipSorting', 'showToolTipFormat', 'showRightLeftAxisSelection', 'showParameterColorSelection'],
    'DOTTED_LINE': ['showAxisPosition', 'showAxisScale', 'showAxisLabel', 'showToolTipConfig', 'showToolTipSorting', 'showToolTipFormat', 'showRightLeftAxisSelection', 'showParameterColorSelection'],
    'AREA': ['showAxisPosition', 'showAxisScale', 'showAxisLabel', 'showToolTipConfig', 'showToolTipSorting', 'showToolTipFormat', 'showRightLeftAxisSelection', 'showParameterColorSelection'],
    'BAR': ['showAxisPosition', 'showAxisScale', 'showAxisLabel', 'showToolTipConfig', 'showToolTipSorting', 'showToolTipFormat', 'showRightLeftAxisSelection', 'showParameterColorSelection'],
    'PIE': ['showChartLabel', 'showPieToolTipLabel', 'showGlobalColorGradient','hideGroupBy'],
    'DONUT': ['showChartLabel', 'showPieToolTipLabel', 'showGlobalColorGradient','hideGroupBy'],
    'SUNBURST': ['showChartLabel', 'showGlobalColorGradient', 'hideGroupBy', 'showRadioBtnParameter'],
    'TERRAIN': ['showAxisPosition', 'showAxisScale', 'showAxisLabel', 'showToolTipConfig', 'showToolTipFormat', 'showForTerrain', 'showRadioBtnParameter'],
    'BOX_PLOT': ['showAxisPosition', 'showAxisLabel', 'showToolTipConfig', 'showToolTipFormat', 'showRightLeftAxisSelection', 'showParameterColorSelection'],
    'GAUGE' : ['hideDataRange', 'hideGroupBy', 'hideViewBy'],
}

export const CHART_PLOT_OPTIONS = {
    COMBINE: 'COMBINE',
    SEPARATE: 'SEPARATE'
};
