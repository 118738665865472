import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { DashboardService } from 'src/app/shared/_services/dashboard.service';
import { CRUDOperationList } from 'src/app/shared/constants/constants';
import { HostRoute } from 'src/app/shared/enums/host.enum';
import { DashboardCreationData } from 'src/app/shared/models/dashboard';
import { ObjectUtil } from 'src/app/shared/utils/object-util';
export enum configurationLevels {
  personal = 'personal',
  shared = 'shared',
  none = 'NONE'
}

@Component({
  selector: 'app-create-dashboard-layout',
  templateUrl: './create-dashboard-layout.component.html',
  styleUrls: ['./create-dashboard-layout.component.scss']
})

export class CreateDashboardLayoutComponent implements OnInit {


  // Property to display selected widget
  widgetType: string = 'personal';

  isInternalPortal = false;
  selectedSharedConfiguration = configurationLevels.personal;
  @ViewChild(MatMenuTrigger) sharedMenuTrigger: MatMenuTrigger;

  // Property to store list of widgets
  configurationOptions: any = [
    { label: 'Personal', value: 'personal' },
    { label: 'Shared', value: 'shared' }
  ];

  whatDescription: string = "";
  whyDescription: string = "";
  dashboardName: string = "";
  isEdit: boolean = false;
  isDuplicate: boolean = false;
  title: string = '';
  actionLabel: string = '';

  constructor(
    public dashboardService: DashboardService,
    public dialogRef: MatDialogRef<CreateDashboardLayoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    const url = new URL(window.location.href);

    this.isEdit = this.data.crudOperation === CRUDOperationList.edit;
    this.isDuplicate = this.data.crudOperation === CRUDOperationList.duplicate;
    this.title = this.isEdit ? 'Edit Dashboard Details' : this.isDuplicate ? 'Duplicate Dashboard' : 'Create New Dashboard';
    this.actionLabel = this.isEdit ? 'edit your' : this.isDuplicate ? 'duplicate' : 'add charts to';
    this.checkPortalRoute(url);

    this.dashboardName = this.data?.dashboardData.name ?? '';
    this.whatDescription = this.data?.dashboardData.what ?? '';
    this.whyDescription = this.data?.dashboardData.why ?? '';

    if (this.isDuplicate && this.data.isDuplicate) {
      this.dashboardName += ' - Copy';
      this.widgetType = configurationLevels.personal;
    } else {
      this.widgetType = this.data.dashboardData?.shared ? configurationLevels.shared : configurationLevels.personal;
    }
   
    this.selectedSharedConfiguration = this.updateSelectedConfiguration(this.data.dashboardData.sharedTo);
    this.dashboardService.dashboardData = this.data.dashboardData;

  }

  /**
   * Updates the selected configuration and returns the corresponding configuration level.
   * Configuration levels:
   * - "INTERNAL_AND_FACILISIGHT": Returns `configurationLevels.shared`
   * - "INTERNAL": Returns `configurationLevels.personal`
   * - Any other value or null/undefined: Returns `configurationLevels.none`
   */
  updateSelectedConfiguration(configuration: string) {
    if (configuration) {
      if (configuration == "INTERNAL_AND_FACILISIGHT") {
        return configurationLevels.shared;
      }
      else if (configuration == "INTERNAL") {
        return configurationLevels.personal;
      }
      else {
        return configurationLevels.none;
      }
    } else {
      return configurationLevels.none;
    }
  }

  // Method to handle query params
  checkPortalRoute(url: any) {
    const queryParams = url.searchParams;
    const portal = queryParams.get('portal');

    // Set isInternalPortal based on whether the portal is 'facilisight'
    this.isInternalPortal = portal !== HostRoute.FACILISIGHT;
  }

  // Method to handle widget change and show options for sharing mechanism
  onWidgetChange(option: any) {
    if (option?.value === configurationLevels.shared) {
      if (this.isInternalPortal) {
        // Open the menu
        if (this.selectedSharedConfiguration == configurationLevels.none) this.selectedSharedConfiguration = configurationLevels.personal;
        this.sharedMenuTrigger.openMenu();
      } else {
        if (this.selectedSharedConfiguration == configurationLevels.none) this.selectedSharedConfiguration = configurationLevels.shared;
        this.sharedMenuTrigger.closeMenu();
      }

    } else {

      // Default Value, if selected as 'personal'.
      this.selectedSharedConfiguration = configurationLevels.none;

      // Close the menu
      this.sharedMenuTrigger.closeMenu();
    }

    this.widgetType = option.value;
  }

  // Method to update dashboard details
  updateDashboard() {
    if (this.isEdit && !this.dashboardService.isSaveasnewOrDuplicate) {
      this.saveExistingDashboard(); // Save the existing dashboard 
    } else {
      this.addNewDashboard(); // Add new dashboard / Duplicate the existing dashboard
    }
    this.dialogRef.close("dashboardForm");
  }

  addNewDashboard() {

    this.dashboardService.crudActionLabel = this.data.crudOperation;

    if (this.data.isDuplicate || this.dashboardService.crudActionLabel === CRUDOperationList.duplicate) {
      this.updateExistingDashboard();
      this.dashboardService.dasboardCrudOperation$.next(CRUDOperationList.duplicate);
      this.dashboardService.crudActionLabel = CRUDOperationList.duplicate;
      this.dialogRef.close("dashboardForm");
      return;
    } else if (this.dashboardService.crudActionLabel === CRUDOperationList.new) {
      const data: DashboardCreationData = {
        name: this.dashboardName,
        what: this.whatDescription,
        why: this.whyDescription,
        shared: this.widgetType === configurationLevels.shared,
        sharedTo: this.widgetType === configurationLevels.shared ? this.selectedSharedConfiguration === configurationLevels.personal ? 'INTERNAL' : 'INTERNAL_AND_FACILISIGHT' : configurationLevels.none,
        widgetList: this.dashboardService?.dashboardData?.widgetList ? ObjectUtil.deepClone(this.dashboardService.dashboardData.widgetList) : [],
      };
      this.dashboardService.dashboardData = data;
      this.dashboardService.dasboardCrudOperation$.next(CRUDOperationList.new);
    }
  }

  // Method to update existing dashboard details in edit mode
  saveExistingDashboard() {
    this.updateExistingDashboard();
    this.dashboardService.isDashboardEdited = true;
    this.dashboardService.dasboardCrudOperation$.next(CRUDOperationList.edit);
  }

  // Method to update existing dashboard details from the form to the service file
  updateExistingDashboard() {
    this.dashboardService.dashboardData['name'] = this.dashboardName;
    this.dashboardService.dashboardData['what'] = this.whatDescription;
    this.dashboardService.dashboardData['why'] = this.whyDescription;
    this.dashboardService.dashboardData['shared'] = this.widgetType === configurationLevels.shared
    this.dashboardService.dashboardData['sharedTo'] = this.widgetType === configurationLevels.shared ? this.selectedSharedConfiguration === configurationLevels.personal ? 'INTERNAL' : 'INTERNAL_AND_FACILISIGHT' : configurationLevels.none
  }

  // Method to close the dialog
  cancelDialog() {
    if (this.dashboardService.crudActionLabel != CRUDOperationList.duplicate && this.dashboardService.crudActionLabel != CRUDOperationList.new) {
      this.dashboardService.isSaveasnewOrDuplicate = false;
    }
    this.dialogRef.close();
  }

  // Return true if any of the above fields have been modified, otherwise return false
  get isDataValid() {
    const whatDescription = this.whatDescription?.trim();
    const whyDescription = this.whyDescription?.trim();
    const dashboardName = this.dashboardName?.trim();
    const isNotEmpty = whatDescription && whyDescription && dashboardName && this.widgetType != null;
    return isNotEmpty;
  }

}
