<div class="accordion">
    <div class="accordion__item">
        <div class="accordion__item__title" *ngIf="isDropDown">
            <div class="d-flex justify-content-between">
                <div class="d-flex" (click)="activateDropdown()">
                    <i [ngClass]="(isOpen) ? 'fa fa-angle-down arrow-down' : 'fa fa-angle-right arrow-right'" class="customDropdownIcon" aria-hidden="true"></i>
                    <span class="accStyle">{{blockTitle}}</span>
                </div>
                <!-- <a *ngIf="showButton" class="textstyle" (click)="addFilter()"> Add
                </a> -->
            </div>
        </div>
        <div class="accordion__item__title" [ngClass]="!isOpen ? 'divide' : ''" *ngIf="!isDropDown">
            <ng-container>
                <h3 id="accordian_{{'graphId'}}" class="block-title-wrapper" (click)="activateDropdown()">
                    <div class="block-title-container">
                        <div>{{ blockTitle }}</div>
                    </div>
                    <div>
                        <i [ngClass]="(isOpen) ? 'fa fa-angle-down arrow-down' : 'fa fa-angle-right arrow-right'"
                            aria-hidden="true"></i>
                    </div>
                </h3>
            </ng-container>
        </div>
        <div *ngIf="isOpen" [ngClass]="(isOpen) ? 'accordion__item__body--open' : 'accordion__item__body'">
            <ng-content></ng-content>
        </div>
    </div>
</div>