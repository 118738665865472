import { Component, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
 selector: 'app-confirmation-popup',
 templateUrl: './confirmation-popup.component.html',
 styleUrls: ['./confirmation-popup.component.scss'],
 encapsulation: ViewEncapsulation.None
})

export class ConfirmationPopupComponent {
constructor(
    public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
     ) { }


}