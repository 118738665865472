<div class="export-layout-panel">
    <div class="export-layout-header" id="export-header">
        <div class="export-layout-title">Export</div>
    </div>
    <div class="export-layout-content p-t-15" id="export-layout-content">
        <div class="row">
            <div class="col-md-8 charts-selection" *ngIf="!isIndividualExport">
                <ng-container *ngIf="chartList?.length">
                    <div class="chart-list" (click)="$event.stopPropagation();"
                        *ngFor="let chart of chartList; let i=index">
                        <ng-container>
                            <div class="charts-name-container">
                                <mat-checkbox [disableRipple]="true" (change)="onChartSelectionChange(chart, $event)"
                                    class="dashboard-filter-checkbox" [checked]="chart.checked"
                                    color="primary"></mat-checkbox>
                                <div class="charts-lable-name" id="charts-label-{{chart?.name}}"   matTooltip="" showTooltipOnOverflow
                                [matTooltipClass]="'exportConfig-parameter-name-tooltip'" [matTooltipPosition]="'above'">
                                    {{chart?.name}}
                                </div>
                            </div>

                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <div class="col-md-4 export-type-selection" [ngClass]="{'border-left': !isIndividualExport}">
                <div class="export-type-header" [ngClass]="{'p-l-24 ': !isIndividualExport}">
                    Select File Type
                </div>
                <div class="export-configurations">
                    <mat-radio-group id="export-sharing-options" class="p-t-8 row export-configuration-radio-group"
                        [ngClass]="{'p-l-24 ': !isIndividualExport}" [(ngModel)]="selectedExportConfiguration">
                        <div class="w-100 p-b-5">
                            <mat-radio-button [disableRipple]="true" (click)="$event.stopPropagation()" value="png">PNG
                            </mat-radio-button>
                        </div>
                        <div class="w-100 p-t-5">
                            <mat-radio-button [disableRipple]="true" (click)="$event.stopPropagation()"
                                value="xlsx">XLSX</mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="export-action-footer p-t-8">

    <button #cancel id="cancel-button"
        class="btn-cancel btn-enabled btnfooter text-uppercase btn btn-outline-primary color-primary"
        (click)="cancelDialog()">Cancel</button>

    <button #download id="download-button"
        [ngClass]="{'btn-enabled': isDownloadEnabled, 'btn-disabled': !isDownloadEnabled}"
        [disabled]="!isDownloadEnabled"
        class="btn-download btnfooter text-uppercase btn btn-outline-primary color-primary"
        (click)="downloadDashboard()">Download</button>
</div>