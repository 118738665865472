import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
import { ExportAsConfig, ExportAsService } from '../../../app/shared/_services/export.service';

@Component({
  selector: 'app-dashboard-export-layout',
  templateUrl: './dashboard-export-layout.component.html',
  styleUrls: ['./dashboard-export-layout.component.scss']
})
export class DashboardExportLayoutComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DashboardExportLayoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private exportAsService: ExportAsService) { }

  isIndividualExport: boolean = true;
  selectedExportConfiguration: any = 'png';
  chartList: any = [];

  config: ExportAsConfig = {
    type: 'png',
    dashboardName: '',
    elements: [],
    options: {
    }
};

  ngOnInit(): void {
    this.isIndividualExport = this.data.isIndividual;
    this.chartList = cloneDeep(this.data.charts);
  }
  
  // Method to download the dashboard / Widgets
  async downloadDashboard() {
    if (this.selectedExportConfiguration == 'png') {
      const charts: { id: string; xAxis: number; yAxis: number, chartName: string }[] = this.chartList
        ?.filter((_filterChartItem: any) => _filterChartItem && _filterChartItem['checked'])
        .map((_chart: any) => {
          return {
            id: _chart['id'],
            xAxis: _chart['xaxis'],
            yAxis: _chart['yaxis'],
            chartName: _chart['name']
          };
        });

      this.config.type = this.selectedExportConfiguration;
      this.config.elements = charts;
      this.config.dashboardName = this.data.dashBoardInfo.name;
      const fileName = this.getFileName();
      this.exportAsService.save(this.config, fileName).subscribe(() => {
        this.dialogRef.close();
      }, () => {
      });
    } else {
      const imagePath = '/assets/images/excel-exportlogo.svg';
      const imageBase64 = await this.transformImageToBase64(imagePath);
      const fileName = this.getFileName();
      //If individual export is true then export only the selected chart
      if (this.isIndividualExport) {
        this.exportAsService.exportExcelForWidgetLevel(imageBase64, this.data?.widgetLayoutComponent, fileName);
      } else {
        // Fetch the list of checked chart IDs
        const selectedChartIds: string[] = this.chartList
          ?.filter((_filterChartItem: any) => _filterChartItem && _filterChartItem?.checked)
          .map((_chart: any) => _chart.id);
        
        // Filter widgetLayoutComponents based on selectedChartIds
        const widgetLayoutComponents = this.data?.widgetLayoutComponents?.filter(
          (_filterWidgetLayoutComponent: any) =>
            selectedChartIds.includes(_filterWidgetLayoutComponent.widgetObj?.id)
        );
        this.exportAsService.exportExcelForDashboardLevel(imageBase64, widgetLayoutComponents, fileName);
      }
      this.dialogRef.close();
    }
  }

  /**
   * Transforms an image from a given path to a Base64 encoded string.
   * @param {string} imagePath - The path to the image that needs to be transformed.
   * @returns {Promise<string>} A promise that resolves to a Base64 encoded string of the image.
   * @throws Will throw an error if the transformation fails.
   */
  async transformImageToBase64(imagePath: string): Promise<string> {
    try {
      const svgData = await this.exportAsService.getImgUrl(imagePath, { responseType: 'text' }).toPromise();
      return "data:image/svg+xml;base64," + btoa(svgData);
    } catch (error) {
      console.error('Error transforming image to Base64:', error);
      throw error;
    }
  }
  
  // Method to get the file name
  getFileName() {
    let fileName;
    if (!this.isIndividualExport) {
      fileName = `${this.data.dashBoardInfo.name.replace(/[\[\]\:*?/\\]/g, '')}`;
    } else {
      fileName = `${this.chartList[0].name.replace(/[\[\]\:*?/\\]/g, '')}`;
    }
    return fileName;
  }


  // Method to close the dialog
  cancelDialog() {
    this.dialogRef.close();
  }

  // Method to export the selected charts
  onChartSelectionChange(chart: any, event: any) {
    chart.checked = event.checked;
  }

  // Method to download the selected charts
  get isDownloadEnabled() {
    return this.isIndividualExport ? true : this.chartList?.filter((chart: any) => chart.checked).length > 0;
  }

}
