import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  url: string;
  chartsList: any;
  public chartAddedorRemoved = new Subject<any>();
  chartAddedOrRemoved$ = this.chartAddedorRemoved.asObservable();

  constructor(public http: HttpClient, public configService: ConfigurationService) {
    this.url = this.configService.getConfig('tableMakerUrl');
  }

  addWidget(data: any) {
    return this.http.post(this.url + 'widget/save', data).pipe(catchError(this.handleError));
  }

  getWidgetsList() {
    return this.http.get(this.url + 'widget/list-all-widgets').pipe(catchError(this.handleError));
  }

  updateLikeOrBookmark(id:any, request:any) {
    return this.http.post(this.url + '/userTraction/likeOrBookmark/' + id, request, { responseType: 'text' });
  }

  setChartsList(data: any) {
    this.chartsList = data;
  }

  getChartsList() {
    return this.chartsList;
  }

  addExternalWidget(data: any) {
    return this.http.post(this.url + 'externalWidget/save', data).pipe(catchError(this.handleError));
  }

  getWidgetPreview(previewRequest: any) {
    return this.http.post(this.url + 'widget-data/preview', previewRequest).pipe(catchError(this.handleError));
  }

  getWidgetData(reqBody: any) {
    return this.http.post(this.url + 'widget-data/details', reqBody).pipe(catchError(this.handleError));
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}
