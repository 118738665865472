import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WidgetService } from 'src/app/shared/_services/widget.service';
import { Chart } from 'src/app/shared/models/chart';
import { GRAPH_CONFIGURATION } from 'src/app/shared/constants/graph-configuration';
import * as _ from 'lodash-es';
import { chartname, chartType, viewByEnum, viewByEnumConvert } from 'src/app/shared/enums/charts.enum';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DashboardService } from 'src/app/shared/_services/dashboard.service';
import {  timeIntervalsEnum } from 'src/app/shared/enums/dateRange.enum';
import { CRUDOperationList } from 'src/app/shared/constants/constants';
import { ObjectUtil } from '../../shared/utils/object-util';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { axisOrientation } from 'src/app/shared/constants/widget';
import { UserPreference } from 'src/app/shared/models/common.model';
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  selector: 'app-widget-layout',
  templateUrl: './widget-layout.component.html',
  styleUrls: ['./widget-layout.component.scss']
})
export class WidgetLayoutComponent implements OnChanges, OnInit {
  @Input() widgetObj: any;
  private chartDataTransformWorker: Worker;
  widgetData: any;
  transformedChartData: any;
  isLoaded: boolean = false;
  showWarningMessage: boolean = false;
  warningMessage:string = "No data exists for this time period";
  crudAction:string;
  useDashBoardConfig:boolean = false;
  lineChartGroup = ['LINE', 'DOTTED_LINE', 'AREA', 'BAR'];
  chartAxisPositions:any = [];
  userPreference: UserPreference;

  constructor(
    public widgetService: WidgetService,
    private sanitizer: DomSanitizer,
    public dashboardService: DashboardService
  ) {
    if (typeof Worker !== 'undefined') {
      this.chartDataTransformWorker = new Worker(new URL('src/app/shared/_workers/chart-data-transform.worker.ts', import.meta.url), {
        type: 'module',
      });
      this.chartDataTransformWorker.onmessage = ({ data }) => {
        this.generateChartData(data);
      };
    }
  }

  ngOnInit(): void {
    const userPreferenceData = localStorage.getItem('user_preference');
    this.userPreference = userPreferenceData ? JSON.parse(userPreferenceData) : null;
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['widgetObj'] && changes['widgetObj'].currentValue) {
      this.crudAction = this.dashboardService.crudActionLabel;
      this.widgetObj = changes['widgetObj'].currentValue;
      this.getConfigAxisPositions();
      this.getChartData();
    }
  }

  getConfigAxisPositions() {
    this.chartAxisPositions = [...new Set(this.widgetObj.pointDefinitions.map((point: any) => point.axisPosition))];
  }

  getChartData() {

    const labels: { [key: string]: string } = timeIntervalsEnum;
    const viewByEnumMapping: { [key: string]: string } = viewByEnum;
    const viewByEnumConvertMapping: { [key: string]: string } = viewByEnumConvert;
    if (this.crudAction === CRUDOperationList.new || this.crudAction === CRUDOperationList.preview || this.crudAction === CRUDOperationList.edit || this.widgetObj?.builderWidgetPreview) {
      let previewRequest:any;
      if ((this.crudAction === CRUDOperationList.new || this.crudAction === CRUDOperationList.preview) && !this.widgetObj?.builderWidgetPreview) {
        this.useDashBoardConfig = true;
        previewRequest = {
          pointDefinitions: this.widgetObj?.pointDefinitions,
          "config": {
            "name": this.widgetObj?.name,
            "scopeSameAsDashboard": this.widgetObj?.scopeSameAsDashboard,
            "siteRefs": this.dashboardService.dashboardConfig.siteRefs,
            "excludedCcuRefs": this.dashboardService.dashboardConfig.excludedCcuRefs,
            "excludedFloorRefs": this.dashboardService.dashboardConfig.excludedFloorRefs,
            "excludedZoneRefs": this.dashboardService.dashboardConfig.excludedZoneRefs,
            "excludedEquipRefs": this.dashboardService.dashboardConfig.excludedEquipRefs,
            "excludedDeviceRefs": this.dashboardService.dashboardConfig.excludedDeviceRefs,
            "viewBy": this.widgetObj?.viewBy ? viewByEnumMapping[this.widgetObj?.viewBy] : viewByEnumMapping['Site'],
            "groupBySameAsDashboard": this.widgetObj?.groupBySameAsDashboard,
            "groupBy": labels[this.dashboardService.dashboardGroupBy],
            "dateRangeSameAsDashboard": this.widgetObj?.dateRangeSameAsDashboard,
            "startDate": this.dashboardService.dashboardDate.startDate,
            "endDate": this.dashboardService.dashboardDate.endDate,
          }
        }
      }

      if (this.crudAction === CRUDOperationList.edit && !this.widgetObj?.builderWidgetPreview) {
        this.useDashBoardConfig = true;
        previewRequest = {
          pointDefinitions: this.widgetObj?.pointDefinitions,
          "config": {
            "name": this.widgetObj?.name,
            "scopeSameAsDashboard": this.widgetObj?.scopeSameAsDashboard,
            "viewBy": viewByEnumMapping[this.widgetObj?.viewBy],
            "groupBySameAsDashboard": this.widgetObj?.groupBySameAsDashboard,
            "dateRangeSameAsDashboard": this.widgetObj?.dateRangeSameAsDashboard,
            "startDate": this.dashboardService.dashboardDate.startDate,
            "endDate": this.dashboardService.dashboardDate.endDate,
          }
        }

        if (previewRequest?.config?.scopeSameAsDashboard) {
          previewRequest.config.siteRefs = this.dashboardService.dashboardConfig.siteRefs;
          previewRequest.config.excludedCcuRefs = this.dashboardService.dashboardConfig.excludedCcuRefs;
          previewRequest.config.excludedFloorRefs = this.dashboardService.dashboardConfig.excludedFloorRefs;
          previewRequest.config.excludedZoneRefs = this.dashboardService.dashboardConfig.excludedZoneRefs;
          previewRequest.config.excludedEquipRefs = this.dashboardService.dashboardConfig.excludedEquipRefs;
          previewRequest.config.excludedDeviceRefs = this.dashboardService.dashboardConfig.excludedDeviceRefs;
        } else {
          previewRequest.config.siteRefs = this.widgetObj.scopeSelectionData.siteRefs;
          previewRequest.config.excludedCcuRefs = this.widgetObj.scopeSelectionData.excludedCcuRefs;
          previewRequest.config.excludedFloorRefs = this.widgetObj.scopeSelectionData.excludedFloorRefs;
          previewRequest.config.excludedZoneRefs = this.widgetObj.scopeSelectionData.excludedZoneRefs;
          previewRequest.config.excludedEquipRefs = this.widgetObj.scopeSelectionData.excludedEquipRefs;
          previewRequest.config.excludedDeviceRefs = this.widgetObj.scopeSelectionData.excludedDeviceRefs;
        }


        if (previewRequest.config.groupBySameAsDashboard) {
          previewRequest.config.groupBy = labels[this.dashboardService.dashboardGroupBy];
        } else {
          previewRequest.config.groupBy = labels[this.widgetObj.groupBy];
        }

        if (previewRequest.config.dateRangeSameAsDashboard) {
          previewRequest.config.startDate = this.dashboardService.dashboardDate.startDate;
          previewRequest.config.endDate = this.dashboardService.dashboardDate.endDate;
        } else {
          previewRequest.config.startDate =  dayjs(this.widgetObj.startDateTime).format('YYYY-MM-DD');
          previewRequest.config.endDate =  dayjs(this.widgetObj.endDateTime).format('YYYY-MM-DD');
        }
      }

      if (this.widgetObj?.builderWidgetPreview) {
        this.useDashBoardConfig = true;
        previewRequest = {
          pointDefinitions: this.widgetObj?.pointDefinitions,
          "config": {
            "name": this.widgetObj?.name,
            "scopeSameAsDashboard": true,
            "siteRefs": this.dashboardService.dashboardConfig.siteRefs,
            "excludedCcuRefs": this.dashboardService.dashboardConfig.excludedCcuRefs,
            "excludedFloorRefs": this.dashboardService.dashboardConfig.excludedFloorRefs,
            "excludedZoneRefs": this.dashboardService.dashboardConfig.excludedZoneRefs,
            "excludedEquipRefs": this.dashboardService.dashboardConfig.excludedEquipRefs,
            "excludedDeviceRefs": this.dashboardService.dashboardConfig.excludedDeviceRefs,
            "viewBy": this.widgetObj?.chartConfig?.viewBy ? viewByEnumConvertMapping[this.widgetObj?.chartConfig?.viewBy] : viewByEnumMapping['Site'],
            "groupBySameAsDashboard": true,
            "groupBy": labels[this.dashboardService.dashboardGroupBy],
            "dateRangeSameAsDashboard": true,
            "startDate": this.dashboardService.dashboardDate.startDate,
            "endDate": this.dashboardService.dashboardDate.endDate,
          }
        }
      }

      // passing chart type in pointdefinitions in preview request
      if (this.widgetObj?.pointDefinitions) {
        this.widgetObj.pointDefinitions.forEach((point: any) => {
          point.chartType = this.widgetObj.chartConfig.chartType;
        });
        previewRequest.pointDefinitions = this.widgetObj.pointDefinitions;
      }

      const { formattedStartDate, formattedEndDate } = this.formatDates(previewRequest.config?.startDate, previewRequest.config?.endDate);
      previewRequest.config.startDate = formattedStartDate;
      previewRequest.config.endDate = formattedEndDate;
      if(this.widgetObj.chartConfig.chartType == chartType.PIE || this.widgetObj.chartConfig.chartType == chartType.DONUT) {
        previewRequest.chartType = this.widgetObj.chartConfig.chartType;
      }
      this.widgetService.getWidgetPreview(previewRequest).subscribe((widgetData: any) => {
        if (widgetData.every((widget: any) => widget.entityData.length === 0)) {
          this.showWarningMessage = true;
          this.isLoaded = true;
        } else {
          this.widgetObj.data = widgetData;
          this.chartDataTransformWorker.postMessage({widgetData: this.widgetObj, userPreference: this.userPreference});
        }
      }, (error) => {
        if (error.status == 422 && error.error?.error.includes('Data point limit exceeded')) {
          this.showWarningMessage = true;
          this.warningMessage = 'API call limit reached for this chart. Please try limiting the chart scope/date range/parameter selection to view data';
          this.isLoaded = true;
        } else {
          this.showWarningMessage = true;
          this.warningMessage = error.error?.error;
          this.isLoaded = true;
        }
      });

    } else {
      let getDataObj:any;
      if (this.widgetObj?.widgetConfig) {
        getDataObj = {
          dashboardId: this.widgetObj?.widgetConfig?.dashboardId,
          widgetId: this.widgetObj?.widgetId,
          startDate: this.widgetObj?.startDateTime,
          endDate: this.widgetObj?.endDateTime,
        }
      }
      const { formattedStartDate, formattedEndDate } = this.formatDates(getDataObj.startDate, getDataObj.endDate);
      getDataObj.startDate = formattedStartDate;
      getDataObj.endDate = formattedEndDate;

      this.widgetService.getWidgetData(getDataObj).subscribe((widgetData: any) => {
        if (widgetData.every((widget: any) => widget.entityData.length === 0)) {
          this.showWarningMessage = true;
          this.warningMessage = 'No data Available';
          this.isLoaded = true;
        } else {
          this.widgetObj.data = widgetData;
          this.chartDataTransformWorker.postMessage({widgetData: this.widgetObj, userPreference: this.userPreference});
        }
      }, (error) => {
        if (error.status == 422 && error.error?.error.includes('Data point limit exceeded')) {
          this.showWarningMessage = true;
          this.warningMessage = 'API call limit reached for this chart. Please try limiting the chart scope/date range/parameter selection to view data';
          this.isLoaded = true;
        } else {
          this.showWarningMessage = true;
          this.warningMessage = error.error?.error;
          this.isLoaded = true;
        }
      });
    }
  }

  /**
   * Generates legend data for a given chart.
   *
   * This method clones the default legend configuration and populates it with
   * the names of points from the chart data that have the `showVisualisation` flag set to true.
   *
   * @param {Chart} chartData - The chart data containing point definitions.
   * @returns {LegendConfig} The legend configuration populated with the relevant point names.
   */
  generatelegendData(chartData: Chart) {
    let legendConfig: { data: string[] } = _.cloneDeep(GRAPH_CONFIGURATION.legendConfig);

    if (chartData.pointDefinitions?.length) {
      chartData.pointDefinitions.forEach((point: any) => {
        if (point.showVisualisation) {
          legendConfig.data.push(point.name);
        }
      });
    }
    return legendConfig;
  }

  /**
   * Generates the configuration for the X-axis of a chart based on the provided chart data.
   *
   * @param {Chart} chartData - The chart data object containing configuration details.
   * @returns {any[]} - The configuration for the X-axis. If the X-axis column is 'time', 
   *                    it returns the predefined date axis configuration. Otherwise, 
   *                    it returns an array of configurations for the X-axis based on 
   *                    the provided chart data.
   */
  generateXAxisData(chartData: Chart) {
    // if x is time
    if (chartData.chartConfig?.axis?.xaxis?.column === 'time') {
      GRAPH_CONFIGURATION.xDate_AxisConfig.data = chartData.dateArr;
      let xConfig = _.cloneDeep(GRAPH_CONFIGURATION.xDate_AxisConfig);
      if (chartData.chartConfig.axis.xaxis['legend label'] != undefined) {
        xConfig['name'] = chartData.chartConfig.axis.xaxis['legend label'];
      }
      
      // if chart type is BOXPLOT, set x axis type to category
      if(chartData.chartConfig.chartType === chartType.BOX_PLOT) {
        xConfig['type'] = 'category';
      }

      return xConfig;
    } else { // if x is value
      let xAxisConfig: any = [];
      let downAxisConfig: any = {
        axisLine: GRAPH_CONFIGURATION.axisLineConfig
      }

      let upAxisConfig: any = {
        axisLine: GRAPH_CONFIGURATION.axisLineConfig
      }

      // min and max for x axis down axis 
      if (chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin != undefined && chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMax != undefined) {
        downAxisConfig['position'] = 'bottom';

        if (chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin != 'AUTO') {
          downAxisConfig['min'] = chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin;
        }

        if (chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMax != 'AUTO') {
          downAxisConfig['max'] = chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMax;
        }

        if (!(this.chartAxisPositions.includes(axisOrientation.top) && this.chartAxisPositions.includes(axisOrientation.bottom))) {
          if (chartData.chartConfig.axis.xaxis['legend label'] != undefined) {
            downAxisConfig['name'] = chartData.chartConfig.axis.xaxis['legend label'];
            downAxisConfig['nameLocation'] = 'middle';
            downAxisConfig['nameGap'] = 30;
            downAxisConfig['nameTextStyle'] = GRAPH_CONFIGURATION.labelNameTextStyle;
          }
        }
        downAxisConfig['axisLabel'] = GRAPH_CONFIGURATION.axisLabelConfig;
        xAxisConfig.push(downAxisConfig);
      }

      // min and max for x axis up axis
      if (chartData?.chartConfig?.axis?.xaxis?.xaxisTopMin != undefined && chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax != undefined) {
        upAxisConfig['position'] = 'top';

        if (chartData?.chartConfig?.axis?.xaxis?.xaxisTopMin != 'AUTO') {
          upAxisConfig['min'] = chartData?.chartConfig?.axis?.xaxis?.xaxisTopMin;
        }

        if (chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax != 'AUTO') {
          upAxisConfig['max'] = chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax;
        }

        if (chartData.chartConfig.axis.xaxis['legend label'] != undefined) {
          upAxisConfig['name'] = chartData.chartConfig.axis.xaxis['legend label'];
          upAxisConfig['nameLocation'] = 'middle';
          upAxisConfig['nameGap'] = 30;
          upAxisConfig['nameTextStyle'] = GRAPH_CONFIGURATION.labelNameTextStyle;
        }
        upAxisConfig['axisLabel'] = GRAPH_CONFIGURATION.axisLabelConfig;
        xAxisConfig.push(upAxisConfig);
      }

      return xAxisConfig;
    }
  }

  /**
   * Generates the Y-axis configuration for a given chart based on its configuration.
   * 
   * @param {Chart} chartData - The chart data object containing configuration details.
   * @returns {Array<Object>} - An array of Y-axis configuration objects.
   * 
   * The function checks if the Y-axis column is set to 'value'. If so, it generates
   * the configuration for the left and right Y-axes based on the provided minimum
   * and maximum values. If the min or max values are set to 'AUTO', they are not included
   * in the configuration. If the Y-axis column is not 'value', it returns a default
   * date axis configuration.
   */
  generateYAxisData(chartData: Chart) {
    // if y is value 
    if (chartData.chartConfig?.axis?.yaxis?.column === 'value') {
      let yAxisConfig: any = [];
      let leftAxisConfig: any = {
        axisLine: GRAPH_CONFIGURATION.axisLineConfig
      }

      let rightAxisConfig: any = {
        axisLine: GRAPH_CONFIGURATION.axisLineConfig
      }

      // min and max for y axis left axis
      if (chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin != undefined && chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMax != undefined) {
        leftAxisConfig['position'] = 'left';
        if (chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin != 'AUTO') {
          leftAxisConfig['min'] = chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin;
        }

        if (chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMax != 'AUTO') {
          leftAxisConfig['max'] = chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMax;
        }

        if (chartData.chartConfig.axis.yaxis['legend label'] != undefined) {
          leftAxisConfig['name'] = chartData.chartConfig.axis.yaxis['legend label'];
          leftAxisConfig['nameLocation'] = 'middle';
          leftAxisConfig['nameGap'] = 40;
          leftAxisConfig['nameTextStyle'] = GRAPH_CONFIGURATION.labelNameTextStyle;
        }
        leftAxisConfig['axisLabel'] = GRAPH_CONFIGURATION.axisLabelConfig;
        yAxisConfig.push(leftAxisConfig);
      }

      // min and max for y axis right axis
      if (chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin != undefined && chartData?.chartConfig?.axis?.yaxis?.yaxisRightMax != undefined) {
        rightAxisConfig['position'] = 'right';

        if (chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin != 'AUTO') {
          rightAxisConfig['min'] = chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin;
        }

        if (chartData?.chartConfig?.axis?.yaxis?.yaxisRightMax != 'AUTO') {
          rightAxisConfig['max'] = chartData?.chartConfig?.axis?.yaxis?.yaxisRightMax;
        }

        if (!(this.chartAxisPositions.includes(axisOrientation.left) && this.chartAxisPositions.includes(axisOrientation.right))) {
          if (chartData.chartConfig.axis.yaxis['legend label'] != undefined) {
            rightAxisConfig['name'] = chartData.chartConfig?.axis?.yaxis['legend label'];
            rightAxisConfig['nameLocation'] = 'middle';
            rightAxisConfig['nameGap'] = 40;
            rightAxisConfig['nameTextStyle'] = GRAPH_CONFIGURATION.labelNameTextStyle;
          }
        }

        rightAxisConfig['axisLabel'] = GRAPH_CONFIGURATION.axisLabelConfig;
        yAxisConfig.push(rightAxisConfig);
      }

      return yAxisConfig;
    } else { // if y is time
      GRAPH_CONFIGURATION.YDate_AxisConfig[0].data = chartData.dateArr;
      if (chartData.chartConfig?.axis?.yaxis['legend label'] != undefined) {
        GRAPH_CONFIGURATION.YDate_AxisConfig[0].name = chartData.chartConfig.axis.yaxis['legend label'];
      }
      // if chart type is BOXPLOT, set y axis type to category
      if(chartData.chartConfig.chartType === chartType.BOX_PLOT) {
        GRAPH_CONFIGURATION.YDate_AxisConfig[0].type = 'category';
      }
      return GRAPH_CONFIGURATION.YDate_AxisConfig;
    }
  }


  /**
   * Generates series data for a chart based on the provided chart data.
   * 
   * @param {Chart} chartData - The chart data containing configuration and data points.
   * @returns {Promise<any>} A promise that resolves to the generated series data.
   * 
   * The function processes the chart data and generates series data for visualization.
   * It iterates through the data points and applies various configurations such as
   * chart type, color, line style, area style, and axis indices based on the chart configuration.
   * 
   * The function supports different chart types including 'DOTTED_LINE' and 'AREA', and
   * adjusts the styles accordingly. It also sets the xAxisIndex and yAxisIndex based on
   * the axis configuration provided in the chart data.
   * 
   * If an error occurs during the processing, the promise is rejected with the error.
   */
  generateSeriesData(chartData: Chart): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let seriesData: any = [];

        if (chartData.data?.length) {
          chartData.data.forEach((pointData: any) => {

            let pointDefinitionObj: any = chartData.pointDefinitions.find((point: any) => point.name === pointData.paramName);
            if (pointDefinitionObj?.showVisualisation && pointData.entityData?.length) {

              pointData.entityData.forEach((hisPointObj: any) => {

                let pointObj: any = {};
                pointObj.name = pointData.paramName;
                pointObj.unit = hisPointObj?.unit?.value;
                pointObj.defaultNameForExports = hisPointObj.defaultNameForExports;
                pointObj.type = chartname[chartData.chartConfig.chartType as keyof typeof chartname];
                pointObj.symbol = 'none';
                pointObj.itemStyle = {
                  color: pointDefinitionObj.color
                };

                // For Dotted Line Chart Type add style for line
                if (chartData.chartConfig.chartType === 'DOTTED_LINE') {
                  pointObj.lineStyle = {
                    type: 'dotted',
                    width: 2,
                  }
                }

                // For Area Chart Type add style for area and remove line
                if (chartData.chartConfig.chartType === 'AREA') {
                  pointObj.areaStyle = {};
                  pointObj.lineStyle = {
                    width: 0
                  }
                }

                // For Boxplot Chart Type add style for border color
                if (chartData.chartConfig.chartType === chartType.BOX_PLOT) {
                  pointObj.itemStyle['borderColor'] = pointDefinitionObj.color;
                  delete pointObj.itemStyle['color']; // Remove color for boxplot.
                }

                pointObj['data'] = hisPointObj.chartTransformedArr;

                // setting the xAxisIndex up or down for each point
                if (chartData?.chartConfig?.axis?.xaxis?.column == "value") {
                  if (chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin != undefined && chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax != undefined) {
                    pointObj['xAxisIndex'] = pointDefinitionObj?.axisPosition == 'BOTTOM' ? 0 : 1;
                  }

                  // if only one x axis is defined
                  if (chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin != undefined && chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax == undefined ||
                    chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin == undefined && chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax != undefined) {
                    pointObj['xAxisIndex'] = 0
                  }
                }

                // setting the yAxisIndex left or right for each point
                if (chartData?.chartConfig?.axis?.yaxis?.column == "value") {
                  if (chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin != undefined && chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin != undefined) {
                    pointObj['yAxisIndex'] = pointDefinitionObj?.axisPosition == 'RIGHT' ? 1 : 0;
                  }

                  // if only one y axis is defined
                  if (chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin != undefined && chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin == undefined ||
                    chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin == undefined && chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin != undefined) {
                    pointObj['yAxisIndex'] = 0
                  }
                }
                seriesData.push(pointObj);
              });
            }
          });
        }
        resolve(seriesData);
      } catch (error) {
        reject(error);
      }
    });

  }

  /**
   * Generates a tooltip configuration for a chart based on the provided chart data.
   * 
   * @param {any} chartData - The data for the chart, which includes the chart configuration.
   * @returns {any} The tooltip configuration object.
   */
  generateTooltip(chartData: any) {
    let graphConfig: any = GRAPH_CONFIGURATION.toolTipTriggerConfig;
    let self = this;
    if (this.lineChartGroup.includes(chartData.chartConfig.chartType)) {
      graphConfig.formatter = function (params: any) {
        let browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; //get browser timezone
        // convert UTC time to browser time 
        let tooltipString: any = `<div class="text-align-left"><strong>${dayjs(params[0].axisValueLabel).tz(browserTimezone).format('@ ddd DD MMM, YYYY | hh:mm A')}</strong><br/>`;

        // sortBy value
        if (chartData.chartConfig.tooltip.sortBy == "sortview") { 
          params = [...params].sort((a, b) => {
            const aValue = a.data[2];
            const bValue = b.data[2];

            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
            return 0;
          });
        } 
        params.forEach(function (item: any) {
          tooltipString += `<div class="toolTipStyle">
            <span style="color: ${item.color}; font-size: 12px;">
            ${item.value[2]}: ${self.trimDecimalTo2Digit(chartData.chartConfig.axis.xaxis.column == 'time' ? item.value[1] : item.value[0])}
            ${item.value[3] ? '': (chartData.chartConfig.axis.xaxis.column == 'time' ? ObjectUtil.isNotUndefinedOrNull(item.value[1]) : ObjectUtil.isNotUndefinedOrNull(item.value[0])) ? item.value[4]: ''}</span>
            ${item.value[3] ? `<i class="fa fa-solid fa-calculator calc-icon-color p-l-5"></i>`: ''}
          </div>`;
        });
        return tooltipString;
      }
    } else if(chartData.chartConfig.chartType === chartType.BOX_PLOT) {
      this.getTooltipDataForBoxPlot(graphConfig);
    }
    return graphConfig;
  }

  getTooltipDataForBoxPlot(graphConfig: any) { 
    const self = this;
    graphConfig.formatter = function (params: any) {
      let browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; //get browser timezone
      // convert UTC time to browser time 
      let tooltipString: any = `<div class="text-align-left"><strong>${dayjs(params[0].axisValueLabel).tz(browserTimezone).format('@ ddd DD MMM, YYYY | hh:mm A')}</strong><br/>`;
      params.forEach(function (item: any) {
        tooltipString += `<div class="toolTipStyle">
        <span style="color: ${item.color}; font-size: 12px;">
          Min: ${self.trimDecimalTo2Digit(item.value[1])}<br/>
          Q1: ${self.trimDecimalTo2Digit(item.value[2])}<br/>
          Median: ${self.trimDecimalTo2Digit(item.value[3])}<br/>
          Q3: ${self.trimDecimalTo2Digit(item.value[4])}<br/>
          Max: ${self.trimDecimalTo2Digit(item.value[5])}
        </span>
        </div>`;
      });
      return tooltipString;
    }
  }

  /**
   * Trims a given number to 2 decimal places if it has more than 2 decimal places.
   *
   * @param value - The number to be trimmed.
   * @returns The number trimmed to 2 decimal places if it had more than 2, otherwise returns the original number.
   */
  trimDecimalTo2Digit(value: number) {
    if (!ObjectUtil.isNotUndefinedOrNull(value)) {
      return '-';
    }
    if (value && value.toString().includes('.') && value.toString().split('.')[1].length > 2) {
      return parseFloat(value.toFixed(2)); // Trim to 2 decimals
    }
    return value;
  }


  /**
   * Generates and transforms chart data based on the provided chart configuration.
   * 
   * @param chartData - The chart data and configuration used to generate the chart.
   * @returns void
   * 
   * @remarks
   * This method sets the `transformedChartData` property with the appropriate configurations
   * for data zoom, tooltip, legend, x-axis, y-axis, and series based on the provided chart data.
   * It also sets the `isLoaded` flag to true once the transformation is complete.
   */
  async generateChartData(chartData: any) {
    let chartConfigObj: any = {};
    if (chartData.chartConfig.chartType == chartType.PIE || chartData.chartConfig.chartType == chartType.DONUT) {
     this.generateChartDataForPieDonutChart(chartData);
    } else {
      chartConfigObj = {
        dataZoom: chartData?.chartConfig?.axis?.xaxis?.column == 'time' ? GRAPH_CONFIGURATION.xDate_ZoomConfig : GRAPH_CONFIGURATION.yDate_ZoomConfig,
        tooltip: this.generateTooltip(chartData),
        grid: chartData?.chartConfig?.axis?.xaxis?.column == 'time' ? GRAPH_CONFIGURATION.xAxisgridConfig : GRAPH_CONFIGURATION.yAxisgridConfig,
        legend: this.generatelegendData(chartData),
        xAxis: this.generateXAxisData(chartData),
        yAxis: this.generateYAxisData(chartData),
      }
    
    //If chartType is COMBINE, generate series data for all sites
    if (chartData.chartConfig.viewType === "COMBINE") {
      this.transformedChartData = [];
      try {
        chartConfigObj.series = await this.generateSeriesData(chartData);
        this.transformedChartData.push(chartConfigObj);
        this.isLoaded = true;
      } catch (error) {
        console.error('Error generating series data for COMBINE view type', error);
      }
    } else { //If chartType is SEPERATE, generate series data for each site
      let listOfSites = chartData.scopeSameAsDashboard || this.useDashBoardConfig ? this.dashboardService.dashboardConfig.siteRefs : chartData.widgetConfig.siteRefs;
      if (listOfSites.length) {
        let chartDataGroupedBySite = listOfSites.map((site: string) => ({
          siteRef: site,
          data: chartData.data.map((chartDataObj: any) => ({
            paramName: chartDataObj.paramName,
            entityData: chartDataObj?.entityData ? chartDataObj.entityData.filter((entity: any) => entity.siteRef === site): []
          })).filter((filterItem: any) => filterItem.entityData.length > 0)
        }));

        this.transformedChartData = [];

        const generateSeriesDataPromises = chartDataGroupedBySite.map(async (siteData: any) => {
          let siteLevelWidgetData: Chart = _.cloneDeep(chartData);
          siteLevelWidgetData.data = siteData.data;
          let siteWidgetConfig = _.cloneDeep(chartConfigObj);
          const seriesData = await this.generateSeriesData(siteLevelWidgetData);
          siteWidgetConfig.series = seriesData;
          siteWidgetConfig.siteName = siteData.data[0]?.entityData[0]?.siteName !== undefined ? siteData.data[0]?.entityData[0]?.siteName : '';
          if(seriesData?.length) {
            this.transformedChartData.push(siteWidgetConfig);
          }
        });

        try {
          await Promise.all(generateSeriesDataPromises);
          this.isLoaded = true;
        } catch (error) {
          this.isLoaded = true;
          // Need to show Error message in UI
          console.error('Error generating series data', error);
        }
      }
    }
  }
  }

  getSanitizedUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /**
   * Formats the given start and end dates to UTC and returns them in the 'YYYY-MM-DDTHH:mm:ssZ' format.
   *
   * @param startDate - The start date as a string.
   * @param endDate - The end date as a string.
   * @returns An object containing the formatted start and end dates.
   * 
   * @example
   * ```typescript
   * const dates = formatDates('2023-10-01', '2023-10-10');
   * console.log(dates);
   * // Output: { formattedStartDate: '2023-10-01T00:00:00Z', formattedEndDate: '2023-10-10T23:59:59Z' }
   * ```
   */
  formatDates(startDate: string, endDate: string) {
    const currentDateTime = dayjs().utc();
    const formattedStartDate = dayjs(startDate).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

    let formattedEndDate;
    if (endDate && dayjs(endDate).isAfter(currentDateTime, 'day') || dayjs(endDate).isSame(currentDateTime, 'day')) {
      formattedEndDate = currentDateTime.format('YYYY-MM-DDTHH:mm:ss[Z]');
    } else {
      formattedEndDate = dayjs(endDate).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
    }
    return { formattedStartDate, formattedEndDate };
  }

  generateSeriesDataForPieChart(chartData: Chart): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let seriesData: any = [];
        if (chartData.data?.length) {
          const dataForChartsInput = chartData?.data
            .flatMap((item: any) =>
              item?.entityData?.map((entity: any) => ({
                ...entity,
                paramName: item?.paramName
              }))
            )
            .filter((item: any) => item?.allowDisplay)
            .map((entity: {
              itemStyle: any;
              count: number;
              name: string;
              defaultNameForExports: string;
              paramName: string;
              aggregationMethod: string;
              unit: any;
              value: number;
            }) => ({
              value: entity?.count ? entity?.count : entity?.value,
              name: entity?.name,
              itemStyle: entity?.itemStyle,
              defaultNameForExports: entity?.defaultNameForExports,
              paramName: entity?.paramName,
              unit: entity?.aggregationMethod != 'COUNT' ? entity?.unit?.value : ''
            }));
          let pointObj: any = {};
          pointObj.name = 'Label Placeholder';
          pointObj.type = 'pie';
          pointObj['data'] = dataForChartsInput;
          pointObj.emphasis = {
            itemStyle: {
              shadowBlur: 20,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
          pointObj.center = ['50%', '50%'],
          pointObj.radius = chartData.chartConfig.chartType == chartType.DONUT ?  ["30%", "50%"] : '50%';
            seriesData.push(pointObj);
        }
        resolve(seriesData);
      } catch (error) {
        reject(error);
      }
    });
  }

  //Method which sets data for e-charts in case of both pie and dougnut chart and view type series or combine:
  async generateChartDataForPieDonutChart(chartData: any) {
    let graphConfig: any = {};

    graphConfig.tooltip = this.generateTooltipForPieDoughnut(chartData);
    graphConfig.legend = {
      type: 'scroll',
      orient: 'horizontal',
      pageButtonPosition: 'end',
      left: "3%",
      top: "2px",
      icon: 'roundRect',
      animation: true,
    } 

    if (chartData.chartConfig.chartType == chartType.DONUT) {
      graphConfig.startAngle = 0;
      graphConfig.endAngle = 360;
    } 
    //If chartType is COMBINE, generate series data for all sites
    if (chartData.chartConfig.viewType === "COMBINE") {
      this.transformedChartData = [];
      try {
        graphConfig.series = await this.generateSeriesDataForPieChart(chartData);
        graphConfig.title =  [
          {
            text: chartData.chartConfig['chartLabel'],
            left: '15px',
            top: 'bottom',
            textStyle: {
              fontSize: 14,
              color: '#999999',
              fontWeight: 400
            }
          }
        ]
        this.transformedChartData.push(graphConfig);
        this.isLoaded = true;
      } catch (error) {
        console.error('Error generating series data for COMBINE view type', error);
      }
    } 
    
    //If chartType is SEPERATE, generate series data for each site
    else {
      let listOfSites = chartData.scopeSameAsDashboard || this.useDashBoardConfig ? this.dashboardService.dashboardConfig.siteRefs : chartData.widgetConfig.siteRefs;
      if (listOfSites?.length) {
        let chartDataGroupedBySite = listOfSites?.map((site: string) => ({
          siteRef: site,
          data: chartData?.data?.map((chartDataObj: any) => ({
            paramName: chartDataObj?.paramName,
            entityData: chartDataObj?.entityData ? chartDataObj.entityData.filter((entity: any) => entity.siteRef === site): []
          })).filter((filterItem: any) => filterItem.entityData.length > 0)
        }));

        this.transformedChartData = [];

        const generateSeriesDataPromises = chartDataGroupedBySite?.map(async (siteData: any, index:number) => {
          let siteLevelWidgetData: Chart = _.cloneDeep(chartData);
          siteLevelWidgetData.data = siteData?.data;
          let siteWidgetConfig = _.cloneDeep(graphConfig);
          const seriesData = await this.generateSeriesDataForPieChart(siteLevelWidgetData);
          siteWidgetConfig.series = seriesData;
          siteWidgetConfig.siteName = siteData?.data[0]?.entityData[0]?.siteName !== undefined ? siteData.data[0]?.entityData[0]?.siteName : '';
          if(index == chartDataGroupedBySite.length -1) {
            siteWidgetConfig.title =  [
              {
                text: chartData.chartConfig['chartLabel'],
                left: '15px',
                top: 'bottom',
                textStyle: {
                  fontSize: 14,
                  color: '#999999',
                  fontWeight: 400
                }
              }
            ]
          }
          if(siteWidgetConfig.series.length)
          this.transformedChartData.push(siteWidgetConfig);
        });

        try {
          await Promise.all(generateSeriesDataPromises);
          this.isLoaded = true;
        } catch (error) {
          this.isLoaded = true;
          // Need to show Error message in UI
          console.error('Error generating series data', error);
        }
      }
    }
  }

  //Based on name return the formatted tooltip label.name for every entity will be unique
  findTooltipByNameForPieDoughnut(data: any[], input: string) {
    for (const index of data) {
      const match = index?.entityData?.find((entity: any) => entity?.name === input);
      if (match) {
        return match;
      }
    }
    return '';
  };

  //Method to generate tooltip for pie and doughnut chart
  generateTooltipForPieDoughnut(chartData: any) {
    chartData?.data?.forEach((data: any) => {
      data?.entityData?.forEach((entity: any) => {
        if(entity?.aggregationMethod != 'COUNT') {
          entity.value = Number(entity?.value?.toFixed(2));
        }
      })
    })
    let graphConfig: any = GRAPH_CONFIGURATION.toolTipTriggerConfigForPieChart;
    let self = this;
  
    graphConfig.formatter = function (params: any) {
      const { name, value, percent, color } = params;
      const toolipNameandUnit = chartData?.data
        ? self.findTooltipByNameForPieDoughnut(chartData.data, name)
        : name;
  
      return `
        <div>
          <span style="color: ${color};">${toolipNameandUnit?.formattedTooltipLabel} : ${value} ${toolipNameandUnit?.unit?.value ? toolipNameandUnit?.unit?.value : ''} (${percent}%)</span>
        </div>
      `;
    };
  
    return graphConfig;
  }

}
