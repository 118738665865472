import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface Configuration {
  haystackUrl: string;
  tagsUrl: string;
}

@Injectable({
  providedIn: 'root'
})

export class ConfigurationService {

  private readonly CONFIG_URL = 'assets/config/config.json';
  appConfig:any = {};

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    return this.http.get<Configuration>(this.CONFIG_URL)
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }

  setConfig(data:any) {
    this.appConfig = data;
  }

  getConfig(key:string) {
    return this.appConfig[key];
  }
}