import { Injectable } from '@angular/core';
import { GridType, GridsterConfig, DisplayGrid } from 'angular-gridster2';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConnectService } from './connect.service';
import { ObjectUtil } from '../utils/object-util';

@Injectable({
  providedIn: 'root'
})
export class GridsterService {
  lastResizeChanges: Map<string, any> = new Map();

  private gridChangedList = new BehaviorSubject<any>({}); // or any initial value type
  gridItem$: Observable<string> = this.gridChangedList.asObservable();
  constructor(private connectService:ConnectService) { }

  public gridsterOptions: GridsterConfig = {
    itemChangeCallback: this.itemChange.bind(this),
    itemResizeCallback: this.itemResize.bind(this),
    displayGrid: DisplayGrid.Always,
    margin: 10,

    draggable: {
      enabled: true,
      start: this.dragStarted.bind(this),
      stop: this.dragStopped.bind(this),
      ignoreContent: true,
      dragHandleClass: 'dragHandle'
    },
    disableScrollHorizontal: true,
    api: {
      optionsChanged: () => {}
    },

    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: 30,
    outerMarginBottom: 100,

    minCols: 24, // minimum amount of columns in the grid
    maxCols: 24, // maximum amount of columns in the grid
    minRows: 1, // minimum amount of rows in the grid
    maxRows: 1000, // maximum amount of rows in the grid
    defaultItemCols: 12, // default width of an item in columns
    defaultItemRows: 8, // default height of an item in rows
    minItemCols: 2, // minimum columns that an item takes
    maxItemCols: 24,	// maximum item number of cols
    minItemRows: 1, // minimum item number of rows
    maxItemRows: 320, // maximum item number of rows

    gridType: GridType.VerticalFixed,
    fixedRowHeight: 50,
    mobileBreakpoint: 640,
    setGridSize: false,

    pushItems: true,
    pushResizeItems: false,
    pushDirections: {
      north: true, east: true, south: true, west: true
    },

    resizable: {
      enabled: true,
      handles: { s: false, e: true, n: true, w: true, se: true, ne: true, sw: true, nw: true },
      start: this.resizeStarted.bind(this),
      stop: this.resizeStopped.bind(this)
    },

    swap: true,

    scrollToNewItems: false,
    disableWarnings: true,
    ignoreMarginInRow: true,

    enableEmptyCellDrop: true,
    enableEmptyCellDrag: true,

    compactType: 'compactUp&Left',
    itemInitCallback: this.itemInitialized.bind(this),
    itemRemovedCallback: this.itemRemoved.bind(this)
  };


  /******************** Gridster Events Start ********************************/

  itemChange(item:any, _itemComponent:any) {
  }


  // This is called after the resize has happened, and the new dimensions have been set,
  // the other thing - resizeStopped does not set the new dimensions
  itemResize(item:any, itemComponent:any) {
    this.lastResizeChanges.set(item.widgetId, { width: itemComponent.width, height: itemComponent.height });

    this.connectService.setWidgetResized(
      { widgetId: item.widgetId, state: 'done', width: itemComponent.width, height: itemComponent.height });
  }


  // Blur on dragging
  dragStarted(item:any, itemComponent:any) {
    // not chartType, since there can be multiple charts of the same type.
    this.connectService.setWidgetDragged(item.widgetId);
  }


  // Unblur on dragStopped
  dragStopped(item:any, itemComponent:any) {
    // instead of identifying which widget has stopped dragging, we simply set the value to null.
    this.connectService.setWidgetDragged(null);
    // If the widgets are re-arranged, we need to update the positions.
    this.gridChangedList.next({
      widgetId: item.widgetId,
      x: ObjectUtil.isNotUndefinedOrNull(itemComponent.$item.x) ? itemComponent.$item.x : item.x,
      y: ObjectUtil.isNotUndefinedOrNull(itemComponent.$item.y) ? itemComponent.$item.y : item.y,
      cols: ObjectUtil.isNotUndefinedOrNull(itemComponent.$item.cols) ? itemComponent.$item.cols : item.cols,
      rows: ObjectUtil.isNotUndefinedOrNull(itemComponent.$item.rows) ? itemComponent.$item.rows : item.rows
    });
  }


  // Blur on resizing
  resizeStarted(item:any, itemComponent:any) {
    this.connectService.setWidgetResized(
      { widgetId: item.widgetId, state: 'start' });
  }


  // Blur removed
  resizeStopped(item:any, itemComponent:any) {
    this.connectService.setWidgetResized(
      { widgetId: item.widgetId, state: 'stop' });
    this.gridChangedList.next({
      widgetId: item.widgetId,
      x: ObjectUtil.isNotUndefinedOrNull(itemComponent.$item.x) ? itemComponent.$item.x : item.x,
      y: ObjectUtil.isNotUndefinedOrNull(itemComponent.$item.y) ? itemComponent.$item.y : item.y,
      cols: ObjectUtil.isNotUndefinedOrNull(itemComponent.$item.cols) ? itemComponent.$item.cols : item.cols,
      rows: ObjectUtil.isNotUndefinedOrNull(itemComponent.$item.rows) ? itemComponent.$item.rows : item.rows
    });
  }


  itemInitialized(item:any, _itemCoponent:any) {
   // should be empty no action required
  }


  itemRemoved(item:any) {
    // should be empty no action required
  }

  /******************** Gridster Events End ********************************/

}
